import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { database } from "../../services/firebase-config";
import {collection,doc,addDoc,deleteDoc,updateDoc} from "firebase/firestore";
import {requestSuperData} from '../../store/dashboardHook';
import PlanItem from "./PlanItem";
import ContactItem from "./ContactItem";

const Leads = () => {


  const [isLoading, setIsLoading] = useState(false);
  const [allContacts, setAllContacts] = useState([])


  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const leads = await requestSuperData('contacts');
      const sortedLeads = leads.sort((b, a) => a.createdAt - b.createdAt);
      setAllContacts(sortedLeads);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);





  const removePopup = async(id) => {
    const isConfirmed = window.confirm("האם למחוק את הפנייה?");
    if(isConfirmed){
        try {
            await deleteDoc(doc(database, "contacts", id))
            .then((res) => {
                toast.success("המסלול הוסרה בהצלחה");
                loadData()
              }).catch((err) => {
                toast.error(err.message);
              });
        } catch (error) {
            toast.error(error.message)
        }
    }
  }


  return (
    <>
      <ToastContainer />


      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>פניות מהאתר</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        {allContacts.length > 0 ? (
          <>
          <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">תאריך</th>
                    <th scope="col">שם מלא</th>
                    <th scope="col">שם החברה</th>
                    <th scope="col">דואר אלקטרוני</th>
                    <th scope="col">טלפון</th>
                    <th scope="col">תוכן ההודעה</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>

                {allContacts.map((item) => (
                    <ContactItem item={item} removePopup={removePopup} />
                ))}
                </tbody>
            </table>
          </>
        ) : (
          <>
            <p>לא נמצאו פופאפים לתצוגה</p>
          </>
        )}
      </div>
    </>
  );
};

export default Leads;
