import React from 'react'

const MainContainer = ({children}) => {
  return (
    <div className="col-lg-10 d-flex flex-column" style={{ height: '100vh', overflowY: 'auto' }}>
        {children}
    </div>
  )
}

export default MainContainer