import React, { useState } from "react";

const AddProject = (props) => {
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectCity, setProjectCity] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [projectImage, setProjectImage] = useState(
    "https://res.cloudinary.com/united-app/image/upload/v1688196093/avatars/project_mawyir.jpg"
  );
  const [projectLogo, setProjectLogo] = useState(
    "https://res.cloudinary.com/united-app/image/upload/v1683366002/avatars/avatar_k4ntm3.png"
  );

  return (
    <>
      <label className="form-label">שם האתר</label>
      <input
        type="text"
        className="form-control"
        value={projectName}
        onChange={(e) => {
          setProjectName(e.target.value);
        }}
      />

      <label className="form-label">תיאור האתר</label>
      <textarea className="form-control"
        onChange={(e) => {
          setProjectDesc(e.target.value);
        }}>{projectDesc}</textarea>


      <label className="form-label">כתובת / מיקום האתר</label>
      <input
        type="text"
        className="form-control"
        value={projectAddress}
        onChange={(e) => {
          setProjectAddress(e.target.value);
        }}
      />

      <label className="form-label">עיר האתר</label>
      <input
        type="text"
        className="form-control"
        value={projectCity}
        onChange={(e) => {
          setProjectCity(e.target.value);
        }}
      />

<div className="col-lg-12" style={{marginTop:12}}>
            <button className="btn btn-success btn-lg" onClick={() => {
                props.addNewProjectClick(
                    projectAddress,
                    projectCity,
                    projectDesc,
                    projectImage,
                    projectLogo,
                    projectName
                    )}}>הוספת אתר חדש</button>
        </div>


    </>
  );
};

export default AddProject;
