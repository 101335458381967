import React from "react";

const NotifyLicense = (props) => {
  let statusColor = "";
  if (props.item.daysLeft > 0) {
    statusColor = "#ff9933";
  }
  if (props.item.daysLeft < 0) {
    statusColor = "#EB6079";
  }


  return (
    <div className="row">
      <div className="col-lg-4">
        <p>{props.item.firstName} {props.item.lastName}</p>
      </div>
      <div className="col-lg-6">
        <p>{props.item.fileName}</p>
      </div>
      <div className="col-lg-2">
        <p style={{ color: statusColor }}>
          <b>{props.item.daysLeft}</b>
        </p>
      </div>
    </div>
  );
};

export default NotifyLicense;
