import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {collection,addDoc,doc,query,where,getDocs,setDoc} from "firebase/firestore";
import useDashboardStore from "../../store/useDashboardStore";

const PayError = () => {

  const {userPersonalData,myCompany} = useDashboardStore();

  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>פרטי החברה שלי</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-12">
          <h6>פרטי מנוי ותשלומים</h6>
          <br/>
        </div>
      </div>
    </>
  );
};

export default PayError;
