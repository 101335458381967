import React, { useState, useEffect, useCallback } from "react";
import { auth, database } from "../../services/firebase-config";
import {requestDataByAssociate,getAccountDetails} from "../../services/dbHelper";
import {collection,addDoc,deleteDoc,updateDoc, doc} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import CategoryTesting from './components/CategoryTesting';

const AssestsCategories = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [myAccount, setMyAccount] = useState(false);
    const [safetyTestingCategoryItem, setSafetyTestingCategoryItem] = useState("");
    const [safetyTestingCategory, setSafetyTestingCategory] = useState([]);

    const loadData = useCallback(async () => {
        setIsLoading(true);
        try {
          const account = await getAccountDetails(auth.currentUser.uid);
          const safetyTestingCategoryArr = await requestDataByAssociate(account.associateId, "assetsCategories");
          setSafetyTestingCategory(safetyTestingCategoryArr);
          setMyAccount(account);
          setIsLoading(false);
        } catch (error) {
          toast.error(error.message);
          setIsLoading(false);
        }
      }, []);
    
      useEffect(() => {
        loadData();
      }, []);
    
    
      const addSafetyCategoryTesting = async() => {
        if(safetyTestingCategoryItem !== ""){
          try {
            await addDoc(collection(database, "assetsCategories"), {
              associateId: myAccount.associateId,
              safetyTestingCategoryItem: safetyTestingCategoryItem
            });
            loadData();
            setSafetyTestingCategoryItem("");
          } catch (error) {
            toast.error(error.message);
          }
        } else {
          toast.error("נא למלא את שם הקטגוריה");
        }
      }

      const editItem = async(id,title) => {
        const trackingRef = doc(database, "assetsCategories", id);
        try {
            await updateDoc(trackingRef, {
              safetyTestingCategoryItem: title,
            })
            loadData()
        } catch (error) {
            toast.error(error.message)
        }
      }
      const deleteItem = async(id) => {
        const isConfirmed = window.confirm("האם למחוק את המחלקה לצמיתות?");
        if(isConfirmed){
            try {
                await deleteDoc(doc(database, "assetsCategories", id))
                .then(result => {
                    loadData()
                })
            } catch (error) {
                toast.error(error.message)
            }
        }
      }



  return (
    <>
    <div className="row heading-container">
        <div className="col-lg-10">
          <h3>ניהול מחלקות אביזרים</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-4">

        <h6>מחלקות אביזרים</h6>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-9">
              <input
                value={safetyTestingCategoryItem}
                onChange={(e) => setSafetyTestingCategoryItem(e.target.value)}
                type="text"
                placeholder="שם המחלקה"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <button onClick={addSafetyCategoryTesting} className="btn btn-success">הוספה</button>
            </div>
          </div>


          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {isLoading ? (
                <>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  {safetyTestingCategory.length > 0 ? (
                    <>
                      {safetyTestingCategory.map((item) => (
                        <CategoryTesting
                            key={item.id}
                            item={item}
                            deleteItem={deleteItem}
                            editItem={editItem}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      <p>לא קיימות קטגוריות אביזרים</p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-4"></div>
      </div>
    </>
  )
}

export default AssestsCategories