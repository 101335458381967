import React from 'react'
import { Link } from "react-router-dom";
import { IoLocationOutline,IoGridOutline,IoPhonePortraitOutline,IoMailOutline,IoPeopleOutline,IoSettingsSharp,IoBusOutline,IoBusinessOutline } from "react-icons/io5";

const Company = ({company,employees}) => {

  const companyInfo = 'edit';
  const sitesInfo = 'sites';
  const employeesInfo = 'employees';
  const machinesInfo = 'machines';
  const departmentsInfo = 'departments';

  return (
    <div className='row row-box'>


        <div className='col-lg-1'>
            {
                company.companyLogo 
                ? <img src={company.companyLogo} className='row-logo' alt={company.companyName} /> 
                : <img src='/images/logo_black.png' className='row-logo' alt={company.companyName} />
            }
        </div>


        <div className='col-lg-6' style={{paddingRight:20}}>
            <h6>{company.companyName} <span className={company.isArchive ? "badge text-bg-warning" : "badge text-bg-success"}>{company.isArchive ? "ארכיון" : "פעיל"}</span></h6>
            <p className='company-address'><span><IoLocationOutline style={{marginLeft:10}} size={20} color="#01A7C2" /></span>{company.companyAddress}, {company.companyCity}</p>
            <p className='company-address'>
                <span>
                    <IoPhonePortraitOutline style={{marginLeft:10}} size={20} color="#01A7C2" />
                    </span>
                    {company.companyEmail} 
                <span>
                    <IoMailOutline style={{marginLeft:10, marginRight:10}} size={20} color="#01A7C2" /></span>
                    {company.companyPhone}</p>
        </div>



        <div className='col-lg-1 company-box'>
        <Link className='btn btn-light' to={{pathname: `/company-edit/${company.id}/${employeesInfo}`}}>
            <IoPeopleOutline color="#01A7C2" size={30} />
            <p>{employees.filter(x => x.associateCompany === company.id).length} עובדים</p></Link>
        </div>


        <div className='col-lg-1 company-box'>
        <Link className='btn btn-light'
                to={{pathname: `/company-edit/${company.id}/${sitesInfo}`}}>
            <IoBusinessOutline color="#01A7C2" size={30} />
            <p>{company.projects.length} אתרים</p></Link>
        </div>

        <div className='col-lg-1 company-box'>
        <Link className='btn btn-light'
                to={{pathname: `/company-edit/${company.id}/${machinesInfo}`}}>
            <IoBusOutline color="#01A7C2" size={30} />
            <p>{company.machines ? company.machines.length : 0} אביזרים</p></Link>
        </div>

        <div className='col-lg-1 company-box'>
        <Link className='btn btn-light'
                to={{pathname: `/company-edit/${company.id}/${departmentsInfo}`}}>
            <IoGridOutline color="#01A7C2" size={30} />
            <p>{company.departments ? company.departments.length : 0} מחלקות</p></Link>
        </div>


        <div className='col-lg-1 company-box'>
            <Link className='btn btn-light'
                to={{pathname: `/company-edit/${company.id}/${companyInfo}`}}>
                <IoSettingsSharp color="#A3BAC3" size={30} />
            </Link>
        </div>

        


    </div>
  )
}

export default Company