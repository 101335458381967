import React from "react";
import { FcElectricalSensor, FcElectricalThreshold } from "react-icons/fc";
import { Link } from "react-router-dom";
const Safety = () => {
  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>דוחות בטיחות</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-3">
          <Link to="/safety-reports">
            <div className="card" style={{ textAlign: "center", alignItems: "center", padding: 10 }}>
              <FcElectricalSensor size={80} />
              <div className="card-body">
                <h5 className="card-title">מבדקי בטיחות</h5>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3">
          <Link to="/finding-list">
            <div
              className="card"
              style={{ textAlign: "center", alignItems: "center", padding: 10 }}
            >
              <FcElectricalThreshold size={80} />
              <div className="card-body">
                <h5 className="card-title">רשימות ממצאים</h5>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3"></div>
        <div className="col-lg-3"></div>
      </div>
    </>
  );
};

export default Safety;
