import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";


const ReportItem = (props) => {

  const project = props?.projects?.find((x) => x.siteId === props.report.siteId);
  const manageInCharge = props.employeesArr.find((x) => x.id === props.report.manageInCharge);
  const trackInCharge = props.employeesArr.find((x) => x.id === props.report.trackInCharge);
  const navigate = useNavigate();

  let priorityColor = "";
  let textColor = "";

  if (parseFloat(props.report.totalAverage) >= 90) {
    priorityColor = "#67eb34";
    textColor = "#67eb34"
  }
  if (parseFloat(props.report.totalAverage) >= 70 && parseFloat(props.report.totalAverage) < 90) {
    priorityColor = "#0099cc";
    textColor = "#0099cc"
  }
  if (parseFloat(props.report.totalAverage) >= 50 && parseFloat(props.report.totalAverage) < 70)  {
    priorityColor = "#ffcc00";
    textColor = "#ffcc00"
  }
  if (parseFloat(props.report.totalAverage) >= 0 && parseFloat(props.report.totalAverage) < 50)  {
    priorityColor = "#EB6079";
    textColor = "#EB6079"
  }


const countFindingItems = (data) => {
    let count = 0;
    if (data && data.subCategories) {
      data.subCategories.forEach(subCategory => {
        if (subCategory.sections) {
          subCategory.sections.forEach(section => {
            if (section.findingItems) {
              count += section.findingItems.length;
            }
          });
        }
      });
    }
    return count;
  };
  const numberOfFindings = countFindingItems(props.report)

  const reportDetailsNav = async() => {
    navigate(`/view-report/${props.report.id}`);
  }

  return (
    <>
    <div className="row" style={{marginBottom: 10,padding: 5,borderRadius: 12,background: "#F8FCFC",}}>
      <div className="col-lg-4" style={{ padding: 2 }}>
        <h6>{props.report.safetyReportItem} <b>{project.projectName}</b><br/></h6>
        אחראי טיפול: <b>{manageInCharge?.firstName} {manageInCharge?.lastName}</b> | אחראי מעקב: <b>{trackInCharge?.firstName} {trackInCharge?.lastName}</b><br/>
        הדו״ח הופק בתאריך: <b>{moment(props.report.createdAt).format('DD/MM/YYYY')}</b><br/>
        <b>מספר מזהה: </b> {props.report.id}
      </div>

      <div className="col-lg-3">
        <p>ציון קטגוריות</p>
        {
            props.report.subCategories.map(sub => (<>
                <p>{sub.safetyReportSubItem} <b>ציון: {parseFloat(sub.score)}</b></p>
            </>))
        }
      </div>


      <div className="col-lg-1" style={{ textAlign: "center", paddingTop: 20 }}>
        <p>ממצאים</p>
        <h2>{numberOfFindings}</h2>
      </div>

      
      <div className="col-lg-1" style={{ textAlign: "center", paddingTop: 20 }}>
        <p>ציון כללי</p>
        <h2 style={{ color: textColor }}>{parseFloat(props.report.totalAverage).toFixed(0)}</h2>
      </div>


      <div className="col-lg-2" style={{ textAlign: "center", paddingTop: 30 }}>
        <button onClick={() => {props.removeReport(props.report.id)}} className="btn btn-danger btn-sm"><MdDelete color="#ffffff" size={25} /></button>
        <button onClick={reportDetailsNav} className="btn btn-warning btn-sm" style={{marginRight:10}}><IoMdSettings color="#ffffff" size={25} /></button>
      </div>
      

      <div className="col-lg-1">
        <div
          style={{
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            width: "30%",
            float: "left",
            height: "100%",
            background: priorityColor,
          }}></div>
      </div>

      </div>
    </>
  );
};

export default ReportItem;
