import React, {useState, useEffect} from "react";
import moment from "moment";

const PlanItem = (props) => {

    const [actions, setActions] = useState(props.item.actions);
    const [companies, setCompanies] = useState(props.item.companies);
    const [employees, setEmployees] = useState(props.item.employees);
    const [planCost, setPlanCost] = useState(props.item.planCost);
    const [planName, setPlanName] = useState(props.item.planName);
    const [users, setUsers] = useState(props.item.users);
    const [sites, setSites] = useState(props.item.sites);
    const [isEditView, setIsEditView] = useState(false);
    const [payurl, setPayurl] = useState(props.item.payurl);
    const [payId, setPayId] = useState(props.item.payId);
    const [displayInSite, setDisplayInSite] = useState(props.item.displayInSite);

    return(
        <>
        {
            isEditView ? (<>
                <tr key={props.item.id}>


                    <td colSpan={8}>
                    <div className="row">
                        <div className="col-lg-6">
                        <label className="form-label">
                    שם המסלול
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    type="text"
                    className="form-control"/>
                        </div>
                        <div className="col-lg-6">
                        <label className="form-label">
                    עלות חודשית
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={planCost}
                    onChange={(e) => setPlanCost(e.target.value)}
                    type="text"
                    className="form-control"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                        <label className="form-label">
                    חברות
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={companies}
                    onChange={(e) => setCompanies(e.target.value)}
                    type="text"
                    className="form-control"/>
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label">
                    אתרים
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={sites}
                    onChange={(e) => setSites(e.target.value)}
                    type="text"
                    className="form-control"/>
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label">
                    יוזרים
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={users}
                    onChange={(e) => setUsers(e.target.value)}
                    type="text"
                    className="form-control"/>
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label">
                    עובדים
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={employees}
                    onChange={(e) => setEmployees(e.target.value)}
                    type="text"
                    className="form-control"/>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                        <label className="form-label">
                    קישור לעמוד תשלום
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={payurl}
                    onChange={(e) => setPayurl(e.target.value)}
                    type="text"
                    style={{textAlign:'left', direction:'ltr'}}
                    className="form-control"/>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-12">
                        <label className="form-label">
                            מזהה תכנית
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={payId}
                    onChange={(e) => setPayId(e.target.value)}
                    type="text"
                    style={{textAlign:'left', direction:'ltr'}}
                    className="form-control"/>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-6">
                        <input
                    onChange={(e) => setActions(e.target.checked)}
                    checked={actions}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    אפשר ביצוע פעולות אוטומציה
                  </label>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-6">
                        <input
                    onChange={(e) => setDisplayInSite(e.target.checked)}
                    checked={displayInSite}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הצגה באתר
                  </label>
                        </div>
                    </div>



                    </td>
                    <td>
                        <button onClick={() => {setIsEditView(!isEditView)}} className="btn btn-warning">ביטול</button>
                        <button onClick={() => {
                            props.updatePopup(
                                props.item.id,
                                displayInSite,
                                planName,
                                planCost,
                                users,
                                companies,
                                sites,
                                payurl,
                                actions,
                                employees,
                                payId);
                            setIsEditView(!isEditView);
                        }} style={{marginRight:6}} className="btn btn-success">שמירה</button>
                    </td>
                </tr>
            </>) : (
                <tr key={props.item.id}>
                    <td>{props.item.planName}</td>
                    <td><b>₪{props.item.planCost}</b></td>
                    <td>{props.item.companies}</td>
                    <td>{props.item.sites}</td>
                    <td>{props.item.users}</td>
                    <td>{props.item.employees}</td>
                    <td>{props.item.actions ? 'פעיל' : 'לא פעיל'}</td>
                    <td>{props.item.displayInSite ? 'פעיל' : 'לא פעיל'}</td>
                    <td>
                        <a className="btn btn-info" href={props.item.payurl} target="_blank">קישור</a>
                    </td>
                    <td>
                        {
                            props.item.id !== 'SuN9HPv29SC3lrbXqUqR' && <button onClick={() => {props.removePopup(props.item.id)}} className="btn btn-danger">הסרה</button>
                        }
                        <button onClick={() => {setIsEditView(!isEditView)}} style={{marginRight:6}} className="btn btn-success">עריכה</button>
                    </td>
                </tr>
            )
        }
        </>
    )
}

export default PlanItem;