import React, {useState} from 'react'
import { MdDeleteOutline,MdAddCircle,MdEdit,MdArrowBackIos,MdArrowForwardIos,MdOutlineSave } from "react-icons/md";
const CategoryTestingAdmin = (props) => {

  const [isEditView, setIsEditView] = useState(false);
  const [title, setTitlte] = useState(props.item.safetyTestingCategoryItem);

  return (
    <>
    {
      isEditView ? (<>
      <div className='row safety-item-row'>
        <div className='col-lg-8'>
            <input
              className='form-control'
              value={title}
              onChange={(e) => setTitlte(e.target.value)}
            />
        </div>
        <div className='col-lg-4'>
          <button onClick={() => props.editItem(props.item.id, title)} className='btn btn-success btn-sm'>שמירה</button>{" "}
          <button onClick={() => setIsEditView(!isEditView)} className='btn btn-dark btn-sm'>ביטול</button>
        </div>
    </div>
      </>) : (<>
        <div className='row safety-item-row'>
        <div className='col-lg-8'>
            <p>{props.item.safetyTestingCategoryItem}</p>
        </div>
        <div className='col-lg-4'>
          {/* <button onClick={() => props.deleteItem(props.item.id)} className='btn btn-danger btn-sm'>הסרה</button>{" "}
          <button onClick={() => setIsEditView(!isEditView)} className='btn btn-success btn-sm'>עריכה</button> */}
          {
                    props.role === 'superadmin' ? (<>
                      <button style={{marginLeft:5}} onClick={() => {setIsEditView(!isEditView)}} className='btn btn-success btn-sm'><MdEdit color="#ffffff" size={20} /></button>
                      <button style={{marginLeft:5}} onClick={() => {props.deleteItem(props.item.id)}} className='btn btn-danger btn-sm'><MdDeleteOutline color="#ffffff" size={20} /></button>
                    </>) : (<>
                      <button style={{marginLeft:5, padding:6}} onClick={() => {props.addTemplateToMyList(props.item)}} className='btn btn-success btn-sm'>הוסף לרשימה שלי</button>
                    </>)
                  }
        </div>
    </div>
      </>)
    }
    </>
  )
}

export default CategoryTestingAdmin