import moment from "moment";
import React, { useState, useRef } from "react";
import { MdOutlineArrowForwardIos, MdDelete, MdEdit, MdSave } from "react-icons/md";
import {toast,ToastContainer} from 'react-toastify'
const ProjectCard = (props) => {
  const [editProjectView, setEditProjectView] = useState(false);

  const [projectName, setProjectName] = useState(props.selectedProject.projectName);
  const [projectAddress, setProjectAddress] = useState(props.selectedProject.projectAddress);
  const [projectCity, setProjectCity] = useState(props.selectedProject.projectCity);
  const [projectDesc, setProjectDesc] = useState(props.selectedProject.projectDesc);
  const [projectImage, setProjectImage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);



  const handleDelete = () => {
    setShowConfirmation(true);
  };
  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    onDelete();

    toast.success("הפרויקט הוסר בהצלחה", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };
  const onDelete = async () => {
    props.removeProject(props.selectedProject)
    props.setSelectedProject(null);
  };

  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };




  return (
    <div className="card">
      <div className="row">
        <div
          className="col-lg-6"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={props.selectedProject.projectImage}
            className="card-img-top"
            alt="..."
          />
        </div>

        <div className="card-body col-lg-6">
          {editProjectView ? (
            <>
              <label className="form-label">שם האתר</label>
              <input
                type="text"
                className="form-control"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
              />

              <label className="form-label">תיאור האתר</label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setProjectDesc(e.target.value);
                }}
              >
                {projectDesc}
              </textarea>

              <label className="form-label">כתובת / מיקום האתר</label>
              <input
                type="text"
                className="form-control"
                value={projectAddress}
                onChange={(e) => {
                  setProjectAddress(e.target.value);
                }}
              />

              <label className="form-label">עיר האתר</label>
              <input
                type="text"
                className="form-control"
                value={projectCity}
                onChange={(e) => {
                  setProjectCity(e.target.value);
                }}
              />

              <label className="form-label">העלאת תמונה</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setProjectImage(e.target.files[0]);
                }}
              />


              <div style={{marginTop: 20,padding: 20,display: "flex",justifyContent: "space-between",}}>

                <div>
                <button className="btn btn-light" onClick={() => {setEditProjectView(!editProjectView)}}>
                  <MdOutlineArrowForwardIos size={20} />
                </button>

                <button style={{marginRight:12}} className="btn btn-danger"  onClick={() => {handleDelete(props.selectedProject)}}>
                  <MdDelete size={20} /> מחיקה
                </button>
                
                </div>


                {showConfirmation && (
                  <div style={{ flexDirection: "row", marginTop: 0 }}>
                    <p>
                      אישור מחיקה{" "}
                      <button className="btn btn-danger" onClick={handleConfirmDelete}>מחיקה</button>{" "}
                      <button className="btn btn-success" onClick={handleCancelDelete}>ביטול</button>
                    </p>
                  </div>
                )}


                <button className="btn btn-success" onClick={() => {
                  props.updateProject(projectAddress,projectCity,projectDesc,projectImage,projectName,props.selectedProject.siteId)
                  setEditProjectView(!editProjectView)
                  }}>
                  <MdSave size={20} /> עדכון פרטים
                </button>

              </div>
            </>
          ) : (







            <>
              <h5 className="card-title">
                {props.selectedProject.projectName}
              </h5>
              <p className="card-text">
                {props.selectedProject.projectDesc}
                <br />
                <b>כתובת האתר: </b>
                {props.selectedProject.projectAddress},{" "}
                {props.selectedProject.projectCity}
                <br />
                <b>מספר מזהה: </b>
                {props.selectedProject.siteId}
                <br />
                <b>התווסף בתאריך: </b>
                {moment(props.selectedProject.createdAt).format("DD/MM/YYYY")}
                <br />
              </p>

              <div
                style={{
                  marginTop: 20,
                  padding: 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="btn btn-light"
                  onClick={() => {
                    props.setSelectedProject(null);
                  }}
                >
                  <MdOutlineArrowForwardIos size={20} />
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setEditProjectView(!editProjectView);
                  }}
                >
                  <MdEdit size={20} />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
