import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { database } from "../../services/firebase-config";
import {collection,updateDoc,doc} from "firebase/firestore";
import useDashboardStore from "../../store/useDashboardStore";
import { useParams } from "react-router-dom";

const PaySuccess = () => {

  const { id } = useParams();
  const { theSelectedPlan, setTheSelectedPlan, myPlan, mainAccount } = useDashboardStore();
  const [message, setMessage] = useState('')

  useEffect(() => {

    const planFromStorage = localStorage.getItem('dashboard-storage');
    if (!theSelectedPlan && planFromStorage) {
        const storedState = JSON.parse(planFromStorage);
        if (storedState?.theSelectedPlan) {
            setTheSelectedPlan(storedState.theSelectedPlan);
            console.log('Plan loaded from storage:', storedState.theSelectedPlan);
        }
    }

    if(theSelectedPlan && id){
      if(id === theSelectedPlan.payId){
        updateUserAccount(theSelectedPlan);
      }
    }


}, [theSelectedPlan, setTheSelectedPlan]);


const updateUserAccount = async(theSelectedPlan) => {
  
  console.log('Update:', theSelectedPlan);
  console.log('mainAccount.id:', mainAccount.id);
  try {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);

    const updateData = {
      planId: theSelectedPlan.id,
      license: theSelectedPlan.planName,
      expireDate: currentDate.getTime()
    };

        const popupRef = doc(database, "accounts", mainAccount.id);
        await updateDoc(popupRef, {
            ...updateData
          }).then(() => {
            useDashboardStore.getState().updateMainAccount(updateData);
            useDashboardStore.getState().updateAccount(mainAccount.id, { ...updateData});
            setMessage('החשבון עודכן בהצלחה למסלול: ' + theSelectedPlan.planName)
          });
  } catch (error) {
      setMessage(error.message);
  }
}



  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>פרטי החברה שלי</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-12">
          <h6>פרטי מנוי ותשלומים</h6>
          <p>{message}</p>
          <br/>
        </div>
      </div>
    </>
  );
};

export default PaySuccess;
