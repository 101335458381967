import React from 'react'
import { IoSettingsSharp } from "react-icons/io5";

const EmployeeSmall = (props) => {
    return (
        <tr>
            <td>
            <input className='form-control'
                type="checkbox"
                checked={props.isSelected}
                onChange={(e) => props.handleSelectEmployee(props.employee.id, e.target.checked)}
            />
            </td>
            <td><b>{props.employee.employeeId}</b></td>
            <td><b>{props.employee.employeeNumber}</b></td>
            <td>{props.employee.firstName} {props.employee.lastName}</td>
            <td>{props.employee.position}</td>
            <td>{props.employee.department}</td>
            <td>{props.employee.branch}</td>
            <td>{typeof props.employee.constructor === 'string' ? props.employee.constructor : ''}</td>
            <td>{props.employee.hospitality}</td>
            <td>{props.employee.files.length}</td>
            <td>{props.employee.trainings.length}</td>
            <td>
                <button onClick={() =>{props.deleteEmployee(props.employee.id)}} className='btn btn-danger btn-sm'>הסרה</button>{" "}
                <button onClick={() => props.setSelectedEmployee(props.employee)} className='btn btn-success btn-sm'>עריכה</button>
            </td>
        </tr>
    )
}

export default EmployeeSmall;
