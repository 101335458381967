import React, { useState, useEffect, useCallback } from "react";
import { auth, database } from "../../services/firebase-config";
import {
  requestDataByAssociate,
  getAccountDetails,
} from "../../services/dbHelper";
import {
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import CategoryTesting from "./components/CategoryTesting";
import useDashboardStore from "../../store/useDashboardStore";
import { requestSuperData } from "../../store/dashboardHook";
import CategoryTestingAdmin from "./components/CategoryTestingAdmin";

const SafetyTesting = () => {
  const { userPersonalData } = useDashboardStore();
  const [isLoading, setIsLoading] = useState(false);
  const [myAccount, setMyAccount] = useState(false);
  const [safetyTestingCategoryItem, setSafetyTestingCategoryItem] =
    useState("");
  const [safetyTestingCategory, setSafetyTestingCategory] = useState([]);
  const [safetyTestingCategoryAdmin, setSafetyTestingCategoryAdmin] = useState(
    []
  );
  const [adminCategory, setAdminCategory] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);
      const safetyTestingCategoryArr = await requestDataByAssociate(
        account.associateId,
        "safetyTestingCategories"
      );
      const safetyTestingCategoryArrFiltered = safetyTestingCategoryArr.filter(
        (x) => x.adminCategory === false
      );

      setSafetyTestingCategory(safetyTestingCategoryArrFiltered);

      const safetyTestingCategoryArrAdmin = await requestSuperData(
        "safetyTestingCategories"
      );
      const safetyTestingCategoryArrAdminFiltered =
        safetyTestingCategoryArrAdmin.filter((x) => x.adminCategory === true);

      setSafetyTestingCategoryAdmin(safetyTestingCategoryArrAdminFiltered);

      setMyAccount(account);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const addSafetyCategoryTesting = async () => {
    if (safetyTestingCategoryItem !== "") {
      try {
        await addDoc(collection(database, "safetyTestingCategories"), {
          associateId: myAccount.associateId,
          safetyTestingCategoryItem: safetyTestingCategoryItem,
          templateId: "",
          adminCategory: adminCategory,
        });
        loadData();
        setSafetyTestingCategoryItem("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שם בדיקת הבטיחות");
    }
  };

  const addTemplateToMyList = async (item) => {
    try {
      await addDoc(collection(database, "safetyTestingCategories"), {
        associateId: myAccount.associateId,
        safetyTestingCategoryItem: item.safetyTestingCategoryItem,
        templateId: item.id,
        adminCategory: false,
      });
      loadData();
      setSafetyTestingCategoryItem("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const editItem = async (id, title) => {
    const trackingRef = doc(database, "safetyTestingCategories", id);
    try {
      await updateDoc(trackingRef, {
        safetyTestingCategoryItem: title,
      });
      loadData();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteItem = async (id) => {
    const isConfirmed = window.confirm("האם למחוק את הקטגוריה לצמיתות?");
    if (isConfirmed) {
      try {
        await deleteDoc(doc(database, "safetyTestingCategories", id)).then(
          (result) => {
            loadData();
          }
        );
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>קטגוריות בדיקות בטיחות</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-4">
          <h6>קטגוריות בדיקות בטיחות</h6>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-9">
              <input
                value={safetyTestingCategoryItem}
                onChange={(e) => setSafetyTestingCategoryItem(e.target.value)}
                type="text"
                placeholder="שם הבדיקה"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <button
                onClick={addSafetyCategoryTesting}
                className="btn btn-success"
              >
                הוספה
              </button>
            </div>
            <div className="col-lg-12">
              {userPersonalData.role === "superadmin" && (
                <>
                  <input
                    onChange={(e) => setAdminCategory(e.target.checked)}
                    checked={adminCategory}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הוסף קטגוריה לכל המשתמשים
                  </label>
                </>
              )}
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {isLoading ? (
                <>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  <p style={{ marginBottom: 20 }}>
                    <b>הקטגוריות שלי</b>
                  </p>
                  {safetyTestingCategory.length > 0 ? (
                    <>
                      {safetyTestingCategory.map((item) => (
                        <CategoryTesting
                          key={item.id}
                          item={item}
                          editItem={editItem}
                          deleteItem={deleteItem}
                        />
                      ))}
                    </>
                  ) : (
                    <p>לא קיימות קטגוריות תרגילי בטיחות</p>
                  )}

                  <p style={{ marginBottom: 12, marginTop: 20 }}>
                    <b>קטגוריות מערכת</b>
                  </p>
                  {safetyTestingCategoryAdmin.length > 0 ? (
                    <>
                      {safetyTestingCategoryAdmin
                        .filter(
                          (adminItem) =>
                            !safetyTestingCategory.some(
                              (personalItem) =>
                                personalItem.templateId === adminItem.id
                            )
                        )
                        .map((item) => (
                          <CategoryTestingAdmin
                            role={userPersonalData.role}
                            addTemplateToMyList={addTemplateToMyList}
                            key={item.id}
                            item={item}
                            editItem={editItem}
                            deleteItem={deleteItem}
                          />
                        ))}
                    </>
                  ) : (
                    <p>לא קיימות קטגוריות תרגילי בטיחות</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-4"></div>
      </div>
    </>
  );
};

export default SafetyTesting;
