import React from "react";
import {
  FcInTransit,
  FcOrganization,
  FcBusinessman,
  FcFile,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import {
  ImUsers,
  ImBriefcase,
  ImFlag,
  ImRocket,
  ImTruck,
  ImTrophy,
} from "react-icons/im";

const BoxesFlat = (props) => {
  const sitesInfo = "sites";
  let linkSites = "/dashboard";
  if (props.selectedCompany) {
    linkSites = `/company-edit/${props.selectedCompany.id}/${sitesInfo}`;
  }

  return (
    <>
      <div className="row mtop">
        <div className="col-lg-2">
          <div className="card">
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>עובדים בחברה</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyEmployees.length}</p></div>
                    <div className="col-lg-5"><ImUsers size={60} color="#999" /></div>
                </div>
              </div>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card">
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>ועדות בטיחות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyCommittees.length}</p></div>
                    <div className="col-lg-5"><ImBriefcase size={60} color="#999" /></div>
                </div>
              </div>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card">
            {props.selectedSite ? (
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>ממצאים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyFindings.length}</p></div>
                    <div className="col-lg-5"><ImFlag size={60} color="#999" /></div>
                </div>
                </div>
            ) : (
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>ממצאים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyFindings.length}</p></div>
                    <div className="col-lg-5"><ImFlag size={60} color="#999" /></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card">
            {props.selectedSite ? (
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>מעקבים תקופתיים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyMachinesReports.length}</p></div>
                    <div className="col-lg-5"><ImTruck size={60} color="#999" /></div>
                </div>
                </div>
            ) : (
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>מעקבים תקופתיים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyMachinesReports.length}</p></div>
                    <div className="col-lg-5"><ImTruck size={60} color="#999" /></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card">
            {props.selectedSite ? (
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>דוחות בטיחות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companySafetyReports.length}</p></div>
                    <div className="col-lg-5"><ImRocket size={60} color="#999" /></div>
                </div>
                </div>
            ) : (
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>דוחות בטיחות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companySafetyReports.length}</p></div>
                    <div className="col-lg-5"><ImRocket size={60} color="#999" /></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card">
            {
              props.selectedSite ? (
                
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>הדרכות / תרגילים</p></div>
                    <div className="col-lg-7">
                    <p className="db-box-number222" style={{ color: "#000" }}>
                    {props.companyTrainings.length} / 
                    {props.companySafetyTests.length}</p>
                    </div>
                    <div className="col-lg-5"><ImTrophy size={60} color="#999" /></div>
                </div>
                </div>
              ) : (
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#000" }}>הדרכות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#000" }}>{props.companyTrainings.length}</p></div>
                    <div className="col-lg-5"><ImTrophy size={60} color="#999" /></div>
                </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default BoxesFlat;
