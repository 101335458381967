import React, { useState, useEffect } from 'react';
import useDashboardStore from "../../store/useDashboardStore";
import moment from "moment";
import 'moment/locale/he';

const Logs = () => {
  const {
    userPersonalData,
    accounts,
    companies,
    logs
  } = useDashboardStore();

  const [filteredLogs, setFilteredLogs] = useState([]);
  const [logTitles, setLogTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");


  useEffect(() => {
    moment.locale('he');
    const userLogs = filterLogsByRole(logs, userPersonalData);
    const groupedLogs = groupLogsByDate(userLogs);
    setFilteredLogs(groupedLogs);
    const uniqueLogTitles = [...new Set(userLogs.map(log => log.logTitle))];
    setLogTitles(uniqueLogTitles);
  }, [logs, userPersonalData]);

  const filterLogsByRole = (logs, user) => {
    if (user.role === 'admin' || user.role === 'manager' || user.role === 'superadmin') {
      return logs;
    } else if (user.role === 'user') {
      return logs.filter(log => log.createdBy === user.uuid);
    }
    return [];
  };

  const groupLogsByDate = (logs) => {
    const groupedLogs = {};
    logs.forEach((log) => {
      const date = moment(log.createdAt).format('YYYY-MM-DD');
      if (!groupedLogs[date]) {
        groupedLogs[date] = [];
      }
      groupedLogs[date].push(log);
    });
    return groupedLogs;
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    setSelectedTitle(title);
    const userLogs = filterLogsByRole(logs, userPersonalData);
    const filtered = userLogs.filter(log => {
      const matchesTitle = title === "" || log.logTitle === title;
      const matchesCompany = selectedCompany === "" || log.associateCompanyId === selectedCompany;
      return matchesTitle && matchesCompany;
    });
    setFilteredLogs(groupLogsByDate(filtered));
  };

  const handleCompanyChange = (e) => {
    const company = e.target.value;
    setSelectedCompany(company);
    const userLogs = filterLogsByRole(logs, userPersonalData);
    const filtered = userLogs.filter(log => {
      const matchesTitle = selectedTitle === "" || log.logTitle === selectedTitle;
      const matchesCompany = company === "" || log.associateCompanyId === company;
      return matchesTitle && matchesCompany;
    });
    setFilteredLogs(groupLogsByDate(filtered));
  };

  const getUserName = (userId) => {
    const user = accounts.find(account => account.uuid === userId);
    return user ? `${user.firstName} ${user.lastName}` : "Unknown User";
  };

  const getCompanyName = (companyId) => {
    const company = companies.find(company => company.id === companyId);
    return company ? company.companyName : "Unknown Company";
  };

  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-12">
          <h3>פעולות במערכת</h3>
        </div>
      </div>
      <div className="row heading-container-filter">
        <div className="col-lg-4">
          <select className="form-control" value={selectedTitle} onChange={handleTitleChange}>
            <option value="">בחר קטגוריה לסינון...</option>
            {logTitles.map((title, index) => (
              <option key={index} value={title}>{title}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-4">
          <select className="form-control" value={selectedCompany} onChange={handleCompanyChange}>
            <option value="">בחר חברה לסינון...</option>
            {companies.map((company, index) => (
              <option key={index} value={company.id}>{company.companyName}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="row" style={{ padding: 50 }}>
        {Object.keys(filteredLogs).length > 0 ? (
          <>
            {Object.keys(filteredLogs).sort((a, b) => new Date(b) - new Date(a)).map(date => (
              <div key={date}>
                <h4 className='log-title'>{moment(date).format('LL')}</h4>
                {
                  filteredLogs[date].sort((a, b) => b.createdAt - a.createdAt).map(log => (
                  <div className='log-row' key={log.id}>
                    <p className='log-sub-title'>{log.logTitle} - {getUserName(log.createdBy)} - {getCompanyName(log.associateCompanyId)}</p>
                    <p className='log-desc'>{log.logDescription}</p>
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <p>לא נרשמו פעולות במערכת</p>
        )}
      </div>
    </>
  );
};

export default Logs;
