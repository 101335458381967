import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ReportItem from "../../components/ReportItem";
import useDashboardStore from "../../store/useDashboardStore";
import { saveLog } from "../../services/createLog";
import { database } from "../../services/firebase-config";
import { deleteDoc, doc } from "firebase/firestore";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FaRegFilePdf } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import ReportItemExp from "../../components/ReportItemExp";
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import moment from "moment";

const SafetyReports = () => {
  const navigate = useNavigate();

  const {
    userPersonalData,
    selectedCompany,
    myCompany,
    selectedSite,
    safetyReports,
    employees,
  } = useDashboardStore();
  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [projects, setProjects] = useState(selectedCompany.projects);

  useEffect(() => {
    if (selectedCompany && selectedSite) {
      const filteredReports = safetyReports.filter(
        (x) =>
          x.associateCompanyId === selectedCompany.id &&
          x.siteId === selectedSite.siteId
      );
      setReports(filteredReports);
    }
  }, [selectedCompany, selectedSite, safetyReports]);

  const filterReportsByDate = (fromDateVal, toDateVal) => {
    if (!fromDateVal || !toDateVal) {
      return;
    }
    const fromDateTimestamp = new Date(fromDateVal).getTime();
    const toDateTimestamp = new Date(toDateVal).getTime();
    const filteredReports = safetyReports.filter(
      (report) =>
        report.createdAt >= fromDateTimestamp &&
        report.createdAt <= toDateTimestamp &&
        report.associateCompanyId === selectedCompany.id &&
        report.siteId === selectedSite.siteId
    );
    setReports(filteredReports);
  };

  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`report_${timestamp}.pdf`);
  };
  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };

  const handleFromDateChange = (e) => {
    const fromDateVal = e.target.value;
    setFromDate(fromDateVal);
    filterReportsByDate(fromDateVal, toDate);
  };

  const handleToDateChange = (e) => {
    const toDateVal = e.target.value;
    setToDate(toDateVal);
    filterReportsByDate(fromDate, toDateVal);
  };

  const addNewReportAction = () => {
    if (selectedCompany && selectedSite) {
      navigate(
        `/add-safety-report/${selectedCompany.id}/${selectedSite.siteId}`
      );
    } else {
      toast.error("נא לבחור את שם האתר לפני הוספת הדוח");
    }
  };

  const removeReport = async (reportId) => {
    try {
      const isConfirmed = window.confirm("האם למחוק את דוח הבטיחות?");
      if (isConfirmed) {
        try {
          await deleteDocument(reportId);
        } catch (error) {
          console.error("Failed to delete the document:", error);
        }
      }

      const logData = {
        associateCompanyId: selectedCompany.id,
        associateId: userPersonalData.associateId,
        createdBy: userPersonalData.uuid,
        logTitle: "הסרת דוח בטיחות",
        logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} הסיר דוח בטיחות לחברת ${selectedCompany.companyName}`,
      };

      saveLog(logData);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteDocument = async (reportId) => {
    try {
      await deleteDoc(doc(database, "safetyReports", reportId));
      useDashboardStore.getState().removeSafetyReport(reportId);
      toast.success("הדוח הוסר מהמערכת");
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };




  const exportToExcel = (reports) => {
    const date = new Date();
    const timestamp = date.getTime(); // This gives you the date as a long number
    const fileName = `SafetyReports_${timestamp}.xlsx`;

    // Define headers in the order you want them to appear (right to left)
    const headers = [
        { header: 'ציון דוח כללי', key: 'totalAverage', width: 10 },
        { header: 'במעקב', key: 'trackInCharge', width: 10 },
        { header: 'מנהל בפועל', key: 'manageInCharge', width: 15 },
        { header: 'קטגוריות משנה', key: 'subCategories', width: 40 },    
        { header: 'סוג הבדיקה', key: 'safetyReportItem', width: 10 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'מספר מזהה', key: 'id', width: 10 },
    ];

    const data = reports.map(report => ({
      id: report.id,
      createdAt: moment(report.createdAt).format('DD/MM/YYYY'),
      safetyReportItem: report.safetyReportItem,
      subCategories: 
        report.subCategories.map((sub) => {
          return `${sub.safetyReportSubItem} | ציון: ${sub.score}`;
        }).join(', '),
      manageInCharge: employees.find((x) => x.id === report.manageInCharge)?.firstName + ' ' + employees.find((x) => x.id === report.manageInCharge)?.lastName,
      trackInCharge: employees.find((x) => x.id === report.trackInCharge)?.firstName + ' ' + employees.find((x) => x.id === report.trackInCharge)?.lastName,
      totalAverage: report.totalAverage
  }));
  

    // Create a new worksheet and apply the headers
    const ws = XLSX.utils.json_to_sheet(data, { header: headers.map(header => header.key), skipHeader: true });

    // Manually add the headers to the first row
    for (let i = 0; i < headers.length; i++) {
        ws[XLSX.utils.encode_cell({ c: i, r: 0 })] = { v: headers[i].header };
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");

    // Write the workbook file
    XLSX.writeFile(wb, fileName);
};




  return (
    <>
      <ToastContainer />
      <div className="row heading-container">
        <div className="col-lg-9">
          <h3>
            דו״חות בטיחות | {selectedCompany.companyName} | פרויקט{" "}
            {selectedSite.projectName}
          </h3>
        </div>
        <div className="col-lg-3 heading-btn-container">
          <button onClick={addNewReportAction} className="btn btn-success btn-lg">
            הוספת דו״ח בטיחות
          </button>
          <button style={{marginRight:12}} onClick={handleModal} className="btn btn-danger">
            <FaRegFilePdf size={30} color="#ffffff" />
          </button>
          <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(reports)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
        </div>
      </div>

      <div className="row heading-container-filter">
        <div className="col-lg-3">
          <label className="form-label">מתאריך</label>
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </div>

        <div className="col-lg-3">
          <label className="form-label">עד תאריך</label>
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={handleToDateChange}
          />
        </div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        {isLoading ? (
          <div className="spinner-border text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {reports.length > 0 ? (
              reports.map((report) => (
                <ReportItem
                  key={report.id}
                  report={report}
                  employeesArr={employees}
                  projects={projects}
                  removeReport={removeReport}
                />
              ))
            ) : (
              <p>לא נמצאו דוחות בטווח התאריכים שנבחר</p>
            )}
          </>
        )}
      </div>

      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה
                      <br />
                      הופק באמצעות
                      <br />
                      מערכת לממונה
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                {selectedCompany && selectedCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={selectedCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{selectedCompany.companyName}</b>
                          <br />
                          {selectedCompany.companyAddress},{" "}
                          {selectedCompany.companyCity}
                          <br />
                          ח.פ. {selectedCompany.companyTaxId}
                          <br />
                          {selectedCompany.companyEmail} |{" "}
                          {selectedCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:50 }}>
              <div className="col-lg-12">
                <h2>דוחות בטיחות | {selectedCompany.companyName} |{" "}{selectedSite.projectName}</h2>
              </div>
            </div>

            {reports.length > 0 ? (
              reports.map((report) => (
                <ReportItemExp
                  key={report.id}
                  report={report}
                  employeesArr={employees}
                  projects={projects}
                  removeReport={removeReport}
                />
              ))
            ) : (
              <p>לא נמצאו דוחות בטווח התאריכים שנבחר</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg"
          >
            שמירת קובץ
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SafetyReports;
