import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  getAccountDetails,
} from "../../services/dbHelper";
import {useAccountStore} from '../../store/useAccountStore';

const Profile = () => {
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);

  const fileInputRef = useRef(null);
  const [myAccount, setMyAccount] = useState("");
  const [progress, setProgress] = useState(0);
  const setProfile = useAccountStore((state) => state.setProfile);


  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);
      setMyAccount(account);
      setAddress(account.contact.address);
      setCity(account.contact.city);
      setPhone(account.contact.phone);
      setFirstName(account.firstName);
      setLastName(account.lastName);
      setAvatar(account.avatar);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    loadData();
  }, []);





  
  const uploadLogo = async () => {
    setIsLoadingLogo(true);
    if (avatar) {
      const storageRef = ref(storage, `files/${uploadImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, uploadImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(prog);
        },
        (error) => {
          console.log(error);
          setIsLoadingLogo(false); // Ensure loading is set to false on error as well
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (downloadURL) => {

              const committeesRef = doc(database, "accounts", myAccount.id);
              const acc = { avatar: downloadURL };
  
              await updateDoc(committeesRef, acc);
  
              setProfile((prevProfile) => {
                // Create the updated profile object
                const updatedProfile = { ...prevProfile, avatar: downloadURL };

                // Log the updated profile object for debugging
                console.log("Updated Profile:", updatedProfile);

                // Return the updated profile to update the state
                return updatedProfile;
              });
  
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              setIsLoadingLogo(false);
              // Assume loadData() refreshes data somewhere, make sure it also uses Zustand state if needed
              loadData();
            });
        }
      );
    } else {
      setIsLoadingLogo(false); // Handle case when avatar is not selected
    }
  };
  

  const updateCompany = async () => {
    setIsLoadingInfo(true)

    try {
      const committeesRef = doc(database, "accounts", myAccount.id);
      const acc = {
        contact: {
          address: address,
          phone: phone,
          city: city
        },
        firstName: firstName,
        lastName: lastName
      }
      await updateDoc(committeesRef, acc)
      .then(async (res) => {

        const updatedProfile = {
          ...myAccount, // Spread the existing account data
          ...acc, // Overwrite and add the updated fields
        };

      setProfile(updatedProfile);
        setIsLoadingInfo(false)
        loadData()
      });

    } catch (error) {
      setIsLoadingInfo(false);
      toast.error(error.message)
    }
  };



  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>הפרופיל שלי</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-3">
          <h6>עדכון תמונת פרופיל</h6>
          <br />
          <label className="form-label">העלאה / עדכון תמונת פרופיל</label>
          <input
            ref={fileInputRef}
            type="file"
            className="form-control"
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          {isLoadingLogo ? (
            <>
            <div style={{width:'100%', alignItems:'center'}}>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            </>
          ) : (
            <>
              <button
                onClick={uploadLogo}
                style={{ marginTop: 20 }}
                className="btn btn-success btn-lg">העלאת תמונת פרופיל</button>
            </>
          )}

          {myAccount && (
            <>
              {avatar && (
                <img src={avatar} style={{ width: 180, marginTop: 20 }} />
              )}
            </>
          )}
        </div>
        <div className="col-lg-4">
          <h6>עדכון פרטים אישיים</h6>
          <br />

          <label className="form-label">
            שם פרטי <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />


          <label className="form-label">
            שם משפחה <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />



          <label className="form-label">כתובת מלאה</label>
          <input
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />


          <label className="form-label">עיר</label>
          <input
            type="text"
            className="form-control"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />


          <label className="form-label">טלפון</label>
          <input
            type="text"
            className="form-control"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />


          {isLoadingInfo ? (
            <>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </>
          ) : (
            <>
              <button
                onClick={updateCompany}
                style={{ marginTop: 20 }}
                className="btn btn-success btn-lg">עדכון פרטים</button>
            </>
          )}
        </div>
        <div className="col-lg-5">
        </div>
      </div>
    </>
  );
};

export default Profile;
