import React from 'react'
import { IoSettingsSharp } from "react-icons/io5";

const SafetyReportSubItemScore = (props) => {
  return (
    <div className='row safety-item-row'>
        <div className='col-lg-8'>
            <p>{props.score.safetyReportSubItemTitleName}</p>
        </div>
        <div className='col-lg-2'>
            <p className='site-name'>{props.score.safetyReportSubItemTitleScore}</p>
        </div>
        <div className='col-lg-2'>
            <button className='btn btn-light'>
                <IoSettingsSharp color="#A3BAC3" size={20} />
            </button>
        </div>
    </div>
  )
}

export default SafetyReportSubItemScore