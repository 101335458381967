import React from "react";
import { FcAdvertising, FcStatistics } from "react-icons/fc";
import { Link } from "react-router-dom";

const Trainings = () => {
  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>הדרכות</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-3">
          <Link to="/trainings-groups">
            <div
              className="card"
              style={{ textAlign: "center", alignItems: "center", padding: 10 }}>
              <FcAdvertising size={80} />
              <div className="card-body">
                <h5 className="card-title">הדרכות</h5>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3">
          <Link to="/trainings-items">
            <div
              className="card"
              style={{ textAlign: "center", alignItems: "center", padding: 10 }}>
              <FcStatistics size={80} />
              <div className="card-body">
                <h5 className="card-title">תרגילי בטיחות</h5>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3"></div>
        <div className="col-lg-3"></div>
      </div>
    </>
  );
};

export default Trainings;
