import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {collection,addDoc,doc,query,where,getDocs,setDoc} from "firebase/firestore";
import useDashboardStore from "../../store/useDashboardStore";
import moment from "moment";
import { Link } from "react-router-dom";

const MyCompany = () => {

  const {userPersonalData,myCompany, mainAccount,plans} = useDashboardStore();


  const [companyName, setCompanyName] = useState(myCompany.companyName);
  const [companyAddress, setCompanyAddress] = useState(myCompany.companyAddress);
  const [companyTaxId, setCompanyTaxId] = useState(myCompany.companyTaxId);
  const [companyCity, setCompanyCity] = useState(myCompany.companyCity);
  const [companyPhone, setCompanyPhone] = useState(myCompany.companyPhone);
  const [companyEmail, setCompanyEmail] = useState(myCompany.companyEmail);
  const [companyLogo, setCompanyLogo] = useState(myCompany.companyLogo);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const fileInputRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('');


  const uploadLogo = async () => {
    setIsLoadingLogo(true);
  
    if (fileInputRef.current.files && fileInputRef.current.files[0]) {
      const selectedFile = fileInputRef.current.files[0];
      const storageRef = ref(storage, `companiesLogos/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => {
          console.error(error);
          setIsLoadingLogo(false);
          toast.error("התרחשה שגיאה בהעלאת הלוגו");
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const companiesOwnerRef = collection(database, "companiesOwner");
            const q = query(
              companiesOwnerRef,
              where("associateId", "==", userPersonalData.associateId)
            );
            const querySnapshot = await getDocs(q);
  
            const logo = {
              companyLogo: downloadURL,
              associateId: userPersonalData.associateId,
            };
  
            if (querySnapshot.empty) {
              await addDoc(companiesOwnerRef, logo);
            } else {
              querySnapshot.forEach(async (document) => {
                const docRef = doc(database, "companiesOwner", document.id);
                await setDoc(docRef, logo, { merge: true });
              });
            }
  
            // Update Zustand store
            useDashboardStore.getState().updateCompanyLogo(downloadURL);
  
            // Update local state
            setCompanyLogo(downloadURL);
  
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            setIsLoadingLogo(false);
            toast.success("לוגו החברה עודכן בהצלחה");
          } catch (error) {
            console.error(error.message);
            setIsLoadingLogo(false);
            toast.error("התרחשה שגיאה בעדכון הלוגו ב-Firestore");
          }
        }
      );
    } else {
      setIsLoadingLogo(false);
      toast.error("נא לבחור לוגו להעלאה");
    }
  };
  

  const updateCompany = async () => {
    setIsLoadingInfo(true);
  
    const companiesOwnerRef = collection(database, "companiesOwner");
    const q = query(companiesOwnerRef, where("associateId", "==", userPersonalData.associateId));
    const querySnapshot = await getDocs(q);
  
    let company = {
      companyName: companyName,
      associateId: userPersonalData.associateId,
      companyAddress: companyAddress,
      companyCity: companyCity,
      companyEmail: companyEmail,
      companyPhone: companyPhone,
      companyTaxId: companyTaxId,
    };
  
    
    if (querySnapshot.empty) {
      await addDoc(companiesOwnerRef, company);
    } else {
      querySnapshot.forEach(async (document) => {
        const docRef = doc(database, "companiesOwner", document.id);
        await setDoc(docRef, company, { merge: true });
      });
    }
    useDashboardStore.getState().updateMyCompany(company);
    setIsLoadingInfo(false);
  };

  useEffect(() => {

    
    if(plans.length > 0 && mainAccount && mainAccount.role !== 'superadmin'){
      const planet = plans.find(x => x.id === mainAccount.planId);
      setSelectedPlan(planet);
    }

  },[plans,mainAccount])


  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>פרטי החברה שלי</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-3">
          <h6>עדכון לוגו חברה</h6>
          <br />
          <label className="form-label">
            העלאה / עדכון לוגו חברה <span style={{ color: "red" }}>*</span>
          </label>
          <input
            ref={fileInputRef}
            type="file"
            className="form-control"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const selectedFile = e.target.files[0];
                setCompanyLogo(URL.createObjectURL(selectedFile));
              }
            }}
          />

          {isLoadingLogo ? (
            <>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </>
          ) : (
            <>
              <button
                onClick={uploadLogo}
                style={{ marginTop: 20 }}
                className="btn btn-success btn-lg">העלאת לוגו חברה</button>
            </>
          )}

          <>
              {companyLogo && (
                <img src={companyLogo} style={{ width: 180, marginTop: 20 }} />
              )}
            </>
        </div>
        <div className="col-lg-4">
          <h6>עדכון פרטי החברה</h6>
          <br />
          <label className="form-label">
            שם החברה <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
          />
          <label className="form-label">כתובת מלאה</label>
          <input
            type="text"
            className="form-control"
            value={companyAddress}
            onChange={(e) => {
              setCompanyAddress(e.target.value);
            }}
          />
          <label className="form-label">עיר</label>
          <input
            type="text"
            className="form-control"
            value={companyCity}
            onChange={(e) => {
              setCompanyCity(e.target.value);
            }}
          />
          <label className="form-label">
            כתובת דואר אלקטרוני לקבלת עדכונים ודוחות{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={companyEmail}
            onChange={(e) => {
              setCompanyEmail(e.target.value);
            }}
          />
          <label className="form-label">טלפון</label>
          <input
            type="text"
            className="form-control"
            value={companyPhone}
            onChange={(e) => {
              setCompanyPhone(e.target.value);
            }}
          />
          <label className="form-label">
            מספר עוסק מורשה / ח.פ. <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={companyTaxId}
            onChange={(e) => {
              setCompanyTaxId(e.target.value);
            }}
          />

          {isLoadingInfo ? (
            <>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </>
          ) : (
            <>
              <button
                onClick={updateCompany}
                style={{ marginTop: 20 }}
                className="btn btn-success btn-lg">עדכון פרטים</button>
            </>
          )}
        </div>


        <div className="col-lg-5">
          {
            userPersonalData.role !== 'superadmin' ? (<>
            <h6>פרטי מנוי ותשלומים</h6>
            <br/>
            {
              mainAccount && (<>
              <p style={{fontSize:20}}>מסלול נוכחי: <b>{selectedPlan.planName}</b><br/>
              בתוקף עד: <b>{moment(mainAccount.expireDate).format('DD/MM/YYYY')}</b>
              </p>
              
              <div className="card" style={{marginTop:20}}>
                    <div className="card-body">
                        <div>
                          <h6>פרטי המסלול שלי: {selectedPlan.planName}</h6><br/>
                          <p style={{fontSize:20}}>משתמשים: <b>{selectedPlan.users}</b></p>
                          <p style={{fontSize:20}}>חברות: <b>{selectedPlan.companies}</b></p>
                          <p style={{fontSize:20}}>אתרים: <b>{selectedPlan.sites}</b></p>
                          <p style={{fontSize:20}}>עובדים: <b>{selectedPlan.employees}</b></p>
                          <p style={{fontSize:20}}>ייצוא ייבוא מסמכים: <b>{selectedPlan.actions ? 'פתוח' : 'סגור'}</b></p>
                          <p style={{fontSize:20}}>עלות חודשית: <b>{selectedPlan.planCost} ש״ח</b></p>
                      </div>
                  </div>
                </div>

                <Link className='btn btn-success btn-lg' style={{width:'100%', marginTop:20}} to={{pathname: `/planslist`}}>בחירת מסלול אחר</Link>

              </>)
            }
          
            </>) : (<>
            
            </>)
          }
        </div>
      </div>
    </>
  );
};

export default MyCompany;
