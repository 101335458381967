import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';
import useDashboardStore from "../../store/useDashboardStore";

const Planlist = () => {
  const { userPersonalData, myCompany, mainAccount, plans, setTheSelectedPlan, theSelectedPlan } = useDashboardStore();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plansSorted, setPlansSorted] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');

  useEffect(() => {
    const filteredPlans = plans.filter(x => x.displayInSite === true);
    const sortedPlans = filteredPlans.sort((a, b) => a.planCost - b.planCost);
    setPlansSorted(sortedPlans);
    if (plans.length > 0 && mainAccount && mainAccount.planId) {
      const currentPlan = plans.find(plan => plan.id === mainAccount.planId);
      setSelectedPlan(currentPlan);
    }
  }, [plans, mainAccount]);

  const handleOpen = (url) => {
    setPaymentUrl(url);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePlanSelect = (plan) => {
    setTheSelectedPlan(plan); // This sets the selected plan in Zustand store
    handleOpen(plan.payurl); // Opens the modal with the payment URL
  };

  console.log(theSelectedPlan);

  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-12">
          <h3>בחירת מסלול חדש</h3>
        </div>
      </div>

      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="container mt-5">
          <div className="row">
            {plansSorted.length > 0 && plansSorted.map((plan) => (
              <div className="col-md-4" key={plan.id}>
                <div className="card mb-4 box-shadow" style={{background: selectedPlan && selectedPlan.id === plan.id ? '#e9f9cc' : ''}}>
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">{plan.planName}</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      ₪{plan.planCost} <small className="text-muted">/ לחודש</small>
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4" style={{fontSize: 19}}>
                      <li>{plan.sites} אתרים</li>
                      <li>{plan.employees} עובדים</li>
                      <li>{plan.companies} חברות</li>
                      <li>{plan.users} יוזרים</li>
                      <li>{plan.actions ? 'אפשרות ייבוא וייצוא מסמכים פעיל' : 'אפשרות ייבוא וייצוא מסמכים לא פעיל'}</li>
                    </ul>
                    {selectedPlan && selectedPlan.id === plan.id ? (
                      <button type="button" className="btn btn-lg btn-block btn-outline-dark" disabled>פעיל</button>
                    ) : (
                      <button type="button" 
                              onClick={() => handlePlanSelect(plan)} 
                              className="btn btn-lg btn-block btn-outline-success">בחר מסלול</button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>עמוד תשלום</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={paymentUrl} style={{ width: '100%', height: '600px' }} frameBorder="0"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>ביטול</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Planlist;
