import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoSettingsSharp, IoPencilSharp } from "react-icons/io5";
import { storage } from "../../../services/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { IoMdCloseCircle } from "react-icons/io";

const FindingSmallItem = (props) => {
  const project = props?.projects?.find((x) => x.siteId === props.item.site);
  const findingType = props.findingsType.find(
    (x) => x.id === props.item.itemImpairmentArea
  );
  const company = props.companiesArr.find(
    (x) => x.id === props.item.associateCompanyId
  );
  const treater = props.employeesArr.find(
    (x) => x.id === props.item.itemImpairmentTreat
  );
  const tracker = props.employeesArr.find(
    (x) => x.id === props.item.itemImpairmentTrack
  );

  let priorityColor = "#ffcc00";
  let statusColor = "#ffcc00";

  if (props.item.itemImpairmentSeverity === "קל") {
    priorityColor = "#67eb34";
  }
  if (props.item.itemImpairmentSeverity === "בינוני") {
    priorityColor = "#ffcc00";
  }
  if (
    props.item.itemImpairmentSeverity === "חמור" ||
    props.item.itemImpairmentSeverity === "מסכן חיים"
  ) {
    priorityColor = "#EB6079";
  }

  if (props.item.status === "סגור") {
    statusColor = "#6fcb9f";
  }
  if (props.item.status === "פעיל") {
    statusColor = "#ff9933";
  }
  if (props.item.status === "בטיפול") {
    statusColor = "#ffcf40";
  }

  return (
    <div
      className="row"
      style={{
        marginBottom: 12,
        padding: 5,
        borderRadius: 12,
        background: "#F8FCFC",
      }}
    >
      <div className="col-lg-6">
        <h6>פרויקט: {project?.projectName}</h6>
        <p>
          תחום הליקוי: <b>{findingType.safetyReportAreaItem}</b>
        </p>
        <p>
          קבצים מצורפים: <b>{props.item.gallery.length} קבצים</b> | סטטוס ממצא:{" "}
          <b
            style={{
              color: props.item.status === "פעיל" ? "#EB6079" : "#67eb34",
            }}
          >
            {props.item.status}
          </b>
        </p>
      </div>

      <div className="col-lg-2" style={{ textAlign: "center" }}>
        <p style={{ marginTop: 0 }}>ליקוי</p>
        <div className="badge" style={{ backgroundColor: priorityColor }}>
          <h6 style={{ color: "#ffffff" }}>
            {props.item.itemImpairmentSeverity}
          </h6>
        </div>
      </div>

      <div className="col-lg-3">
        <p style={{ marginTop: 14 }}>
          הוקם: {moment(props.item.createdAt).format("DD/MM/YYYY")}
          <br />
          <b>למעקב של: </b>
          {tracker?.firstName} {tracker?.lastName}
          <br />
          <b>לטיפול של: </b>
          {treater?.firstName} {treater?.lastName}
          <br />
        </p>
      </div>

      <div
        className="col-lg-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          className="btn btn-success btn-sm"
          onClick={() => {
            props.setSmallFindingItemsIndex(() => props.index);
          }}
        >
          הוספה
        </button>
      </div>
    </div>
  );
};

export default FindingSmallItem;
