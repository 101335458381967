import React from 'react'
import { IoSettingsSharp } from "react-icons/io5";

const SiteSmall = (props) => {
  return (
    <div className='row sitesmall-row'>
        <div className='col-lg-1' style={{overflow:'hidden'}}>
          <img style={{width:'100%'}} src={props.project.projectImage} alt={props.project.projectName} />
        </div>
        <div className='col-lg-9'>
            <p className='site-name'>{props.project.projectName}</p>
            <p>{props.project.projectAddress}, {props.project.projectCity}</p>
        </div>
        <div className='col-lg-2'>
          <button className='btn btn-light' onClick={() => {props.setSelectedProject(props.project)}}><IoSettingsSharp color="#A3BAC3" size={20} /></button>
        </div>
    </div>
  )
}

export default SiteSmall