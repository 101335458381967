import React from 'react'
import { MdArrowBackIosNew,MdDelete } from "react-icons/md";
const UserCard = (props) => {
  return (
    <div className='row' style={{marginBottom:20}} onClick={() => {props.setSelectedUser(props.user)}}>
      <div className='col-lg-2'>
        <img src={props.user.avatar} alt={props.user.firstName} className='rounded mx-auto d-block' />
      </div>
      <div className='col-lg-9'>
        <h6>{props.user.lastName} {props.user.firstName} | {props.user.code} {!props.user.isDisplay && (<span style={{color:'red'}}>משתמש הוסר מהמערכת</span>)}</h6>
        <p><b>רמת הרשאה: </b>{props.user.role} | <b>מצב חשבון: </b> {props.user.isUserLock ? "חשבון נעול" : "חשבון פעיל"}</p>
        <p><b>דואר אלקטרוני: </b>{props.user.email}, <b>טלפון: </b>{props.user.contact.phone}</p>
      </div>
      <div className='col-lg-1' style={{display:'flex', alignItems:'center'}}>
        <MdArrowBackIosNew color="#01A7C2" size={20} />
      </div>
    </div>
  )
}

export default UserCard