import moment from "moment";
import React, { useState,useEffect, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { BsFillFilePdfFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";

const SafetyTestItem = (props) => {
  const [comitEdidView, setComitEditView] = useState(false);
  const [trainingName, settrainingName] = useState(props.group.trainingName);
  const [trainingDesc, settrainingDesc] = useState(props.group.trainingDesc);
  const [trainingDate, settrainingDate] = useState(props.group.trainingDate);
  const [trainingNextDate, settrainingNextDate] = useState(props.group.trainingNextDate);
  const [trainingFile, settrainingFile] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(props.group.employees);
  const [employeesArr, setEmployeesArr] = useState(props.employeesArr);
  const [selectedTrainingCategory, setSelectedTrainingCategory] = useState(props.group.selectedTrainingCategory);


  const handleRemove = (emp) => {
    setSelectedEmployees(selectedEmployees.filter((v) => v.id !== emp.id));
    setEmployeesArr([...employeesArr, emp]); // Add back to the dropdown options
  };

  const handleSelect = (empId) => {
    const emp = props.employeesArr.find((x) => x.id === empId); // Use the original prop to find the employee
    if (emp && !selectedEmployees.some((e) => e.id === emp.id)) {
      setSelectedEmployees([...selectedEmployees, emp]);
    }
  };

  useEffect(() => {
    setEmployeesArr(
      props.employeesArr.filter(
        (e) => !selectedEmployees.some((se) => se.id === e.id)
      )
    );
  }, [selectedEmployees, props.employeesArr]);




  let numberOfDays = 0;

  const calculateDays = () => {
    var startDate = moment(props.group.trainingNextDate).format("MM/DD/YYYY");
    var currenDate = moment(new Date()).format("MM/DD/YYYY");
    numberOfDays = datediff(parseDate(currenDate), parseDate(startDate));
  };

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  function parseDate(str) {
    var mdy = str.split("/");
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  calculateDays();


  let textColor = "#000000";
  if (numberOfDays <= 0) {
    textColor = "#EB6079";
  } else if (numberOfDays > 0 && numberOfDays < 30) {
    textColor = "#ff9900";
  } else {
    textColor = "#000000";
  }


  return (
    <>
      {comitEdidView ? (
        <>
          <div className="row rowshadowbox">
            <div className="row">

              <div className="col-lg-3">
              <label className="form-label">קטגוריית תרגיל בטיחות</label>
              <select
                value={selectedTrainingCategory}
                onChange={(e) => setSelectedTrainingCategory(e.target.value)}
                className="form-control"
              >
                <option value="">בחר קטגוריה מהרשימה...</option>
                {props.safetyCategories.length > 0 && (
                  <>
                    {props.safetyCategories.map((item) => (
                      <option selected={selectedTrainingCategory === item.id ? true : false}  key={item.id} value={item.id}>{item.safetyTestingCategoryItem}</option>
                    ))}
                  </>
                )}
              </select>
              </div>

              <div className="col-lg-3">
                <label className="form-label">שם תרגיל הבטיחות</label>
                <input
                  type="text"
                  className="form-control"
                  value={trainingName}
                  onChange={(e) => {
                    settrainingName(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-3">
                <label className="form-label">תאריך התרגיל</label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(trainingDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    settrainingDate(e.target.value);
                  }}
                />
              </div>

              <div className="col-lg-3">
                <label className="form-label">תאריך התרגיל הבא</label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(trainingNextDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    settrainingNextDate(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label className="form-label">תיאור התרגיל</label>
                <textarea
                  className="form-control"
                  value={trainingDesc}
                  onChange={(e) => {
                    settrainingDesc(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label className="form-label">העלאת קובץ חדש</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    settrainingFile(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <select
                  className="form-control"
                  onChange={(e) => {
                    handleSelect(e.target.value);
                  }}
                >
                  <option value="0">בחר עובדים מהרשימה...</option>
                  {employeesArr.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.lastName} {employee.firstName} {employee.employeeId}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="col-lg-12"
                style={{ paddingTop: 12, paddingBottom: 12 }}
              >
                <span>נכחו בתרגיל: </span>
                {props.group.employees.length > 0 && (
                  <>
                    {selectedEmployees.map((emp) => (
                      <span
                        key={emp.id}
                        onClick={() => {
                          handleRemove(emp);
                        }}
                        style={{ marginRight: 6 }}
                        className="badge text-bg-info"
                      >
                        {emp.firstName} {emp.lastName}
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>

            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <button
                    style={{ marginRight: 12 }}
                    onClick={() => setComitEditView(!comitEdidView)}
                    className="btn btn-dark">ביטול</button>

                  <button
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      props.removeCommittee(props.group);
                      setComitEditView(false);
                    }}
                    className="btn btn-danger"
                  >
                    <MdDelete size={24} />
                  </button>
                </div>

                <button
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    props.updateComit(
                      selectedEmployees,
                      trainingName,
                      trainingDate,
                      trainingNextDate,
                      trainingDesc,
                      trainingFile,
                      props.group.id,
                      selectedTrainingCategory
                    )
                    setComitEditView(!comitEdidView)
                  }
                  }
                  className="btn btn-success">שמירת נתונים</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row rowshadowbox">
            <div className="col-lg-5">
              <h6>{props.group.trainingName}</h6>
              <p>{props.group.trainingDesc}</p>
              <p>
                <b>השתתפו בתרגיל: </b>
                {props.group.employees.map((emp) => (
                  <>
                    {emp.firstName} {emp.lastName},{" "}
                  </>
                ))}
              </p>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>תאריך התרגיל</p>
              <h4>{moment(props.group.trainingDate).format("DD/MM/YYYY")}</h4>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>התרגיל הבא</p>
              <h4>{moment(props.group.trainingNextDate).format("DD/MM/YYYY")}</h4>
              <p style={{ color: textColor }}>{numberOfDays} ימים</p>
            </div>

            <div className="col-lg-1" style={{ textAlign: "center" }}>

            <p>קבצים ({props.group.trainingFiles.length})</p>
              {
                props.group.trainingFiles.length > 0 && (<>
                {
                  props.group.trainingFiles.map((file) => (
                  <a href={file} target="_blank" rel="noreferrer"><BsFillFilePdfFill color="#EB6079" size={30} /></a>))
                }
                </>)
              }

              
            </div>
            <div
              className="col-lg-2"
              style={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <button
                style={{ marginRight: 12 }}
                onClick={() => setComitEditView(!comitEdidView)}
                className="btn btn-success"
              >
                <RiEdit2Fill size={24} />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SafetyTestItem;
