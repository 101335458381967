import { database } from "./firebase-config";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  getDoc,
  orderBy,
  doc,
  addDoc,
} from "firebase/firestore";




//Generic request for array by associate id
export const requestDataByAssociate = async (associateId, collectionRef) => {
  try {
    const dataRef = collection(database, collectionRef);
    const q = query(dataRef, where("associateId", "==", associateId));
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return dataArr;
  } catch (error) {
    return error.message;
  }
};




export const getNumberOfProjects = async (associateId, collectionRef) => {
  try {
    const dataRef = collection(database, collectionRef);
    const q = query(dataRef, where("associateId", "==", associateId));
    const querySnapShot = await getDocs(q);
    let totalProjectsCount = 0;
    querySnapShot.forEach((doc) => {
      const projectsArray = doc.data().projects; // Replace with your actual projects array key
      const projectsCount = projectsArray ? projectsArray.length : 0;
      totalProjectsCount += projectsCount;
    });
    return totalProjectsCount;
  } catch (error) {
    return error.message;
  }
};

export const getNumberOfMachines = async (associateId, collectionRef) => {
  try {
    const dataRef = collection(database, collectionRef);
    const q = query(dataRef, where("associateId", "==", associateId));
    const querySnapShot = await getDocs(q);
    let totalProjectsCount = 0;
    querySnapShot.forEach((doc) => {
      const projectsArray = doc.data().machines; // Replace with your actual projects array key
      const projectsCount = projectsArray ? projectsArray.length : 0;
      totalProjectsCount += projectsCount;
    });
    return totalProjectsCount;
  } catch (error) {
    return error.message;
  }
};



export const getAllProjects = async (associateId, collectionRef) => {
  try {
    const dataRef = collection(database, collectionRef);
    const q = query(dataRef, where("associateId", "==", associateId));
    const querySnapShot = await getDocs(q);
    let allProjects = [];
    querySnapShot.forEach((doc) => {
      const projectsArray = doc.data().projects;
      if (projectsArray) {
        allProjects = [...allProjects, ...projectsArray];
      }
    });
    console.log('DATA CALL...');
    return allProjects;
  } catch (error) {
    console.error('error: ', error);
    return error.message;
  }
};





export const requestDataByAssociateWithLimit = async (associateId, collectionRef) => {
  try {
    const dataRef = collection(database, collectionRef);
    const q = query(dataRef, 
      where("associateId", "==", associateId),
      limit(10));
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return dataArr;
  } catch (error) {
    return error.message;
  }
};

export const requestDataByCompanyWithLimit = async (companyId, collectionRef) => {
  try {
    const dataRef = collection(database, collectionRef);
    const q = query(dataRef, 
      where("associateCompanyId", "==", companyId),
      limit(10));
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('DATA CALL...');
    return dataArr;
  } catch (error) {
    return error.message;
  }
};





//Load employees by company
export const getEmployeesByCompanyId = async (companyId) => {
  try {
    const dataRef = collection(database, "employees");
    const q = query(dataRef, where("associateCompany", "==", companyId));
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('DATA CALL...');
    return dataArr;
  } catch (error) {
    return error.message;
  }
};

//Load employees by company
export const loadDataByCompanyId = async (companyId,collectionName) => {
  console.log('SSSS: ' + companyId);
  try {
    const dataRef = collection(database, collectionName);
    const q = query(
      dataRef, 
      where("associateCompanyId", "==", companyId)
      );
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('DATA CALL...');
    return dataArr;
  } catch (error) {
    return error.message;
  }
};





//Load employees by company
export const loadDataByCompanyIdAndSiteId = async (companyId,siteId,collectionName) => {
  try {
    const dataRef = collection(database, collectionName);
    const q = query(
      dataRef, 
      where("associateCompanyId", "==", companyId),
      where("siteId", "==", siteId),
      );
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('DATA CALL...');
    return dataArr;
  } catch (error) {
    return error.message;
  }
};



export const loadDataByCompanyIdAndSite = async (companyId,siteId,collectionName) => {
  try {
    const dataRef = collection(database, collectionName);
    const q = query(
      dataRef, 
      where("associateCompanyId", "==", companyId),
      where("site", "==", siteId),
      );
    const querySnapShot = await getDocs(q);
    const dataArr = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('DATA CALL...');
    return dataArr;
  } catch (error) {
    return error.message;
  }
};






//Load company by id
export const getCompanyById = async (companyId) => {
  console.log('DATA CALL...');
  try {
    const docRef = doc(database, "companies", companyId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};

export const getReportItemById = async (itemId) => {
  console.log('DATA CALL...');
  try {
    const docRef = doc(database, "safetyReportsItems", itemId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};



//Get account info by firebase uid
export const getAccountDetails = async (uid) => {
  try {
    const accountsRef = collection(database, "accounts");
    const q = query(accountsRef, where("uuid", "==", uid));
    const querySnapShot = await getDocs(q);
    const array = querySnapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log('DATA CALL...');
    return array[0];
  } catch (error) {
    return error.message;
  }
};



export const createLog = async (uid, logTitle, logDecription, associateId,associateCompanyId) => {
  console.log('DATA CALL...');
  try {
    await addDoc(collection(database, "logs"), {
      createdBy: uid,
      createdAt: Date.now(),
      logTitle: logTitle,
      logDecription: logDecription,
      associateId: associateId,
      associateCompanyId:associateCompanyId
    })
      .then((log_created) => {
        return log_created;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};
