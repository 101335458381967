import moment from "moment";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  IoSettingsSharp,
  IoPencilSharp,
} from "react-icons/io5";
import { storage } from "../../../services/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { IoMdCloseCircle } from "react-icons/io";


const FindingItem = (props) => {

  const [downloadURLs, setDownloadURLs] = useState(props.item.gallery);
  const [inputKey, setInputKey] = useState(Date.now());
  const [files, setFiles] = useState([]);
  const [itemImpairmentSeverity, setItemImpairmentSeverity] = useState(props.item.itemImpairmentSeverity);
  const [itemImpairmentArea, setItemImpairmentArea] = useState(props.item.itemImpairmentArea);
  const [itemImpairmentDesc, setItemImpairmentDesc] = useState(props.item.itemImpairmentDesc);
  const [itemImpairmentComments, setItemImpairmentComments] = useState(props.item.itemImpairmentComments);
  const [itemImpairmentLocation, setItemImpairmentLocation] = useState(props.item.itemImpairmentLocation);
  const [itemImpairmentTreat, setItemImpairmentTreat] = useState(props.item.itemImpairmentTreat);
  const [itemImpairmentTrack, setItemImpairmentTrack] = useState(props.item.itemImpairmentTrack);
  const [itemImpairmentImgReq, setItemImpairmentImgReq] = useState(props.item.itemImpairmentImgReq);
  const [itemImpairmentCommReq, setItemImpairmentCommReq] = useState(props.item.itemImpairmentCommReq);
  const project = props?.projects?.find((x) => x.siteId === props.item.site);
  const findingType = props.findingsType.find((x) => x.id === props.item.itemImpairmentArea);
  const company = props.companiesArr.find((x) => x.id === props.item.associateCompanyId);
  const treater = props.employeesArr.find((x) => x.id === props.item.itemImpairmentTreat);
  const tracker = props.employeesArr.find((x) => x.id === props.item.itemImpairmentTrack);
  const [isEditView, setIsEditView] = useState(false);
  const [isTreatEdit, setIsTreatEdit] = useState(false);
  const [targetDate, setTargetDate] = useState(props.item.targetDate);

  let priorityColor = "#ffcc00";
  let statusColor = "#ffcc00"

  if (props.item.itemImpairmentSeverity === "קל") {
    priorityColor = "#6fcb9f";
  }
  if (props.item.itemImpairmentSeverity === "בינוני") {
    priorityColor = "#ffcc00";
  }
  if (
    props.item.itemImpairmentSeverity === "חמור" ||
    props.item.itemImpairmentSeverity === "מסכן חיים"
  ) {
    priorityColor = "#EB6079";
  }



  if (props.item.status === "סגור") {
    statusColor = "#6fcb9f";
  }
  if (props.item.status === "פעיל") {
    statusColor = "#ff9933";
  }
  if (props.item.status === "בטיפול") {
    statusColor = "#ffcf40";
  }

  const uploadFiles = (filesToUpload) => {
    Array.from(filesToUpload).forEach((file) => {
      const storageRef = ref(storage, `findings/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);

            const newImage = {
              imageSource: downloadURL,
              imgId: `${Date.now()}`
            };

            setDownloadURLs((prevURLs) => [...prevURLs, newImage]);

            if (file === filesToUpload[filesToUpload.length - 1]) {
              setInputKey(Date.now());
            }
          });
        }
      );
    });
  };
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles); // Set the files in state
    uploadFiles(selectedFiles); // Call uploadFiles immediately
  };
  const uploadTreatFiles = (filesToUpload) => {
    Array.from(filesToUpload).forEach((file) => {
      const storageRef = ref(storage, `findings/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const newImage = {
              imageSource: downloadURL,
              imgId: `${Date.now()}`
            };

            setTreatImages((prevURLs) => [...prevURLs, newImage]);

            if (file === filesToUpload[filesToUpload.length - 1]) {
              setInputKey(Date.now());
            }
          });
        }
      );
    });
  };
  const handleTreatFileChange = (e) => {
    const selectedFiles = e.target.files;
    setTreatFiles(selectedFiles); // Set the files in state
    uploadTreatFiles(selectedFiles); // Call uploadFiles immediately
  };
  const removeImage = (imgIdToRemove) => {
    setDownloadURLs((prevURLs) => prevURLs.filter(url => url.imgId !== imgIdToRemove));
  };
  const [treatComments, setTreatComments] = useState("")
  const [treatImages, setTreatImages] = useState([])
  const [treatFiles, setTreatFiles] = useState([])

  return (
    <>
      {isEditView ? (
        <>
          <div className="row">


            <div className="col-lg-3">
              <label className="form-label">שם החברה</label>
              <input
                disabled
                value={company.companyName}
                type="text"
                className="form-control"
              />
            </div>


            <div className="col-lg-3">
              <label className="form-label">שם הפרויקט</label>
              <input
                disabled
                value={project?.projectName}
                type="text"
                className="form-control"
              />
            </div>


            <div className="col-lg-3">
              <label className="form-label">
                סינון על פי חומרת הליקוי <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                onChange={(e) => setItemImpairmentSeverity(e.target.value)}
              >
                <option
                  selected={itemImpairmentSeverity === "קל" ? true : false}
                  value="קל"
                >
                  קל
                </option>
                <option
                  selected={itemImpairmentSeverity === "בינוני" ? true : false}
                  value="בינוני"
                >
                  בינוני
                </option>
                <option
                  selected={itemImpairmentSeverity === "חמור" ? true : false}
                  value="חמור"
                >
                  חמור
                </option>
                <option
                  selected={
                    itemImpairmentSeverity === "מסכן חיים" ? true : false
                  }
                  value="מסכן חיים"
                >
                  מסכן חיים
                </option>
              </select>
            </div>


            <div className="col-lg-3">
              <label className="form-label">
                בחר את תחום הליקוי <span style={{ color: "red" }}>*</span>
              </label>
              <select
                onChange={(e) => setItemImpairmentArea(e.target.value)}
                className="form-control"
              >
                <option value="">בחר את תחום הליקוי...</option>
                {props.findingsType.length > 0 && (
                  <>
                    {props.findingsType.map((finding) => (
                      <option
                        selected={
                          itemImpairmentArea === finding.id ? true : false
                        }
                        key={finding.id}
                        value={finding.id}
                      >
                        {finding.safetyReportAreaItem}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>



            <div className="col-lg-3">
              <label className="form-label">בחר יעד לטיפול <span style={{ color: "red" }}>*</span></label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(targetDate).format("YYYY-MM-DD")}
                  onChange={(e) => {setTargetDate(e.target.value);}}
                />
            </div>



            <div className="col-lg-3">
              <label className="form-label">
                מיקום הליקוי באתר<span style={{ color: "red" }}>*</span>
              </label>
              <input
                value={itemImpairmentLocation}
                onChange={(e) => setItemImpairmentLocation(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>

            <div className="col-lg-3">
              <label className="form-label">
                אחראי למעקב <span style={{ color: "red" }}>*</span>
              </label>
              <select
                onChange={(e) => setItemImpairmentTrack(e.target.value)}
                className="form-control"
              >
                <option value="">בחר אחראי למעקב...</option>
                {props.employeesArr.length > 0 && (
                  <>
                    {props.employeesArr.map((emp) => (
                      <option
                        selected={itemImpairmentTrack === emp.id ? true : false}
                        key={emp.id}
                        value={emp.id}
                      >
                        {emp?.firstName} {emp?.lastName}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            <div className="col-lg-3">
              <label className="form-label">
                אחראי לטיפול <span style={{ color: "red" }}>*</span>
              </label>
              <select
                onChange={(e) => setItemImpairmentTreat(e.target.value)}
                className="form-control"
              >
                <option value="">בחר אחראי לטיפול...</option>
                {props.employeesArr.length > 0 && (
                  <>
                    {props.employeesArr.map((emp) => (
                      <option
                        selected={itemImpairmentTreat === emp.id ? true : false}
                        key={emp.id}
                        value={emp.id}
                      >
                        {emp?.firstName} {emp?.lastName}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            <div className="col-lg-12">
              <label className="form-label">
                תיאור הליקוי <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setItemImpairmentDesc(e.target.value);
                }}
              >
                {itemImpairmentDesc}
              </textarea>
            </div>
            <div className="col-lg-12">
              <label className="form-label">הערות לטיפול</label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setItemImpairmentComments(e.target.value);
                }}
              >
                {itemImpairmentComments}
              </textarea>
            </div>

            <div className="col-lg-3">
              <div class="form-check form-switch">
                <input
                  onChange={(e) => setItemImpairmentImgReq(e.target.value)}
                  checked={itemImpairmentImgReq}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label className="form-check-label">לחייב העלאת תמונה בסגירת הדוח</label>
              </div>
            </div>

            <div className="col-lg-3">
              <div class="form-check form-switch">
                <input
                  onChange={(e) => setItemImpairmentCommReq(e.target.value)}
                  checked={itemImpairmentCommReq}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label className="form-check-label">
                  לחייב הוספת תגובה בסגירת הדוח
                </label>
              </div>
            </div>
          </div>

          <div className="row" style={{marginTop:20, marginBottom:50}}>

                <div className="col-lg-12">
                <option value="">העלאת תמונה / תמונות</option>
                  <input
                    key={inputKey}
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </div>

              {downloadURLs.map((url, index) => (
                <div className="col-lg-1" key={index}>
                  <img
                    src={url.imageSource}
                    alt={`Uploaded File ${index + 1}`}
                    style={{ width: "70px", height: "auto" }}
                  />
                  <button className="btn btn-danger btn-sm" onClick={() => removeImage(url.imgId)}>
                  <IoMdCloseCircle size={20} />
                  </button>
                </div>
              ))}

                <div className="col-lg-12" style={{marginTop:20, display:'flex', justifyContent:'space-between'}}>
                  <button onClick={() => setIsEditView(!isEditView)} className="btn btn-dark">ביטול</button>
                  <button 
                    onClick={() => {
                    props.handleDelete(props.item.id)
                    setIsEditView(!isEditView)
                    }
                    } className="btn btn-danger">מחיקה</button>
                  <button
                  onClick={() => 
                    {props.updateFindingItem(
                    itemImpairmentArea,
                    itemImpairmentCommReq,
                    itemImpairmentComments,
                    itemImpairmentDesc,
                    itemImpairmentImgReq,
                    itemImpairmentLocation,
                    itemImpairmentSeverity,
                    itemImpairmentTrack,
                    itemImpairmentTreat,
                    downloadURLs,
                    props.item.id,
                    targetDate
                  ); setIsEditView(false)}}
                  className="btn btn-success">שמירה</button>
                </div>

          </div>
        </>
      ) : isTreatEdit ? (<>
      

        <div className="row">

          <div className="col-lg-12">
            <textarea placeholder="הערות הטיפול" className="form-control" onChange={(e) => setTreatComments(e.target.value)}>{treatComments}</textarea>
          </div>

          <div className="col-lg-12">
          <option value="">העלאת תמונה / תמונות</option>
                  <input
                    key={inputKey}
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleTreatFileChange}
                  />
                  <div className="row">
                    {treatImages.map((url, index) => (
                      <div className="col-lg-1" key={index}>
                        <img
                          src={url.imageSource}
                          alt={`Uploaded File ${index + 1}`}
                          style={{ width: "70px", height: "auto" }}
                        />
                      </div>
                    ))}
                  </div>
          </div>

          <div className="col-lg-12" style={{display:'flex', justifyContent:'space-between', marginTop:20, marginBottom:50}}>
            <button onClick={() => setIsTreatEdit(!isTreatEdit)} className="btn btn-warning">ביטול</button>
            <button onClick={() => {props.updateFindingStatus(
              treatComments, treatImages, props.item.id
            ); setIsTreatEdit(false)}} className="btn btn-success">עדכן סטטוס לטופל</button>
          </div>

        </div>


      </>) 
      : (
        <>
          <div className="row" style={{marginBottom: 0,padding: 5,borderRadius: 12,background: "#F8FCFC",}}>
            
            
            <div className="col-lg-1">
              {props.item.gallery.length > 0 ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: 80,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="finding-row-image"
                      src={props.item.gallery[0].imageSource}
                      alt={props.item.gallery[0].imgId}
                    />
                  </div>
                </>
              ) 
              
              
              
              : (
                <></>
              )}
            </div>


            <div className="col-lg-3">
              <h6>פרויקט: {project?.projectName}</h6>
              <p>תחום הליקוי: <b>{findingType.safetyReportAreaItem}</b></p>
              <p>
                קבצים מצורפים: <b>{props.item.gallery.length} קבצים</b> | סטטוס
                ממצא: <b style={{color: props.item.status === 'פעיל' ? '#EB6079' : '#67eb34'}}>
                  {
                    props.item.status
                  }
                  </b>
              </p>
            </div>


            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 14 }}>תאריך הקמה</p>
              <h6>{moment(props.item.createdAt).format("DD/MM/YYYY")}</h6>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 14 }}>יעד לטיפול</p>
              <h6>{moment(props.item.targetDate).format("DD/MM/YYYY")}</h6>
            </div>


            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 0 }}>ליקוי</p>
              <div className="badge" style={{ backgroundColor: priorityColor }}>
                <h6 style={{ color: "#000000" }}>
                  {props.item.itemImpairmentSeverity}
                </h6>
              </div>
            </div>


            <div className="col-lg-2">
              <p style={{ marginTop: 14 }}>
                <b>למעקב של: </b>
                {tracker?.firstName} {tracker?.lastName}
                <br />
                <b>לטיפול של: </b>
                {treater?.firstName} {treater?.lastName}
                <br />
              </p>
            </div>


            <div className="col-lg-1"style={{display: "flex",alignItems: "center",justifyContent: "center",gap: 10}}>
              <button onClick={() => setIsTreatEdit(!isTreatEdit)}className="btn btn-light" ><IoSettingsSharp color="#A3BAC3" size={20} /></button>
              <button className="btn btn-dark" onClick={() => setIsEditView(!isEditView)}><IoPencilSharp color="#ffffff" size={20} /></button>
            </div>


          </div>
          
          <div className="row" style={{marginBottom:12}}>
            <div className="col-lg-12" 
              style={{
                padding:3,
                display:'flex',
                borderBottomRightRadius:10,
                borderBottomLeftRadius:10,
                backgroundColor:statusColor}}>
              <p style={{color:'#fff', marginTop:13, marginRight:10}}>עדכון סטטוס מממצא: </p>
              <select onChange={(e) => {props.handleStatusChanged(e.target.value, props.item.id)}} className="form-control" style={{width:150, height:40, marginRight:10, marginTop:4}}>
                  <option value='סגור' selected={props.item.status === 'סגור' ? true : false}>סגור</option>
                  <option value='בטיפול' selected={props.item.status === 'בטיפול' ? true : false}>בטיפול</option>
                  <option value='פעיל' selected={props.item.status === 'פעיל' ? true : false}>פעיל</option>
              </select>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FindingItem;
