import React, { useState,useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

const AddEmployee = (props) => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [company, setCompany] = useState("");
  const [constructor, setConstructor] = useState("");
  const [branch, setBranch] = useState("");
  const [hospitality, setHospitality] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [department, setDepartment] = useState("");

  useEffect(() => {
    if (department) {
      const filtered = props.departments.filter((dept) =>
        dept.departmentName.toLowerCase().includes(department.toLowerCase())
      );
      setFilteredDepartments(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setFilteredDepartments([]);
      setShowDropdown(false);
    }
  }, [department, props.departments]);

  const handleSelectDepartment = (deptName) => {
    setDepartment(deptName);
    setShowDropdown(false);
  };

  const addNewEmployee = () => {
    if(firstName !== '' && lastName !== '' && employeeId !== '' && position !== ''){
      props.addNewEmployee(
        firstName,
        lastName,
        address,
        email,
        mobile,
        position,
        employeeId,
        company,
        branch,
        constructor,
        employeeNumber,
        hospitality, department);
      setAddress("");
      setEmail("");
      setFirstName("");
      setLastname("");
      setMobile("");
      setEmployeeId("");
      setPosition("");
      setBranch("");
      setCompany("");
      setConstructor("");
      setHospitality("");
      setEmployeeNumber("")
    } else {
      toast.error("נא למלא את פרטי העובד")
    }
  }

  return (
    <>
    <ToastContainer />
      <div className="row">
        <div className="col-lg-4">
          <label className="form-label">שם פרטי <span style={{color:'red'}}>*</span></label>
          <input type="text" className="form-control" value={firstName} onChange={(e) => {setFirstName(e.target.value);}}/>
        </div>

        <div className="col-lg-4">
          <label className="form-label">שם משפחה <span style={{color:'red'}}>*</span></label>
          <input type="text" className="form-control" value={lastName} onChange={(e) => {setLastname(e.target.value);}}/>
        </div>

        <div className="col-lg-4">
          <label className="form-label">מספר עובד</label>
          <input type="text" className="form-control" value={employeeNumber} onChange={(e) => {setEmployeeNumber(e.target.value);}}/>
        </div>


      </div>

      <div className="row">
        <div className="col-lg-4">
          <label className="form-label">תעודת זהות <span style={{color:'red'}}>*</span></label>
          <input type="text" className="form-control" value={employeeId} onChange={(e) => {setEmployeeId(e.target.value);}}/>
        </div>

        <div className="col-lg-4">
          <label className="form-label">תפקיד בחברה <span style={{color:'red'}}>*</span></label>
          <input type="text" className="form-control" value={position} onChange={(e) => {setPosition(e.target.value);}}/>
        </div>

        <div className="col-lg-4">
          <label className="form-label">קופת חולים</label>
          <input type="text" className="form-control" value={hospitality} onChange={(e) => {setHospitality(e.target.value);}}/>
        </div>
      </div>


      <div className="row">
        <div className="col-lg-4 position-relative">
            <label className="form-label">מחלקה</label>
            <input
              type="text"
              className="form-control"
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => {
                setTimeout(() => setShowDropdown(false), 100);
              }}
            />
            {showDropdown && (
              <ul className="dropdown-menu show position-absolute w-100" style={{ zIndex: 1000, textAlign:'right' }}>
                {filteredDepartments.map((dept) => (
                  <li
                    key={dept.empId}
                    className="dropdown-item"
                    onClick={() => handleSelectDepartment(dept.departmentName)}>
                    {dept.departmentName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        <div className="col-lg-4">
          <label className="form-label">סניף</label>
          <input type="text" className="form-control" value={branch} onChange={(e) => {setBranch(e.target.value);}}/>
        </div>
        <div className="col-lg-4">
          <label className="form-label">קבלן</label>
          <input type="text" className="form-control" value={constructor} onChange={(e) => {setConstructor(e.target.value);}}/>
        </div>
      </div>




      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">טלפון נייד</label>
          <input
            type="text"
            className="form-control"
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">דואר אלקטרוני</label>
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>

      <label className="form-label">כתובת מגורים</label>
      <input
        type="text"
        className="form-control"
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
        }}
      />

      <div className="col-lg-12" style={{ marginTop: 12 }}>
        <button
          className="btn btn-success btn-lg"
          onClick={addNewEmployee}>הוספת עובד חדש</button>
      </div>
    </>
  );
};

export default AddEmployee;
