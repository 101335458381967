import React, { useState, useEffect, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { FaEdit, FaLockOpen, FaLock } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';

const UserCardBig = (props) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [updateUserView, setUpdateUserView] = useState(false);

  const [email, setEmail] = useState(props.selectedUser.email);
  const [isUserLock, setIsUserLock] = useState(props.selectedUser.isUserLock);
  const [firstName, setFirstName] = useState(props.selectedUser.firstName);
  const [lastName, setLastName] = useState(props.selectedUser.lastName);
  const [role, setRole] = useState(props.selectedUser.role);
  const [phone, setPhone] = useState(props.selectedUser.contact.phone);
  const [address, setAddress] = useState(props.selectedUser.contact.address);
  const [city, setCity] = useState(props.selectedUser.contact.city);
  const [avatar, setAvatar] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setEmail(props.selectedUser?.email || "");
    setFirstName(props.selectedUser?.firstName || "");
    setLastName(props.selectedUser?.lastName || "");
    setRole(props.selectedUser?.role || "");
    setPhone(props.selectedUser?.contact?.phone || "");
    setAddress(props.selectedUser?.contact?.address || "");
    setCity(props.selectedUser?.contact?.city || "");
    setIsUserLock(props.selectedUser?.isUserLock || false);
    setAvatar(null); // Reset avatar to null on user change
  }, [props.selectedUser]);

  const sortedCompanies = [...props.allCompanies].sort((a, b) => a.companyName.localeCompare(b.companyName));

  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    onDelete();
    toast.success("המשתמש הוסר בהצלחה", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const onDelete = async () => {
    props.removeUser(props.selectedUser.id);
  };

  const userRolesWithCompanyInfo = (props.selectedUser.projectsRole || [])
    .map((role) => ({
      ...role,
      company: sortedCompanies.find((company) => company.id === role.role),
    }))
    .filter((roleWithCompany) => roleWithCompany.company) // Filter out any roles without a matching company
    .sort((a, b) => a.company.companyName.localeCompare(b.company.companyName));
    const fileInputRef = useRef(null);
    const triggerFileInput = () => {
      fileInputRef.current.click(); // Programmatically click the hidden file input
    };
  return (
    <div className="card">
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="circle">
          <img src={props.selectedUser.avatar} className="circle-img" alt="..." />
        </div>
      </div>
      <div className="card-body">
        <h5 className="card-title">
          {props.selectedUser.firstName} {props.selectedUser.lastName}{" "}
          {!props.selectedUser.isDisplay && <span style={{ color: "red" }}>משתמש הוסר מהמערכת</span>}
        </h5>

        {updateUserView ? (
          <>
            <div className="row">
              <div className="col-lg-12">
                <label className="form-label">רמת הרשאה</label>
                <select
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  className="form-control"
                >
                  <option value="admin">מנהל ראשי</option>
                  <option value="manager">מנהל מערכת</option>
                  <option value="user">משתמש גישה מוגבלת</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label className="form-label">שם פרטי</label>
                <input
                  type="text"
                  className="form-control"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">שם משפחה</label>
                <input
                  type="text"
                  className="form-control"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label className="form-label">דואר אלקטרוני</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">טלפון</label>
                <input
                  type="text"
                  className="form-control"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label className="form-label">כתובת מלאה</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">עיר מגורים</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label className="form-label">העלאת תמונת פרופיל</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setAvatar(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button
                  onClick={() => {
                    props.updateUser(firstName, lastName, email, phone, role, address, city, props.selectedUser.id, avatar);
                  }}
                  className="btn btn-success"
                >
                  עדכן פרטים
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="card-text">
              <b>כתובת: </b>
              {props.selectedUser.contact.address}
              <br />
              <b>דואר אלקטרוני: </b>
              {props.selectedUser.email}
              <br />
              <b>טלפון נייד: </b>
              {props.selectedUser.contact.phone}
              <br />
              <b>האם המשתמש הקים חשבון: </b>
              {props.selectedUser.uuid ? "כן" : "לא"}
            </p>
          </>
        )}

        {props.selectedUser.isDisplay && (
          <>
            {props.selectedUser.role === "user" && (
              <>
                <div className="role-box">
                  <h6>הרשאה לחברות</h6>
                  <div className="row">
                    <div className="col-lg-10" style={{ marginTop: 12 }}>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setSelectedProject(e.target.value);
                        }}
                      >
                        <option value="">בחר חברה מהרשימה...</option>
                        {sortedCompanies
                          .filter((company) => !props.selectedUser.projectsRole.find((role) => role.role === company.id))
                          .map((proitem) => (
                            <option key={proitem.id} value={proitem.id}>
                              {proitem.companyName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-lg-2">
                      <button
                        style={{ marginTop: 12 }}
                        className="btn btn-success"
                        onClick={() => {
                          props.addProjectRole(props.selectedUser, selectedProject);
                        }}
                      >
                        הוספה
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: 12 }}>
                    {props?.selectedUser?.projectsRole && (
                      <>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">חברה</th>
                              <th scope="col">הסרה</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userRolesWithCompanyInfo.map((pro) => (
                              <tr key={pro.roleId}>
                                <td>{pro.company.companyName}</td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => {
                                      props.removeProjectRole(props.selectedUser, pro.roleId);
                                    }}
                                  >
                                    הסרה
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            <div style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="בלחיצה על כפתור זה המשתמש ימחק לצמיתות והמידע יעלם / הגישה למשתמש זה תינעל עד שתיפתח מחדש"
                className="btn btn-danger"
                onClick={() => {
                  handleDelete(props.selectedUser);
                }}
              >
                <MdDelete size={20} />
              </button>
              {showConfirmation && (
                <div style={{ flexDirection: "row", marginTop: 0 }}>
                  <p>
                    האם למחוק את המשתמש וכל המידע לצמיתות{" "}
                    <button className="btn btn-danger" onClick={handleConfirmDelete}>
                      מחיקה
                    </button>{" "}
                    <button className="btn btn-success" onClick={handleCancelDelete}>
                      ביטול
                    </button>
                  </p>
                </div>
              )}
              <button
                onClick={() => props.lockUnlockUser(!isUserLock, props.selectedUser.id)}
                className="btn btn-warning"
              >
                {isUserLock ? <FaLock size={20} /> : <FaLockOpen size={20} />}
              </button>
              <button onClick={() => setUpdateUserView(!updateUserView)} className="btn btn-success">
                <FaEdit size={20} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserCardBig;
