import React, { useState, useEffect } from 'react';
import useDashboardStore from "../../store/useDashboardStore";
import moment from "moment";
import 'moment/locale/he';
import NotificationItem from './components/NotificationItem';

const Notifications = () => {

  const {
    userPersonalData,
    selectedCompany,
    accounts,
    companies,
    employees,
    findings,
    committees,
    machineReports,
    safetyTests,
    trainingGroups,
    logs
  } = useDashboardStore();

  const [filteredEmployees, setFilteredEmployees] = useState([])
  const [filteredFindings, setFilteredFindings] = useState([])
  const [filteredCommittees, setFilteredCommittees] = useState([])
  const [filteredMachinesReports, setFilteredMachinesReports] = useState([])
  const [filteredSafetyTests, setFilteredSafetyTests] = useState([])
  const [filteredTrainingGroups, setFilteredTrainingGroups] = useState([])


  useEffect(() => {
    const currentDate = new Date();
const thirtyDaysLater = new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000));

const detailedFilesArray = employees
    .filter(emp => emp.associateCompany === selectedCompany.id)  // Filter by company
    .flatMap(emp => {  // Flatten the array of files for each employee into a single array
        return emp.files
            .map(file => {
                const fileExpDate = new Date(file.fileExpDate);
                const timeDiff = fileExpDate - currentDate;
                const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));  // Calculate days left

                return {
                    id: emp.id,
                    firstName: emp.firstName,
                    lastName: emp.lastName,
                    createdAt: emp.createdAt,
                    employeeId: emp.employeeId,
                    fileName: file.fileName,
                    fileExpDate: file.fileExpDate,
                    daysLeft: daysLeft
                };
            })
            .filter(file => file.daysLeft >= 0 && file.daysLeft <= 30);  // Filter files by days left within the next 30 days
    });

    setFilteredEmployees(detailedFilesArray);


    const filteredAndEnhancedFindings = findings
      .filter(group => 
          group.associateCompanyId === selectedCompany.id && 
          group.status !== 'סגור' &&
          new Date(group.targetDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
      )
      .map(group => {
          const trainingNextDate = new Date(group.targetDate);
          const timeDiff = trainingNextDate - currentDate;
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

          return {
              ...group,
              daysLeft
          };
      });
      setFilteredFindings(filteredAndEnhancedFindings);


    const filteredAndEnhancedCommittees = committees
      .filter(group => 
          group.associateCompanyId === selectedCompany.id && 
          new Date(group.committeeNextDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
      )
      .map(group => {
          const trainingNextDate = new Date(group.committeeNextDate);
          const timeDiff = trainingNextDate - currentDate;
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

          return {
              ...group,
              daysLeft
          };
      });
      setFilteredCommittees(filteredAndEnhancedCommittees);


    const filteredAndEnhancedMachineReports = machineReports
      .filter(group => 
          group.associateCompanyId === selectedCompany.id && 
          new Date(group.nextReportDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
      )
      .map(group => {
          const trainingNextDate = new Date(group.nextReportDate);
          const timeDiff = trainingNextDate - currentDate;
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

          return {
              ...group,
              daysLeft
          };
      });
      setFilteredMachinesReports(filteredAndEnhancedMachineReports);


    const filteredAndEnhancedSafetyTests = safetyTests
      .filter(group => 
          group.associateCompanyId === selectedCompany.id && 
          new Date(group.trainingNextDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
      )
      .map(group => {
          const trainingNextDate = new Date(group.trainingNextDate);
          const timeDiff = trainingNextDate - currentDate;
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

          return {
              ...group,
              daysLeft
          };
      });
      setFilteredSafetyTests(filteredAndEnhancedSafetyTests);


    const filteredAndEnhancedTrainingGroups = trainingGroups
        .filter(group => 
            group.associateCompanyId === selectedCompany.id && 
            new Date(group.trainingNextDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
        )
        .map(group => {
            const trainingNextDate = new Date(group.trainingNextDate);
            const timeDiff = trainingNextDate - currentDate;
            const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            return {
                ...group,
                daysLeft
            };
        });
        setFilteredTrainingGroups(filteredAndEnhancedTrainingGroups);
  },[employees,findings,machineReports,safetyTests,committees,trainingGroups,selectedCompany.id])


  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-12">
          <h3>התראות מערכת</h3>
        </div>
      </div>
      <div className="row heading-container-filter">

      </div>
      <div className="row" style={{ padding: 50 }}>

      <h4 className='log-title'>עובדים ({filteredEmployees.length})</h4>
      {
        filteredEmployees.length > 0 ? (<>
        {
          filteredEmployees.map(item => (
            <NotificationItem
            key={item.id} 
            daysLeft={item.daysLeft} 
            title={`${item.firstName} ${item.lastName} - ${item.employeeId}`} 
            currentDate={item.createdAt} 
            nextDate={item.fileExpDate} 
            description={item.fileName}
            />
          ))
        }
        </>) : (<>
          <p>לא קיימות התראות</p>
        </>)
      }
      <h4 className='log-title'>ממצאים ({filteredFindings.length})</h4>
      {
        filteredFindings.length > 0 ? (<>
        {
          filteredFindings.map(item => (
            <NotificationItem
            key={item.id} 
                daysLeft={item.daysLeft} 
                title={`סטטוס: ${item.status} - ליקוי: ${item.itemImpairmentSeverity}`} 
                currentDate={item.createdAt} 
                nextDate={item.targetDate} 
                description={item.itemImpairmentDesc}
            />
          ))
        }
        </>) : (<>
          <p>לא קיימות התראות</p>
        </>)
      }
      <h4 className='log-title'>ועדות בטיחות ({filteredCommittees.length})</h4>
      {
        filteredCommittees.length > 0 ? (<>
        {
          filteredCommittees.map(item => (
            <NotificationItem
            key={item.id} 
            daysLeft={item.daysLeft} 
            title={item.committeeName} 
            currentDate={item.committeeDate} 
            nextDate={item.committeeNextDate} 
            description={item.committeeDesc}
            />
          ))
        }
        </>) : (<>
          <p>לא קיימות התראות</p>
        </>)
      }
      <h4 className='log-title'>מעקבים תקופתיים ({filteredMachinesReports.length})</h4>
      {
        filteredMachinesReports.length > 0 ? (<>
        {
          filteredMachinesReports.map(item => (
            <NotificationItem
              key={item.id} 
              daysLeft={item.daysLeft} 
              title={`${item.checkType} ${item.reportType}`} 
              currentDate={item.reportDate} 
              nextDate={item.nextReportDate} 
              description={item.reportComments}
            />
          ))
        }
        </>) : (<>
          <p>לא קיימות התראות</p>
        </>)
      }
      <h4 className='log-title'>תרגילי בטיחות ({filteredSafetyTests.length})</h4>
      {
        filteredSafetyTests.length > 0 ? (<>
        {
          filteredSafetyTests.map(item => (
            <NotificationItem
              key={item.id} 
              daysLeft={item.daysLeft} 
              title={item.trainingName} 
              currentDate={item.trainingDate} 
              nextDate={item.trainingNextDate} 
              description={item.trainingDesc}
            />
          ))
        }
        </>) : (<>
          <p>לא קיימות התראות</p>
        </>)
      }
      <h4 className='log-title'>הדרכות ({filteredTrainingGroups.length})</h4>
      {
        filteredTrainingGroups.length > 0 ? (<>
        {
          filteredTrainingGroups.map(item => (
            <NotificationItem
              key={item.id}
              daysLeft={item.daysLeft}
              title={item.trainingName}
              currentDate={item.trainingDate}
              nextDate={item.trainingNextDate}
              description={item.trainingDesc}
            />
          ))
        }
        </>) : (<>
          <p>לא קיימות התראות</p>
        </>)
      }

      </div>
    </>
  )
}

export default Notifications