import React, { useState } from "react";

const TrainingItem = (props) => {
  
  const [isEditView, setIsEditView] = useState(false);
  const [title, setTitlte] = useState(props.item.trainingItem);

  return (
    <>
    {
      isEditView ? (<>
      <div className='row safety-item-row'>
        <div className='col-lg-8'>
            <input
              className='form-control'
              value={title}
              onChange={(e) => setTitlte(e.target.value)}
            />
        </div>
        <div className='col-lg-4'>
          <button onClick={() => props.editItem(props.item.id, title)} className='btn btn-success btn-sm'>שמירה</button>{" "}
          <button onClick={() => setIsEditView(!isEditView)} className='btn btn-dark btn-sm'>ביטול</button>
        </div>
    </div>
      </>) : (<>
      <div className="row safety-item-row">
      <div className="col-lg-8">
        <p>{props.item.trainingItem}</p>
      </div>
      <div className='col-lg-4'>
          <button onClick={() => props.deleteItem(props.item.id)} className='btn btn-danger btn-sm'>הסרה</button>{" "}
          <button onClick={() => setIsEditView(!isEditView)} className='btn btn-success btn-sm'>עריכה</button>
        </div>
    </div>
      </>)
    }
    </>
  );
};

export default TrainingItem;
