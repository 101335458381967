import React, {useEffect,useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { database } from "../../services/firebase-config";
import {collection,doc,updateDoc,writeBatch, getDocs, where, query, getDoc} from "firebase/firestore";
import {requestDataByAssociate,getNumberOfProjects,getNumberOfMachines} from '../../services/dbHelper';
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import useDashboardStore from "../../store/useDashboardStore";

const AccountInfo = () => {
    const { associateId } = useParams(); 
    const { plans,userPersonalData } = useDashboardStore();
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [committees, setCommittees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [findings, setFindings] = useState([]);
    const [machinesReports, setMachineReports] = useState([]);
    const [safetyReports, setSafetyReports] = useState([]);
    const [safetyTests, setSafetyTests] = useState([]);
    const [trainings, setTrainings] = useState([]);
    const [projects, setProjects] = useState(0);
    const [machines, setMachines] = useState(0);
    const [mainAccount, setMainAccount] = useState(null);
    const [daysLeftToTrail, setDaysLeftToTrail] = useState('');
    const [exdate, setExdate] = useState('');
    const [plan, setPlan] = useState(plans.find(x => x.id === mainAccount?.planId));

    const [selectedPlan, setSelectedPlan] = useState(plan?.id);
    const loadAccountData = async() => {
        const accountsReq = await requestDataByAssociate(associateId,'accounts');
        setAccounts(accountsReq);

        const committeesReq = await requestDataByAssociate(associateId,'committees');
        setCommittees(committeesReq);

        const companiesReq = await requestDataByAssociate(associateId,'companies');
        setCompanies(companiesReq);

        const employeesReq = await requestDataByAssociate(associateId,'employees');
        setEmployees(employeesReq);

        const findingsReq = await requestDataByAssociate(associateId,'findingList');
        setFindings(findingsReq);

        const machinesReportsReq = await requestDataByAssociate(associateId,'machines_reports');
        setMachineReports(machinesReportsReq);

        const safetyReportsReq = await requestDataByAssociate(associateId,'safetyReports');
        setSafetyReports(safetyReportsReq);

        const safetyTestsReq = await requestDataByAssociate(associateId,'safetyTests');
        setSafetyTests(safetyTestsReq);

        const trainingsReq = await requestDataByAssociate(associateId,'trainingGroups');
        setTrainings(trainingsReq);

        const numberOfProjects = await getNumberOfProjects(associateId,'companies');
        setProjects(numberOfProjects)

        const numberOfMachines = await getNumberOfMachines(associateId,'companies');
        setMachines(numberOfMachines)

        const main = accountsReq.find(x => x.uuid === associateId);

        setMainAccount(main);

        let daysLeftval = 0;
        if (main.license === 'Trail') {
            const startDate = moment(main.createdAt);
            const end = moment();
            const duration = moment.duration(end.diff(startDate));
            const daysLeft = 30 - duration.asDays();
            daysLeftval = Math.max(0, Math.round(daysLeft));
        }   
        setDaysLeftToTrail(daysLeftval);
    }


    const [isUserLocked, setIsUserLocked] = useState(false);

    const fetchAccount = async () => {
        const accountsRef = collection(database, "accounts");
        const q = query(accountsRef, where("uuid", "==", associateId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const accountData = querySnapshot.docs[0].data(); // Assuming there's only one match
            setMainAccount(accountData);
            setIsUserLocked(accountData.isUserLock);
        } else {
            toast.error("Account not found with the given ID.");
        }
    };
    
    useEffect(() => {
        fetchAccount();
    }, [associateId]);




    useEffect(() => {
        loadAccountData();
    },[])

    let daysColor = '#000000';
    if (daysLeftToTrail < 5) {
        daysColor = '#EB6079'
    }


    const updateLicenseToPaid = async() => {

        try {
            const accountRef = doc(database, "accounts", mainAccount.id);
            await updateDoc(accountRef, {
                planId: selectedPlan
            });
            useDashboardStore.getState().updateAccount(mainAccount.id, { planId: selectedPlan });
            setMainAccount(prevState => ({
                ...prevState,
                planId: selectedPlan
            }));
            toast.success("מסלול עודכן בהצלחה")
        } catch (error) {
            toast.error(error.message)
        }
    }


    const addExtraDate = async (amount) => {
        try {
          const accountRef = doc(database, "accounts", mainAccount.id);
          const accountSnap = await getDoc(accountRef);
      
          console.log("MAIN ID: " + mainAccount.id);
          if (accountSnap.exists()) {
            // Get the current expire date and calculate the new expire date
            const currentExpireDate = accountSnap.data().expireDate;
            const newExpireDate = currentExpireDate + (amount * 86400000); // 86400000 ms per day
      
            console.log("NEW DATE: " + newExpireDate);
            // Update the document
            await updateDoc(accountRef, { expireDate: newExpireDate });
            useDashboardStore.getState().updateAccount(mainAccount.id, { expireDate: newExpireDate });
      
            // Update local state if needed
            setMainAccount(prevState => ({
              ...prevState,
              expireDate: newExpireDate
            }));
            
            toast.success("מסלול עודכן בהצלחה");
          } else {
            throw new Error("Account does not exist.");
          }
        } catch (error) {
          toast.error(error.message);
        }
      };
      
      const lockAdmin = async () => {
        const newLockStatus = !isUserLocked;
        const accountRef = doc(database, "accounts", mainAccount.id);

        try {
            await updateDoc(accountRef, { isUserLock: newLockStatus });
            useDashboardStore.getState().updateAccount(mainAccount.id, { isUserLock: newLockStatus });
            setIsUserLocked(newLockStatus);
            toast.success(`משתמש ${newLockStatus ? 'ננעל' : 'נפתח'}`);
        } catch (error) {
            toast.error("שגיאה בעדכון נעילת משתמש");
        }
    };

    const removeAdmin = async() => {

        if (!window.confirm("לתשומת ליבך! הסרת חשבון אדמין מהמערכת תגרום להסרת כל המידע המשוייך למשתמש לרבות: חשבונות משתמשים, מעקבים, ממצאים, חברות ועובדים, אביזרים, דוחות בטיחות, תרגילי בטיחות ומבדקים.")) {
            return;
        }

        const batch = writeBatch(database);
        const collections = [
            'accounts', 'assetsCategories', 'committees', 'companies', 'companiesOwner',
            'employees', 'findingList', 'logs', 'machines_reports', 'periodicTracking',
            'safetyReports', 'safetyReportsAreas', 'safetyReportsItems', 'safetyTestingCategories',
            'safetyTests', 'trainingGroups', 'trainingItems'
        ];
    
        try {
            for (let collectionName of collections) {
                const q = query(collection(database, collectionName), where("associateId", "==", mainAccount.uuid));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                });
            }
            // Commit the batch
            await batch.commit();

            useDashboardStore.setState((state) => ({
                accounts: state.accounts.filter(account => account.uuid !== mainAccount.uuid),
                logs: state.logs.filter(log => log.associateId !== mainAccount.uuid),
                committees: state.committees.filter(committee => committee.associateId !== mainAccount.uuid),
                companies: state.companies.filter(company => company.associateId !== mainAccount.uuid),
                employees: state.employees.filter(employee => employee.associateId !== mainAccount.uuid),
                findings: state.findings.filter(finding => finding.associateId !== mainAccount.uuid),
                machineReports: state.machineReports.filter(report => report.associateId !== mainAccount.uuid),
                periodics: state.periodics.filter(periodic => periodic.associateId !== mainAccount.uuid),
                safetyReports: state.safetyReports.filter(report => report.associateId !== mainAccount.uuid),
                safetyReportsAreas: state.safetyReportsAreas.filter(area => area.associateId !== mainAccount.uuid),
                safetyReportsItems: state.safetyReportsItems.filter(item => item.associateId !== mainAccount.uuid),
                safetyCategories: state.safetyCategories.filter(category => category.associateId !== mainAccount.uuid),
                safetyTests: state.safetyTests.filter(test => test.associateId !== mainAccount.uuid),
                trainingGroups: state.trainingGroups.filter(group => group.associateId !== mainAccount.uuid),
                trainingItems: state.trainingItems.filter(item => item.associateId !== mainAccount.uuid)
            }));
            navigate('/superadmin');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const planet = plans.find(x => x.id === mainAccount?.planId);



    const updateExpireDateByPicker = async (newDate) => {
        try {
            const accountRef = doc(database, "accounts", mainAccount.id);
            const newExpireDate = new Date(newDate).getTime(); // Convert the date string to a timestamp
    
            // Update the document
            await updateDoc(accountRef, { expireDate: newExpireDate });
            useDashboardStore.getState().updateAccount(mainAccount.id, { expireDate: newExpireDate });
    
            // Update local state if needed
            setMainAccount(prevState => ({
                ...prevState,
                expireDate: newExpireDate
            }));
    
            toast.success("תאריך התפוגה עודכן בהצלחה");
        } catch (error) {
            toast.error(error.message);
        }
    };


    return(
        <>
        <ToastContainer />
            <div className="row heading-container">
                <div className="col-lg-10">
                    <h3>סופר אדמין</h3>
                </div>
                    <div className="col-lg-2 heading-btn-container">
                </div>
            </div>

            <div className='row' style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>משתמשים</p>
                        <p className='db-box-number222'>{planet?.users} / {accounts.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>וועדות בטיחות</p>
                        <p className='db-box-number222'>{committees.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>חברות</p>
                        <p className='db-box-number222'>{planet?.companies} / {companies.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>עובדי חברות</p>
                        <p className='db-box-number222'>{planet?.employees} / {employees.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>ממצאים</p>
                        <p className='db-box-number222'>{findings.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>תזכיר אביזרים</p>
                        <p className='db-box-number222'>{machinesReports.length}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>


            <div className='row' style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>דוחות בטיחות</p>
                        <p className='db-box-number222'>{safetyReports.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>מבדקי בטיחות</p>
                        <p className='db-box-number222'>{safetyTests.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>הדרכות</p>
                        <p className='db-box-number222'>{trainings.length}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>אתרים</p>
                        <p className='db-box-number222'>{planet?.sites} / {projects}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>אביזרים</p>
                        <p className='db-box-number222'>{machines}</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className="card">
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                        <p className='db-box-title'>רישיון</p>
                        <p className='db-box-number222'>
                            {planet?.planName}
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>



            <div className='row' style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
                <div className='col-lg-6'>
                    <div className="card">
                    <div className="card-body">

                            
                            
                            {
                                mainAccount && (<>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <p className='db-box-title'>חשבון מנהל ראשי</p><br/>
                                        <b>שם מלא: </b> {mainAccount.firstName} {mainAccount.lastName}<br/>
                                        <b>תאריך הקמת החשבון: </b> {moment(mainAccount.createdAt).format('DD/MM/YYYY')}<br/>
                                        <b>שם החברה: </b> {mainAccount.companyName}<br/>
                                        <b>דואר אלקטרוני: </b> {mainAccount.email}<br/>
                                        <b>טלפון: </b> {mainAccount.contact.phone}<br/>
                                        <b>הרשאה: </b> {mainAccount.role}<br/>
                                        <b>חשבון מוצג: </b> {mainAccount.isDisplay ? 'כן' : 'לא'}<br/>
                                        <b>חשבון נעול: </b> {mainAccount.isUserLock ? 'כן' : 'לא'}
                                    </div>
                                </div>
                               
                               <div className="row" style={{marginTop:12}}>
                                    <div className="col-lg-4">
                                    <button onClick={lockAdmin} className={`btn ${isUserLocked ? 'btn-success' : 'btn-danger'} btn-lg`}>
                                        {isUserLocked ? 'שחרור משתמש' : 'נעילת משתמש'}
                                    </button>
                                    </div>
                                    <div className="col-lg-6">
                                        <button onClick={removeAdmin} className="btn btn-dark btn-lg">הסרת אדמין</button>
                                    </div>
                                </div>

                                </>)
                            }

                    </div>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className="card">
                    <div className="card-body">

                            <div className="row">
                                <div className="col-lg-12">
                                <p className='db-box-title'>
                                <b>סוג רישיון: </b> {planet?.planName}<br/>
                                <b>תאריך הקמת החשבון: </b> {mainAccount && (<>{moment(mainAccount.createdAt).format('DD/MM/YYYY')}</>)}<br/>
                                <b>בתוקף עד: </b> {moment(mainAccount?.expireDate).format('DD/MM/YYYY')}
                            </p>
                                </div>
                            </div>
                            

                            <div className="row">
                            <div className="col-lg-8">
                            {
                                plans.length > 0 && (<>

                                    <label style={{marginTop:12}} className="form-label">מסלול לבחירה</label>
                                    <select style={{marginBottom:12}} className="form-control" value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                                        <option value="">בחר קטגוריה מהרשימה...</option>
                                        {
                                            plans.map((plan) => (
                                                <option selected={plan?.id === mainAccount?.planId ? true : false} value={plan.id}>{plan.planName}</option>
                                            ))
                                        }
                                    </select>
                                </>)
                            }
                            </div>
                            <div className="col-lg-4">
                                <button style={{marginTop:44}} onClick={updateLicenseToPaid} className="btn btn-success">עדכון מסלול</button>
                            </div>
                            </div>

                            <div className="row" style={{marginTop:12}}>
                                <div className="col-lg-12">
                                    <label style={{marginTop:12}} className="form-label">הארכת מסלול</label>
                                </div>
                                <div className="col-lg-3">
                                    <button onClick={() => {addExtraDate(30)}} className="btn btn-info">30 ימים</button>
                                </div>
                                <div className="col-lg-3">
                                    <button onClick={() => {addExtraDate(90)}}className="btn btn-warning">90 ימים</button>
                                </div>
                                <div className="col-lg-3">
                                <input
                                    type="date"
                                    className="form-control"
                                    value={moment(exdate).format("YYYY-MM-DD")} // Assumes `exdate` is the state holding the current expiry date
                                    onChange={(e) => {
                                        updateExpireDateByPicker(e.target.value);
                                    }}
                                />
                                </div>
                                <div className="col-lg-3">
                                    <button onClick={() => {addExtraDate(1000)}}className="btn btn-dark">ללא הגבלה</button>
                                </div>
                            </div>


                            

                            <br/><br/>
                            <p className='db-box-title'>תשלומים</p>

                    </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default AccountInfo;