import React, { useState,useEffect } from "react";

const MachineRow = (props) => {
  const [machineManifacture, setMachineManifacture] = useState(props.machine.machineManifacture);
  const [department, setDepartment] = useState(props.machine.department);
  const [machineYear, setMachineYear] = useState(props.machine.machineYear);
  const [machineSerialNumber, setMachineSerialNumber] = useState(props.machine.machineSerialNumber);
  const [machineModel, setMachineModel] = useState(props.machine.machineModel);
  const [machineType, setMachineType] = useState(props.machine.machineType);
  const [machineComments, setMachineComments] = useState(props.machine.machineComments);
  const [machineLicenseNumber, setMachineLicenseNumber] = useState(props.machine.machineLicenseNumber);
  const [isEditView, setIsEditView] = useState(false);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (department) {
      const filtered = props.departments.filter((dept) =>
        dept.departmentName.toLowerCase().includes(department.toLowerCase())
      );
      setFilteredDepartments(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setFilteredDepartments([]);
      setShowDropdown(false);
    }
  }, [department, props.departments]);

  const handleSelectDepartment = (deptName) => {
    setDepartment(deptName);
    setShowDropdown(false);
  };

  return (
    <>
      {isEditView ? (
        <>
          <tr>
            <td colSpan="8">
              <div className="row">
              <div className="col-lg-3 position-relative">
            <label className="form-label">מחלקה</label>
            <input
              type="text"
              className="form-control"
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => {
                setTimeout(() => setShowDropdown(false), 100);
              }}
            />
            {showDropdown && (
              <ul className="dropdown-menu show position-absolute w-100" style={{ zIndex: 1000, textAlign:'right' }}>
                {filteredDepartments.map((dept) => (
                  <li
                    key={dept.empId}
                    className="dropdown-item"
                    onClick={() => handleSelectDepartment(dept.departmentName)}
                  >
                    {dept.departmentName}
                  </li>
                ))}
              </ul>
            )}
          </div>



                <div className="col-lg-3">
                  <label className="form-label">סוג אביזר</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setMachineType(e.target.value);
                    }}
                  >
                    <option value={machineType}>נא לבחור מהרשימה</option>
                    {props?.periodicList?.map((item) => (
                      <option
                        selected={
                          item.trackingName === machineType ? true : false
                        }
                        value={item.trackingName}
                        key={item.id}
                      >
                        {item.trackingName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-3">
                  <label className="form-label">יצרן</label>
                  <input
                    type="text"
                    className="form-control"
                    value={machineManifacture}
                    onChange={(e) => {
                      setMachineManifacture(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="form-label">דגם / מודל</label>
                  <input
                    type="text"
                    className="form-control"
                    value={machineModel}
                    onChange={(e) => {
                      setMachineModel(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="form-label">שנת ייצור</label>
                  <input
                    type="text"
                    className="form-control"
                    value={machineYear}
                    onChange={(e) => {
                      setMachineYear(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="form-label">מספר סידורי</label>
                  <input
                    type="text"
                    className="form-control"
                    value={machineSerialNumber}
                    onChange={(e) => {
                      setMachineSerialNumber(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="form-label">מספר רישוי</label>
                  <input
                    type="text"
                    className="form-control"
                    value={machineLicenseNumber}
                    onChange={(e) => {
                      setMachineLicenseNumber(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="form-label">תיאור האביזר</label>
                  <input
                    type="text"
                    className="form-control"
                    value={machineComments}
                    onChange={(e) => {setMachineComments(e.target.value);}}/>
                </div>


                <div className="col-lg-3">

                  <button onClick={() => setIsEditView(!isEditView)} className="btn btn-dark btn-sm">ביטול</button>

                  <button
                    onClick={() => {
                      props.updateMachine(
                        machineComments,
                        machineLicenseNumber,
                        machineManifacture,
                        machineModel,
                        machineSerialNumber,
                        machineType,
                        machineYear,
                        props.machine.empId,
                        department
                      );
                      setIsEditView(false);
                    }}
                    className='btn btn-success btn-sm'
                    style={{ marginRight: 6 }}>שמירה</button>
                </div>
              </div>
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <td><p><b>מחלקה:</b> {props.machine.department}<br /><b>סוג אביזר:</b> {props.machine.machineType}</p></td>
            <td>{props.machine.machineManifacture}</td>
            <td>{props.machine.machineModel}</td>
            <td>{props.machine.machineLicenseNumber}</td>
            <td>{props.machine.machineSerialNumber}</td>
            <td>{props.machine.machineYear}</td>
            <td>{props.machine.machineComments}</td>
            <td>
                <button onClick={() => props.removeMachine(props.machine)} className='btn btn-danger btn-sm'>הסרה</button>{" "}
                <button onClick={() => setIsEditView(!isEditView)} className='btn btn-success btn-sm'>עריכה</button>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default MachineRow;
