import React from "react";
import moment from "moment";

const FindingItemSmall = (props) => {

    const findingType = props.safetyReportsAreas.find((x) => x.id === props.finding.itemImpairmentArea);

    return(
        <div className="row findrow">
            <div className="col-lg-2">
                {props.finding.gallery.length > 0 ? (
                <>
                <div style={{width: "100%",height: 80,overflow: "hidden",display: "flex",justifyContent: "center",alignItems: "center"}}>
                    <img className="finding-row-image" src={props.finding.gallery[0].imageSource} alt={props.finding.gallery[0].imgId}/>
                </div>
                </>
            ) : (<></>)}
            </div>
            <div className="col-lg-4"><p>תיאור</p><h3>{findingType.safetyReportAreaItem}</h3></div>
            <div className="col-lg-2"><p>סטטוס</p><h3>{props.finding.status}</h3></div>
            <div className="col-lg-2"><p>חומרת הליקוי</p><h3>{props.finding.itemImpairmentSeverity}</h3></div>
            <div className="col-lg-2"><p>תאריך הקמה</p><h3>{moment(props.finding.createdAt).format('DD/MM/YYYY')}</h3></div>
            </div>
    )
}

export default FindingItemSmall;