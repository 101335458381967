import React, { useState } from "react";

const DepartmentRow = (props) => {

  const [departmentName, setDepartmentName] = useState(props.depart.departmentName);
  const [isEditView, setIsEditView] = useState(false);

  return (
    <>
      {isEditView ? (
        <>
          <tr>
            <td colSpan="8">
              <div className="row">

                <div className="col-lg-9">
                  <label className="form-label">מחלקה</label>
                  <input
                    type="text"
                    className="form-control"
                    value={departmentName}
                    onChange={(e) => {
                        setDepartmentName(e.target.value);
                    }}
                  />
                </div>


                <div className="col-lg-3">

                  <button onClick={() => setIsEditView(!isEditView)} className="btn btn-dark btn-sm">ביטול</button>

                  <button
                    onClick={() => {
                      props.updateDepartment(
                        props.depart.empId,
                        departmentName
                      );
                      setIsEditView(false);
                    }}
                    className='btn btn-success btn-sm'
                    style={{ marginRight: 6 }}>שמירה</button>
                </div>
              </div>
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <td>{props.depart.empId}</td>
            <td>{props.depart.departmentName}</td>
            <td>
                <button onClick={() => props.removeDepartment(props.depart)} className='btn btn-danger btn-sm'>הסרה</button>{" "}
                <button onClick={() => setIsEditView(!isEditView)} className='btn btn-success btn-sm'>עריכה</button>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default DepartmentRow;
