import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { database } from "../../services/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import CompanyRow from "./AccountRow";
import useDashboardStore from "../../store/useDashboardStore";


const SuperAdmin = (props) => {
    const navigate = useNavigate();
    const { plans,userPersonalData } = useDashboardStore();
    const [allAccounts, setAllAccounts] = useState([]);
    const [allOwnerCompanies, setAllOwnerCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [allAccountReg, setAllAccountReg] = useState([]);
    const [numberOfAccountInTrail, setNumberOfAccountInTrail] = useState(0);
    const [numberOfAccountInPaid, setNumberOfAccountInPaid] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const loadAdminData = async () => {
        setIsLoading(true);
        try {
            const dataRef = collection(database, 'accounts');
            const querySnapshot = await getDocs(dataRef);
            const accountList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const admins = accountList.filter(account => account.associateId === account.uuid);
            const adminsWithEmployees = admins.map(admin => {
                const employees = accountList.filter(emp => emp.associateId === admin.associateId && emp.uuid !== admin.uuid);
                return { ...admin, employees: employees };
            });

            const ownerCompanies = collection(database, 'companiesOwner');
            const ownerCompaniesSnapShot = await getDocs(ownerCompanies);
            const ownerCompaniesList = ownerCompaniesSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const employees = collection(database, 'employees');
            const employeesSnapShot = await getDocs(employees);
            const employeesList = employeesSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const companies = collection(database, 'companies');
            const companiesSnapShot = await getDocs(companies);
            const companiesList = companiesSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const trails = adminsWithEmployees.filter(x => x.license === 'Trail' || x.license === 'Free').length;
            const paids = adminsWithEmployees.filter(x => x.license === 'Paid').length;

            setNumberOfAccountInPaid(paids);
            setNumberOfAccountInTrail(trails);
            setAllAccounts(adminsWithEmployees);
            setAllOwnerCompanies(ownerCompaniesList);
            setAllEmployees(employeesList);
            setAllCompanies(companiesList);
            setAllAccountReg(accountList);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        loadAdminData();
    }, []);

    const filteredAccounts = searchQuery ? allAccounts.filter(
        account => account.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    ) : allAccounts;


    return (
        <>
            <ToastContainer />
            <div className="row heading-container">
                <div className="col-lg-9">
                    <h3>ניהול ארגונים במערכת</h3>
                </div>
                <div className="col-lg-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="חיפוש לפי שם חברה או דואל"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className='row' style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 20 }}>
                {/* Your cards code */}
            </div>

            <div className="row" style={{ padding: 50 }}>
                {
                    isLoading ? (
                        <div className="spinner-border text-info" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        allAccounts.length > 0 && (
                            <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">תאריך</th>
                                    <th scope="col">שם מנהל החשבון</th>
                                    <th scope="col">דואר אלקטרוני</th>
                                    <th scope="col">משתמשים</th>
                                    <th scope="col">חברות</th>
                                    <th scope="col">אתרים</th>
                                    <th scope="col">עובדים</th>
                                    <th scope="col">רישיון</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                    {filteredAccounts.map(account => (
                                        <CompanyRow
                                            plans={plans}
                                            key={account.id}
                                            account={account}
                                            allCompanies={allCompanies}
                                            allEmployees={allEmployees}
                                            allOwnerCompanies={allOwnerCompanies}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )
                    )
                }
            </div>
        </>
    );
};

export default SuperAdmin;
