import React, { useState, useEffect, useRef } from "react";

const EditCompany = (props) => {

  const [companyName, setCompanyName] = useState(props.company.companyName);
  const [companyLogo, setCompanyLogo] = useState(props.company.companyLogo);
  const [companyAddress, setCompanyAddress] = useState(props.company.companyAddress);
  const [companyPhone, setCompanyPhone] = useState(props.company.companyPhone);
  const [companyEmail, setCompanyEmail] = useState(props.company.companyEmail);
  const [companyTaxId, setCompanyTaxId] = useState(props.company.companyTaxId);
  const [companyCity, setCompanyCity] = useState(props.company.companyCity);
  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  return (
    <>
      <label className="form-label">שם החברה</label>
      <input
        type="text"
        className="form-control"
        value={companyName}
        onChange={(e) => {
          setCompanyName(e.target.value);
        }}
      />

      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">דואר אלקטרוני</label>
          <input
            type="text"
            className="form-control"
            value={companyEmail}
            onChange={(e) => {
              setCompanyEmail(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">העלאת לוגו חברה</label>
          <input
            type="file"
            className="form-control"
            onChange={(e) => {
              setCompanyLogo(e.target.files[0]);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">כתובת מלאה</label>
          <input
            type="text"
            className="form-control"
            value={companyAddress}
            onChange={(e) => {
              setCompanyAddress(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">עיר</label>
          <input
            type="text"
            className="form-control"
            value={companyCity}
            onChange={(e) => {
              setCompanyCity(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">מספר ע.מ / ח״פ</label>
          <input
            type="text"
            className="form-control"
            value={companyTaxId}
            onChange={(e) => {
              setCompanyTaxId(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">טלפון</label>
          <input
            type="text"
            className="form-control"
            value={companyPhone}
            onChange={(e) => {
              setCompanyPhone(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-12" style={{marginTop:12}}>
            <button className="btn btn-success btn-lg" onClick={() => {
                props.updateCompany(
                    companyName,
                    companyAddress,
                    companyCity,
                    companyEmail,
                    companyLogo,
                    companyPhone,
                    companyTaxId
                    )}}>עדכון פרטי החברה</button>
        </div>
      </div>
    </>
  );
};

export default EditCompany;
