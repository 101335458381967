import { database } from './firebase-config';
import useDashboardStore from '../store/useDashboardStore';
import {addDoc,collection} from 'firebase/firestore'
/**
 * Save log to Firestore and update local Zustand store.
 * @param {Object} logData - Data to be logged.
 */
export const saveLog = async (logData) => {
    
  const logEntry = {
    ...logData,
    createdAt: Date.now(),
  };

  try {

    await addDoc(collection(database, "logs"), logEntry).then((docRef) => {
        console.log('Log entry added with ID:', docRef.id);
        useDashboardStore.getState().addLog({
            ...logEntry,
            id: docRef.id,
          });
    });
    
  } catch (error) {
    console.error('Error adding log entry:', error);
  }
};
