import moment from "moment";
import React, { useState, useEffect } from "react";
import { BsFillFilePdfFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";

import { FaRegCheckCircle } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CommitteeCardExp = (props) => {
  const [comitEdidView, setComitEditView] = useState(false);
  const [committeeName, setCommitteeName] = useState(props.comit.committeeName);
  const [committeeDesc, setCommitteeDesc] = useState(props.comit.committeeDesc);
  const [committeeDate, setCommitteeDate] = useState(props.comit.committeeDate);
  const [committeeNexDateVal, setCommitteeNextDateVal] = useState(props.comit.committeeNextDate);
  const [committeeFile, setCommitteeFile] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(props.comit.employees);
  const [selectedOption, setSelectedOption] = useState("");
  const [employeesArr, setEmployeesArr] = useState(props.employeesArr);
  const [isReportSent, setIsReportSent] = useState(props.comit.isReportSent);

  useEffect(() => {
    setEmployeesArr(
      props.employeesArr.filter(
        (e) => !selectedEmployees.some((se) => se.id === e.id)
      )
    );
  }, [selectedEmployees, props.employeesArr]);

  const handleRemove = (emp) => {
    setSelectedEmployees(selectedEmployees.filter((v) => v.id !== emp.id));
    setEmployeesArr([...employeesArr, emp]); // Add back to the dropdown options
  };

  const handleSelect = (empId) => {
    const emp = props.employeesArr.find((x) => x.id === empId); // Use the original prop to find the employee
    if (emp && !selectedEmployees.some((e) => e.id === emp.id)) {
      setSelectedEmployees([...selectedEmployees, emp]);
    }
  };

  return (
    <>

          <div className="row rowshadowboxExp">


            <div className="col-lg-5">
              <h6>{props.comit.committeeName}</h6>
              <p>{props.comit.committeeDesc}</p>
              <p>
                <b>השתתפו בועדה: </b>
                {props.comit.employees.map((emp) => (
                  <>
                    {emp.firstName} {emp.lastName},{" "}
                  </>
                ))}
              </p>
            </div>

            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p>משרד העבודה</p>
              {
                props.comit.isReportSent ? (<FaRegCheckCircle size={34} color="#6fcb9f" />) : (<AiOutlineCloseCircle size={34} color="#EB6079" />)
              }
            </div>


            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>תאריך הועדה</p>
              <h4>{moment(props.comit.committeeDate).format("DD/MM/YYYY")}</h4>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>הועדה הבאה</p>
              <h4>{moment(props.comit.committeeNextDate).format("DD/MM/YYYY")}</h4>
            </div>


            <div className="col-lg-1" style={{ textAlign: "center" }}>

            <p>קבצים ({props.comit.committeeFiles.length})</p>
              {
                props.comit.committeeFiles.length > 0 && (<>
                {
                  props.comit.committeeFiles.map((file) => (
                  <a href={file} target="_blank" rel="noreferrer"><BsFillFilePdfFill color="#EB6079" size={30} /></a>))
                }
                </>)
              }

            </div>
          </div>
    </>
  );
};

export default CommitteeCardExp;
