import React from "react";
import {
  FcInTransit,
  FcOrganization,
  FcBusinessman,
  FcFile,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import {
  ImUsers,
  ImBriefcase,
  ImFlag,
  ImRocket,
  ImTruck,
  ImTrophy,
} from "react-icons/im";

const Boxes = (props) => {
  const sitesInfo = "sites";
  let linkSites = "/dashboard";
  if (props.selectedCompany) {
    linkSites = `/company-edit/${props.selectedCompany.id}/${sitesInfo}`;
  }

  return (
    <>
      <div className="row mtop">
        <div className="col-lg-2">
          <div className="card employees">
            <Link to={linkSites} style={{ textDecoration: "none", color: "#000000" }}>
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>עובדים בחברה</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyEmployees.length}</p></div>
                    <div className="col-lg-5"><ImUsers size={60} color="#acd4da" /></div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card committees">
            <Link to={linkSites} style={{ textDecoration: "none", color: "#000000" }}>
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>ועדות בטיחות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyCommittees.length}</p></div>
                    <div className="col-lg-5"><ImBriefcase size={60} color="#bfa9ec" /></div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card findings">
            {props.selectedSite ? (
              <Link to="/finding-list" style={{ textDecoration: "none", color: "#000000" }}>
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>ממצאים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyFindings.length}</p></div>
                    <div className="col-lg-5"><ImFlag size={60} color="#f1e1d5" /></div>
                </div>
                </div>
              </Link>
            ) : (
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>ממצאים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyFindings.length}</p></div>
                    <div className="col-lg-5"><ImFlag size={60} color="#f1e1d5" /></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card machines">
            {props.selectedSite ? (
              <Link to="/machines" style={{ textDecoration: "none", color: "#000000" }}>
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>מעקבים תקופתיים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyMachinesReports.length}</p></div>
                    <div className="col-lg-5"><ImTruck size={60} color="#ecccdc" /></div>
                </div>
                </div>
              </Link>
            ) : (
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>מעקבים תקופתיים</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyMachinesReports.length}</p></div>
                    <div className="col-lg-5"><ImTruck size={60} color="#ecccdc" /></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card reports">
            {props.selectedSite ? (
              <Link to="/safety-reports" style={{ textDecoration: "none", color: "#000000" }}>
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>דוחות בטיחות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companySafetyReports.length}</p></div>
                    <div className="col-lg-5"><ImRocket size={60} color="#acd4da" /></div>
                </div>
                </div>
              </Link>
            ) : (
              <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>דוחות בטיחות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companySafetyReports.length}</p></div>
                    <div className="col-lg-5"><ImRocket size={60} color="#acd4da" /></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card trainings">
            {
              props.selectedSite ? (
                
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>הדרכות / תרגילים</p></div>
                    <div className="col-lg-7">
                    <p className="db-box-number222" style={{ color: "#fff" }}>
                    <Link to="/trainings-groups" style={{ textDecoration: "none", color: "#ffffff" }}>{props.companyTrainings.length}</Link> / 
                    <Link to="/trainings-items" style={{ textDecoration: "none", color: "#ffffff" }}>{props.companySafetyTests.length}</Link></p>
                    </div>
                    <div className="col-lg-5"><ImTrophy size={60} color="#c0f3d7" /></div>
                </div>
                </div>
              ) : (
                <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="row">
                    <div className="col-lg-12"><p className="db-box-title" style={{ color: "#fff" }}>הדרכות</p></div>
                    <div className="col-lg-7"><p className="db-box-number222" style={{ color: "#fff" }}>{props.companyTrainings.length}</p></div>
                    <div className="col-lg-5"><ImTrophy size={60} color="#c0f3d7" /></div>
                </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default Boxes;
