import { create } from "zustand";
import { persist } from 'zustand/middleware';

const useDashboardStore = create(persist((set) => ({

  isLoading: false,
  loadingMessage: "",
  selectedCompany: null,
  selectedSite: null,
  userPersonalData: {},
  associateAccounts: [],
  accounts: [],
  plans:[],
  committees: [],
  companies: [],
  employees: [],
  findings: [],
  machineReports: [],
  periodics: [],
  safetyReports: [],
  safetyReportsAreas: [],
  safetyReportsItems: [],
  safetyCategories: [],
  safetyTests: [],
  trainingGroups: [],
  trainingItems: [],
  logs: [],
  popups: [],
  error: null,
  myCompany: {},
  mainAccount: {},
  myPlan: {},


  theSelectedPlan: null,
  setTheSelectedPlan: (plan) => {
    console.log('Setting selected plan:', plan);
    set({ theSelectedPlan: plan });
  },
  clearSelectedPlan: () => {
      console.log('Clearing selected plan');
      set({ theSelectedPlan: null });
  },
  
  addCommittee: (newCommittee) =>
    set((state) => ({
      committees: [...state.committees, newCommittee],
    })),

  addTrainingGroup: (newGroup) =>
    set((state) => ({
      trainingGroups: [...state.trainingGroups, newGroup],
    })),

  updateTrainingGroup: (id, updatedData) =>
    set((state) => ({
      trainingGroups: state.trainingGroups.map((group) =>
        group.id === id ? { ...group, ...updatedData } : group
      ),
    })),

  removeTrainingGroupById: (id) =>
    set((state) => ({
      trainingGroups: state.trainingGroups.filter((group) => group.id !== id),
    })),

  addMachineReport: (newReport) =>
    set((state) => ({
      machineReports: [...state.machineReports, newReport],
    })),

  addManagerNewPopup: (popup) =>
    set((state) => ({
      popups: [...state.popups, popup],
    })),

    addManagerNewPlan: (plan) =>
      set((state) => ({
        plans: [...state.plans, plan],
      })),

  updateMachineReport: (id, updatedData) =>
    set((state) => ({
      machineReports: state.machineReports.map((report) =>
        report.id === id ? { ...report, ...updatedData } : report
      ),
    })),

  removeMachineReportById: (id) =>
    set((state) => ({
      machineReports: state.machineReports.filter((report) => report.id !== id),
    })),

    removePopupById: (id) =>
      set((state) => ({
        popups: state.popups.filter((popup) => popup.id !== id),
      })),

      removePlanById: (id) =>
        set((state) => ({
          plans: state.plans.filter((plan) => plan.id !== id),
        })),

  addSafetyTest: (newSafetyTest) =>
    set((state) => ({
      safetyTests: [...state.safetyTests, newSafetyTest],
    })),

  updateCommittee: (committeeId, updatedData) =>
    set((state) => ({
      committees: state.committees.map((committee) =>
        committee.id === committeeId
          ? { ...committee, ...updatedData }
          : committee
      ),
    })),

    updatePopupStore: (popupId, updatedData) =>
      set((state) => ({
        popups: state.popups.map((popup) =>
          popup.id === popupId
            ? { ...popup, ...updatedData }
            : popup
        ),
      })),







      updateCompanyDepartments: (companyId, newDepartment) =>
        set((state) => {
          const updatedCompanies = state.companies.map((company) => {
            if (company.id === companyId) {
              const updatedDepartments = [...company.departments, newDepartment];
              return { ...company, departments: updatedDepartments };
            }
            return company;
          });
          return { companies: updatedCompanies };
        }),


        removeCompanyDepartment: (companyId, departmentId) =>
          set((state) => {
            const updatedCompanies = state.companies.map((company) => {
              if (company.id === companyId) {
                const updatedDepartments = company.departments.filter(
                  (department) => department.empId !== departmentId.empId
                );
                return { ...company, departments: updatedDepartments };
              }
              return company;
            });
            return { companies: updatedCompanies };
          }),

          updateCompanyDepartment: (companyId, updatedDepartment) =>
            set((state) => {
              const updatedCompanies = state.companies.map((company) => {
                if (company.id === companyId) {
                  const updatedDepartments = company.departments.map((department) =>
                    department.empId === updatedDepartment.empId
                      ? { ...department, departmentName: updatedDepartment.departmentName }
                      : department
                  );
                  return { ...company, departments: updatedDepartments };
                }
                return company;
              });
              return { companies: updatedCompanies };
            }),
        






      updatePlanStore: (planId, updatedData) =>
        set((state) => ({
          plans: state.plans.map((plan) =>
            plan.id === planId
              ? { ...plan, ...updatedData }
              : plan
          ),
        })),

  updateSafetyTest: (safetyTestId, updatedData) =>
    set((state) => ({
      safetyTests: state.safetyTests.map((safetyTest) =>
        safetyTest.id === safetyTestId
          ? { ...safetyTest, ...updatedData }
          : safetyTest
      ),
    })),

  removeCommittee: (committeeId) =>
    set((state) => ({
      committees: state.committees.filter(
        (committee) => committee.id !== committeeId
      ),
    })),

  removeSafetyTest: (safetyTestId) =>
    set((state) => ({
      safetyTests: state.safetyTests.filter(
        (safetyTest) => safetyTest.id !== safetyTestId
      ),
    })),

  updateAccount: (accountId, updatedData) =>
    set((state) => ({
      accounts: state.accounts.map((account) =>
        account.id === accountId ? { ...account, ...updatedData } : account
      ),
    })),

  addLog: (newLog) =>
    set((state) => ({
      logs: [...state.logs, newLog],
    })),

  removeReport: (reportId) =>
    set((state) => ({
      machineReports: state.machineReports.filter(
        (report) => report.id !== reportId
      ),
    })),

  updateReport: (reportId, updatedData) =>
    set((state) => ({
      machineReports: state.machineReports.map((report) =>
        report.id === reportId ? { ...report, ...updatedData } : report
      ),
    })),

  updateFindingClosing: (findingId, updatedData) =>
    set((state) => ({
      findings: state.findings.map((finding) =>
        finding.id === findingId ? { ...finding, ...updatedData } : finding
      ),
    })),

  removeFinding: (findingId) =>
    set((state) => ({
      findings: state.findings.filter((finding) => finding.id !== findingId),
    })),

  removeSafetyReport: (reportId) =>
    set((state) => ({
      safetyReports: state.safetyReports.filter(
        (report) => report.id !== reportId
      ),
    })),

  addFinding: (newFinding) =>
    set((state) => ({
      findings: [...state.findings, newFinding],
    })),

  addMachineToCompany: (companyId, newMachine) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          const updatedMachines = [...company.machines, newMachine];
          return { ...company, machines: updatedMachines };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),

  addNewProject: (companyId, newProject) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          return { ...company, projects: [...company.projects, newProject] };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),

  updateUserPersonalData: (updatedData) =>
    set((state) => ({
      userPersonalData: {
        ...state.userPersonalData,
        ...updatedData,
      },
    })),

  updateCompanyProjects: (companyId, updatedProjects) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          return { ...company, projects: updatedProjects };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),

  removeProjectFromCompany: (companyId, projectId) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          const updatedProjects = company.projects.filter(
            (project) => project.siteId !== projectId
          );
          return { ...company, projects: updatedProjects };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),

  updateMyCompany: (updatedCompany) =>
    set((state) => ({
      myCompany: { ...state.myCompany, ...updatedCompany },
    })),

  updateCompanyLogo: (companyLogo) =>
    set((state) => ({
      myCompany: { ...state.myCompany, companyLogo },
    })),

  addCompanyToStore: (newCompany) =>
    set((state) => ({
      companies: [...state.companies, newCompany],
    })),

  addEmployeeToStore: (newEmployee) =>
    set((state) => ({
      employees: [...state.employees, newEmployee],
    })),

  updateEmployeeInStore: (updatedEmployee) =>
    set((state) => ({
      employees: state.employees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee
      ),
    })),

  removeEmployeeFromStore: (id) =>
    set((state) => ({
      employees: state.employees.filter((employee) => employee.id !== id),
    })),

  updateEmployeeFileInStore: (empId, newFile) =>
    set((state) => ({
      employees: state.employees.map((employee) =>
        employee.id === empId
          ? { ...employee, files: [...employee.files, newFile] }
          : employee
      ),
    })),

  addProjectToStore: (companyId, newProject) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          return {
            ...company,
            projects: [...company.projects, newProject],
          };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),
  removeProjectFromStore: (companyId, siteId) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          return {
            ...company,
            projects: company.projects.filter(
              (project) => project.siteId !== siteId
            ),
          };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),
  updateProjectInStore: (companyId, updatedProject) =>
    set((state) => {
      const updatedCompanies = state.companies.map((company) => {
        if (company.id === companyId) {
          return {
            ...company,
            projects: company.projects.map((project) =>
              project.siteId === updatedProject.siteId
                ? updatedProject
                : project
            ),
          };
        }
        return company;
      });
      return { companies: updatedCompanies };
    }),

    updateCompanyMachines: (companyId, updatedMachines) =>
      set((state) => {
        const updatedCompanies = state.companies.map((company) => {
          if (company.id === companyId) {
            return { ...company, machines: updatedMachines };
          }
          return company;
        });
        return { companies: updatedCompanies };
      }),

      
  updateCompanyInStore: (updatedCompany) =>
    set((state) => ({
      companies: state.companies.map((company) =>
        company.id === updatedCompany.id
          ? { ...company, ...updatedCompany }
          : company
      ),
    })),

  removeCompanyFromStore: (id) =>
    set((state) => ({
      companies: state.companies.filter((company) => company.id !== id),
    })),


    updateMainAccount: (updatedData) => set((state) => ({
      mainAccount: { ...state.mainAccount, ...updatedData }  // This method updates properties of the mainAccount
    })),


  updateUserInStore: (updatedUser) =>
    set((state) => ({
      accounts: state.accounts.map((user) =>
        user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      ),
    })),

  updateUserProjectRoles: (id, updatedRoles) =>
    set((state) => ({
      accounts: state.accounts.map((user) =>
        user.id === id ? { ...user, projectsRole: updatedRoles || [] } : user
      ),
    })),

  lockUnlockUserInStore: (id, isUserLock) =>
    set((state) => ({
      accounts: state.accounts.map((user) =>
        user.id === id ? { ...user, isUserLock } : user
      ),
    })),

  addUserToStore: (newUser) =>
    set((state) => ({
      accounts: [...state.accounts, newUser],
    })),

  removeUserInStore: (id) =>
    set((state) => ({
      accounts: state.accounts.filter((user) => user.id !== id),
    })),

  resetState: () =>
    set({
      isLoading: false,
      loadingMessage: "",
      selectedCompany: null,
      selectedSite: null,
      committees: [],
      employees: [],
      departments: [],
      findings: [],
      machineReports: [],
      periodics: [],
      safetyReports: [],
      safetyReportsAreas: [],
      safetyReportsItems: [],
      safetyCategories: [],
      safetyTests: [],
      trainingGroups: [],
      trainingItems: [],
      logs: [],
      popups: [],
      plans:[],
      error: null,
    }),

  setSelectedCompany: (selectedCompany) => set({ selectedCompany }),
  setSelectedSite: (selectedSite) => set({ selectedSite }),
  setUserPersonalData: (userPersonalData) => set({ userPersonalData }),
  setMyCompany: (myCompany) => set({ myCompany }),
  setMainAccount: (mainAccount) => set({ mainAccount }),
  setCommittees: (committees) => set({ committees }),
  setCompanies: (companies) => set({ companies }),
  setEmployees: (employees) => set({ employees }),
  setAssociateAccounts: (associateAccounts) => set({ associateAccounts }),
  setFindings: (findings) => set({ findings }),
  setPopups: (popups) => set({ popups }),
  setPlans: (plans) => set({ plans }),
  setMyPlan: (myPlan) => set({myPlan}),
  setMachinesReports: (machineReports) => set({ machineReports }),
  setAccounts: (accounts) => set({ accounts }),
  setPeriodics: (periodics) => set({ periodics }),
  setSafetyReports: (safetyReports) => set({ safetyReports }),
  setSafetyReportsAreas: (safetyReportsAreas) => set({ safetyReportsAreas }),
  setSafetyReportsItems: (items) => set({ safetyReportsItems: items }),
  setSafetyCategories: (safetyCategories) => set({ safetyCategories }),
  setSafetyTests: (safetyTests) => set({ safetyTests }),
  setTrainingGroups: (trainingGroups) => set({ trainingGroups }),
  setTrainingItems: (trainingItems) => set({ trainingItems }),
  setLogs: (logs) => set({ logs }),
  setError: (error) => set({ error }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setLoadingMessage: (loadingMessage) => set({ loadingMessage }),
  }),
  {
    name: 'dashboard-storage', // unique name for localStorage key
    storage: localStorage,
    serialize: (state) => JSON.stringify(state),
    deserialize: (str) => JSON.parse(str),
    onRehydrateStorage: () => (state, error) => {
        if (error) {
            console.error('Rehydration error:', error);
        } else if (state) {
            console.log('Rehydrated state:', state.theSelectedPlan);
        } else {
            console.log('Rehydration complete, no state found');
        }
    },
}));

export default useDashboardStore;
