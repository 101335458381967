import React, {useState} from 'react'
import { IoSettingsSharp } from "react-icons/io5";
import { MdDeleteOutline,MdEdit,MdArrowBackIos,MdArrowForwardIos,MdOutlineSave } from "react-icons/md";
const SafetyReportSubItem = (props) => {

let bg = '#ededed'
if(props?.selectedSafetyReportSubItem?.itemId === props.subItem.itemId){bg = '#c0e1ff'}

const [isEditView, setIsEditView] = useState(false)
const [safetyReportSubItem, setSafetyReportSubItem] = useState(props.subItem.safetyReportSubItem)

  return (
    <div className='row safety-item-row' style={{backgroundColor:bg}}>
        {
            isEditView ? (<>
                <div className='col-lg-7'>
                    <input
                            value={safetyReportSubItem}
                            onChange={(e) => setSafetyReportSubItem(e.target.value)}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className='col-lg-5'>
                        <button style={{marginLeft:5}} onClick={() => {setIsEditView(!isEditView)}} className='btn btn-warning btn-sm'><MdArrowForwardIos color="#ffffff" size={20} /></button>
                        <button onClick={() => {
                            props.updateSafetyReportSubItem(props.subItem.itemId,safetyReportSubItem);
                            setIsEditView(!isEditView)}} className='btn btn-success btn-sm'><MdOutlineSave color="#ffffff" size={20} /></button>
                    </div>
                </>) : (<>
                    <div className='col-lg-6'>
                        <p className='site-name'>{props.subItem.safetyReportSubItem}</p>
                    </div>
                    <div className='col-lg-6'>
                        <button style={{marginLeft:5}} onClick={() => {setIsEditView(!isEditView)}} className='btn btn-success btn-sm'><MdEdit color="#ffffff" size={20} /></button>
                        <button style={{marginLeft:5}} onClick={() => {props.removeSafetyReportSubItem(props.subItem.itemId)}} className='btn btn-danger btn-sm'><MdDeleteOutline color="#ffffff" size={20} /></button>
                        <button onClick={() => {props.setSelectedSafetyReportSubItem(props.subItem)}} className='btn btn-light btn-sm'><MdArrowBackIos color="#A3BAC3" size={20} /></button>
                    </div>
                </>)
        }
    </div>
  )
}

export default SafetyReportSubItem