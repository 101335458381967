import React, {useState} from "react";
import moment from "moment";

const NotificationItem = (props) => {

    const reportMessage = 'הבדיקה הבאה: ' + moment(props.nextDate).format('DD/MM/YYYY');

    let priorityColor = "#ffcc00";
    if (props.daysLeft > 30) {
        priorityColor = "#00cc99";
    }
    if (props.daysLeft < 30) {
        priorityColor = "#ffcc00";
    }
    if (props.daysLeft < 1) {
        priorityColor = "#EB6079";
    }
    if (props.daysLeft === 0) {
        priorityColor = "#444444";
    }


    return(
        <>
            <div className="log-row">
                <div className="row">
                    <div className="col-lg-7">
                        <h6 className="not-title">{props.title}</h6>
                        <p style={{fontSize:15}}>
                        {props.description}<br/>
                        הבדיקה התקיימה בתאריך: {moment(props.currentDate).format('DD/MM/YYYY')}
                        </p>
                    </div>
                    <div className="col-lg-2" style={{textAlign:'center'}}>
                        <p>ימים לבדיקה:</p>
                        <h6 className="days" style={{color:priorityColor}}>{props.daysLeft}</h6>
                    </div>
                    <div className="col-lg-3 next-container" style={{background:priorityColor}}>
                        <p className="next-days">{reportMessage}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationItem;