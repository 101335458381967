import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDbDsTwHIj2OsqqfL0aidxqaWlSy0qrNps",
  authDomain: "memune-fbd73.firebaseapp.com",
  projectId: "memune-fbd73",
  storageBucket: "memune-fbd73.appspot.com",
  messagingSenderId: "1084028688172",
  appId: "1:1084028688172:web:8d85b16a4776a4b67a7e89"
};

const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);