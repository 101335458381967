import React, {useState} from 'react'
import useDashboardStore from "../../../store/useDashboardStore";
import { MdDeleteOutline,MdEdit,MdArrowBackIos,MdArrowForwardIos,MdOutlineSave } from "react-icons/md";


const SafetyReportItem = (props) => {
  const { userPersonalData } = useDashboardStore();
  let bg = '#ededed'
  if(props?.selectedSafetyReportItem?.id === props.item.id){
    bg = '#c0e1ff'
  }

  const [isEditView, setIsEditView] = useState(false)
  const [safetyReportItem, setSafetyReportItem] = useState(props.item.safetyReportItem)
  const [adminCategory, setAdminCategory] = useState(props.item.adminCategory ? props.item.adminCategory : false);

  return (
    <div className='row safety-item-row' style={{backgroundColor:bg}}>
        {
          isEditView ? (<>
            <div className='col-lg-7'>
            <input
                value={safetyReportItem}
                onChange={(e) => setSafetyReportItem(e.target.value)}
                type="text"
                placeholder="שם סוג דוח בטיחות"
                className="form-control"
              />
            </div>
            <div className='col-lg-5'>
                <button style={{marginLeft:5}} onClick={() => {setIsEditView(!isEditView)}} className='btn btn-warning btn-sm'><MdArrowForwardIos color="#ffffff" size={20} /></button>
                <button onClick={() => {
                  props.updateReportItem(props.item.id,safetyReportItem,adminCategory);
                  setIsEditView(!isEditView)}} className='btn btn-success btn-sm'><MdOutlineSave color="#ffffff" size={20} /></button>
                
            </div>
            <div className="col-lg-12">
              {
                userPersonalData.role === 'superadmin' && (<>
                <input
                    onChange={(e) => setAdminCategory(e.target.checked)}
                    checked={adminCategory}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"/>{" "}<label className="form-check-label">הוסף קטגוריה לכל המשתמשים</label>
                </>)
              }
            </div>
          </>) : (
            <>
              <div className='col-lg-6'><p className='site-name'>{props.item.safetyReportItem}</p></div>
              <div className='col-lg-6'>
                  <button style={{marginLeft:5}} onClick={() => {setIsEditView(!isEditView)}} className='btn btn-success btn-sm'><MdEdit color="#ffffff" size={20} /></button>
                  <button style={{marginLeft:5}} onClick={() => {props.removeReportItem(props.item.id)}} className='btn btn-danger btn-sm'><MdDeleteOutline color="#ffffff" size={20} /></button>
                  <button onClick={() => {props.selectAndClear(props.item)}} className='btn btn-light btn-sm'><MdArrowBackIos color="#A3BAC3" size={20} /></button>
              </div>
            </>
          )
        }
    </div>
  )
}

export default SafetyReportItem