import {database} from '../services/firebase-config';
import {collection,query,where,limit,getDocs,getDoc,orderBy,doc,addDoc} from "firebase/firestore";

//GET PERSONAL ACCOUNT DATA
export const getAccountDetails = async (uid) => {
    try {
      const accountsRef = collection(database, "accounts");
      const q = query(accountsRef, where("uuid", "==", uid));
      const querySnapShot = await getDocs(q);
      const array = querySnapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      return array[0];
    } catch (error) {
      return error.message;
    }
  };

//GET PERSONAL COMPANY
export const getCompanyDetails = async (associateId) => {
  try {
    const companiesOwnerRef = collection(database, "companiesOwner");
    const q = query(companiesOwnerRef, where("associateId", "==", associateId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const documentSnapshot = querySnapshot.docs[0];
      const companyData = documentSnapshot.data();
      return companyData;
    } else {
      return {}
    }
  } catch (error) {
    return error.message;
  }
};


export const getMainAccount = async (associateId) => {
  try {
    const companiesOwnerRef = collection(database, "accounts");
    const q = query(companiesOwnerRef, where("uuid", "==", associateId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const documentSnapshot = querySnapshot.docs[0]; // Getting the first document
      const companyData = documentSnapshot.data();
      companyData.id = documentSnapshot.id; // Append the document ID to the data object
      return companyData;
    } else {
      return {}; // Return an empty object if no document is found
    }
  } catch (error) {
    return error.message; // Return the error message if an error occurs
  }
};



  export const requestDataByAssociate = async (associateId, collectionRef) => {
    try {
      const dataRef = collection(database, collectionRef);
      const q = query(dataRef, where("associateId", "==", associateId));
      const querySnapShot = await getDocs(q);
      const dataArr = querySnapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log('DATA CALL => ' + collectionRef);
      return dataArr;
    } catch (error) {
      return error.message;
    }
  };


  export const requestSuperData = async (collectionRef) => {
    try {
      const dataRef = collection(database, collectionRef);
      const querySnapShot = await getDocs(dataRef);
      const dataArr = querySnapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      return dataArr;
    } catch (error) {
      return error.message;
    }
  };
  