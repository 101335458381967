import moment from 'moment'
import React from 'react'

const LogCard = ({log}) => {

  

  return (
    <div className='row logrow'>
        <div className='col-lg-12'>
            <p className='log-date'>{moment(log.createdAt).format('DD/MM/YYYY HH:MM')}</p>
            <p className='log-title'>{log.logTitle}</p>
            <p className='log-description'>{log.logDescription}</p>
        </div>
    </div>
  )
}

export default LogCard