import moment from "moment";
import React, { useState } from "react";
import { storage } from "../../../services/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const FindingReportItem = (props) => {

  const project = props?.projects?.find((x) => x.siteId === props.item.site);
  const findingType = props.findingsType.find((x) => x.id === props.item.itemImpairmentArea);
  const treater = props.employeesArr.find((x) => x.id === props.item.itemImpairmentTreat);
  const tracker = props.employeesArr.find((x) => x.id === props.item.itemImpairmentTrack);


  let priorityColor = "#ffcc00";

  if (props.item.itemImpairmentSeverity === "קל") {
    priorityColor = "#67eb34";
  }
  if (props.item.itemImpairmentSeverity === "בינוני") {
    priorityColor = "#ffcc00";
  }
  if (
    props.item.itemImpairmentSeverity === "חמור" ||
    props.item.itemImpairmentSeverity === "מסכן חיים"
  ) {
    priorityColor = "#EB6079";
  }


  return (
    <>

          <div className="row" style={{marginBottom: 10,padding: 5,borderRadius: 12,background: "#F8FCFC",}}>
            
            
            <div className="col-lg-1">
              {props.item.gallery.length > 0 ? (
                <>
                  <div style={{width: "100%",height: 80,overflow: "hidden",display: "flex",justifyContent: "center",alignItems: "center",}}>
                    <img className="finding-row-image"src={props.item.gallery[0].imageSource}alt={props.item.gallery[0].imgId}/>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>


            <div className="col-lg-5">
              <h6>פרויקט: {project?.projectName}</h6>
              <p>תחום הליקוי: <b>{findingType.safetyReportAreaItem}</b></p>
              <p>
                קבצים מצורפים: <b>{props.item.gallery.length} קבצים</b> | סטטוס
                ממצא: <b style={{color: props.item.status === 'פעיל' ? '#EB6079' : '#67eb34'}}>{props.item.status}</b>
              </p>
            </div>


            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 14 }}>תאריך הקמה</p>
              <h6>{moment(props.item.createdAt).format("DD/MM/YYYY")}</h6>
            </div>


            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 0 }}>ליקוי</p>
              <div className="badge" style={{ backgroundColor: priorityColor }}>
                <h6 style={{ color: "#ffffff" }}>
                  {props.item.itemImpairmentSeverity}
                </h6>
              </div>
            </div>
            
            
            <div className="col-lg-3">
              <p style={{ marginTop: 14 }}>
                <b>למעקב של: </b>
                {tracker.firstName} {tracker.lastName}
                <br />
                <b>לטיפול של: </b>
                {treater.firstName} {treater.lastName}
                <br />
              </p>
            </div>


          </div>
    </>
  );
};

export default FindingReportItem;
