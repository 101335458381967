import React from 'react'
import moment from 'moment'
const HpFinding = (props) => {

    const project = props?.projects?.find((x) => x.siteId === props.item.site);
    const findingType = props?.findingsType?.find((x) => x.id === props.item.itemImpairmentArea);


    let priorityColor = "#ffcc00";
    let statusColor = "#ffcc00"
  
    if (props.item.itemImpairmentSeverity === "קל") {
      priorityColor = "#6fcb9f";
    }
    if (props.item.itemImpairmentSeverity === "בינוני") {
      priorityColor = "#ffcc00";
    }
    if (
      props.item.itemImpairmentSeverity === "חמור" ||
      props.item.itemImpairmentSeverity === "מסכן חיים"
    ) {
      priorityColor = "#EB6079";
    }
  
  
  
    if (props.item.status === "סגור") {
      statusColor = "#6fcb9f";
    }
    if (props.item.status === "פעיל") {
      statusColor = "#ff9933";
    }
    if (props.item.status === "בטיפול") {
      statusColor = "#ffcf40";
    }



  return (
    <div className='row logrow'>
        <div className='col-lg-9'>
        <p className='log-date'>{moment(props.item.createdAt).format('DD/MM/YYYY HH:MM')}</p>
        <p className='log-title'>פרויקט: {project?.projectName}</p>
              <p className='log-description'>תחום הליקוי: <b>{findingType?.safetyReportAreaItem}</b></p>
              <p className='log-description'>
                קבצים מצורפים: <b>{props.item.gallery.length} קבצים</b> | סטטוס
                ממצא: <b style={{color:statusColor}}>
                  {
                    props.item.status
                  }
                  </b>
              </p>
        </div>
        <div className='col-lg-3' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <div className="badge" style={{ backgroundColor: priorityColor }}>
                <h6 style={{ color: "#ffffff", fontSize:16 }}>
                  {props.item.itemImpairmentSeverity}
                </h6>
              </div>
        </div>
    </div>
  )
}

export default HpFinding