import React, { useState, useEffect, useCallback } from "react";
import { auth, database } from "../../services/firebase-config";
import {requestDataByAssociate,getAccountDetails} from "../../services/dbHelper";
import {collection,addDoc,doc,deleteDoc,updateDoc} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import TrainingItem from './components/TrainingItem';
import useDashboardStore from "../../store/useDashboardStore";
import { requestSuperData } from "../../store/dashboardHook";
import TrainingItemAdmin from "./components/TrainingItemAdmin";


const TrainingsCategories = () => {
  const { userPersonalData } = useDashboardStore();
  const [isLoading, setIsLoading] = useState(false);
  const [myAccount, setMyAccount] = useState(false);
  const [trainingItem, setTrianingItem] = useState("");
  const [trainingItems, setTrainingItems] = useState([]);
  const [trainingItemsAdmin, setTrainingItemsAdmin] = useState([]);
  const [adminCategory, setAdminCategory] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);

      const trainingItemArr = await requestDataByAssociate(account.associateId,"trainingItems");
      const safetyReportsAreasArrAdmin = await requestSuperData("trainingItems");

      const trainingItemArrFiltered = trainingItemArr.filter((x) => x.adminCategory === false);
      const trainingItemArrAdminFiltered = safetyReportsAreasArrAdmin.filter((x) => x.adminCategory === true);

      setTrainingItems(trainingItemArrFiltered);
      setTrainingItemsAdmin(trainingItemArrAdminFiltered);
      setMyAccount(account);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);


  const addSafetyReportArea = async() => {
    if(trainingItem !== ""){
      try {
        await addDoc(collection(database, "trainingItems"), {
          associateId: myAccount.associateId,
          trainingItem: trainingItem,
          adminCategory: adminCategory,
          templateId: ''
        });
        loadData();
        setTrianingItem("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שם תחום ההדרכה");
    }
  }

  const addTemplateToMyList = async (item) => {
    try {
      await addDoc(collection(database, "trainingItems"), {
        associateId: myAccount.associateId,
        trainingItem: item.trainingItem,
        adminCategory: false,
        templateId: item.id
      });
      loadData();
      setTrianingItem("");
    } catch (error) {
      toast.error(error.message);
    }
  };


  const editItem = async(id,title) => {
    const trackingRef = doc(database, "trainingItems", id);
    try {
        await updateDoc(trackingRef, {
          trainingItem: title,
        })
        loadData()
    } catch (error) {
        toast.error(error.message)
    }
  }
  const deleteItem = async(id) => {
    const isConfirmed = window.confirm("האם למחוק את הקטגוריה לצמיתות?");
    if(isConfirmed){
        try {
            await deleteDoc(doc(database, "trainingItems", id))
            .then(result => {
                loadData()
            })
        } catch (error) {
            toast.error(error.message)
        }
    }
  }





  return (
    <>
    <div className="row heading-container">
        <div className="col-lg-10">
          <h3>תחומי הדרכות</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-4">

        <h6>תחומי הדרכות</h6>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-9">
              <input
                value={trainingItem}
                onChange={(e) => setTrianingItem(e.target.value)}
                type="text"
                placeholder="שם תחום הדרכה"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <button onClick={addSafetyReportArea} className="btn btn-success">
                הוספה
              </button>
            </div>

            <div className="col-lg-12">
              {userPersonalData.role === "superadmin" && (
                <>
                  <input
                    onChange={(e) => setAdminCategory(e.target.checked)}
                    checked={adminCategory}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הוסף קטגוריה לכל המשתמשים
                  </label>
                </>
              )}
            </div>


          </div>


          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {isLoading ? (
                <>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                <>
  <p style={{ marginBottom: 20 }}>
    <b>הקטגוריות שלי</b>
  </p>
  {trainingItems.length > 0 ? (
    <>
      {trainingItems.map((item) => (
        <TrainingItem
          key={item.id}
          item={item}
          editItem={editItem}
          deleteItem={deleteItem}
        />
      ))}
    </>
  ) : (
    <p>לא קיימים תחומי הדרכה במערכת</p>
  )}

  <p style={{ marginBottom: 12, marginTop: 20 }}>
    <b>קטגוריות מערכת</b>
  </p>
  {trainingItemsAdmin.length > 0 ? (
    <>
      {trainingItemsAdmin.filter(adminItem => !trainingItems.some(myItem => myItem.templateId === adminItem.id)).map((item) => (
        <TrainingItemAdmin
          key={item.id}
          role={userPersonalData.role}
          addTemplateToMyList={addTemplateToMyList}
          item={item}
          editItem={editItem}
          deleteItem={deleteItem}
        />
      ))}
    </>
  ) : (
    <p>לא קיימים תחומי הדרכה במערכת</p>
  )}
</>

              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-4"></div>
      </div>
    </>
  )
}

export default TrainingsCategories