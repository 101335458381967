import React, {useState, useEffect} from "react";
import moment from "moment";

const ContactItem = (props) => {

    return(
        <>
            <tr key={props.item.id}>
                <td>{moment(props.item.createdAt).format('DD/MM/YYYY hh:mm')}</td>
                <td>{props.item.fullname}</td>
                <td>{props.item.companyName}</td>
                <td>{props.item.email}</td>
                <td>{props.item.phone}</td>
                <td>{props.item.freetext}</td>
                <td>
                    <button onClick={() => {props.removePopup(props.item.id)}} className="btn btn-danger">הסרה</button>
                </td>
             </tr>
        </>
    )
}

export default ContactItem;