import React from "react";

const NotifyMachine = (props) => {
  let statusColor = "";
  if (props.item.daysLeft > 0) {
    statusColor = "#ff9933";
  }
  if (props.item.daysLeft < 0) {
    statusColor = "#EB6079";
  }

  console.log(props.item);
  const machine = props.allMachines.find(x => x.empId === props.item.associateMachineId);

  return (
    <div className="row">
      <div className="col-lg-6">
        <p>{`${machine?.machineModel} - מס״ד ${machine?.machineSerialNumber}`}</p>
      </div>
      <div className="col-lg-4">
        <p>
          {props.item.reportType} | {props.item.checkType}
        </p>
      </div>
      <div className="col-lg-2">
        <p style={{ color: statusColor }}>
          <b>{props.item.daysLeft}</b>
        </p>
      </div>
    </div>
  );
};

export default NotifyMachine;
