import React from "react";

const TrainingNotify = (props) => {
  let statusColor = "";
  if (props.item.daysLeft > 0) {
    statusColor = "#ff9933";
  }
  if (props.item.daysLeft < 0) {
    statusColor = "#EB6079";
  }

  return (
    <div className="row">
      <div className="col-lg-10">
        <p>{props.item.trainingName}</p>
      </div>
      <div className="col-lg-2">
        <p style={{ color: statusColor }}>
          <b>{props.item.daysLeft}</b>
        </p>
      </div>
    </div>
  );
};

export default TrainingNotify;
