import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { BsFillFilePdfFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";

import { FaRegCheckCircle } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CommitteeCard = (props) => {
  const [comitEdidView, setComitEditView] = useState(false);
  const [committeeName, setCommitteeName] = useState(props.comit.committeeName);
  const [committeeDesc, setCommitteeDesc] = useState(props.comit.committeeDesc);
  const [committeeDate, setCommitteeDate] = useState(props.comit.committeeDate);
  const [committeeNexDateVal, setCommitteeNextDateVal] = useState(props.comit.committeeNextDate);
  const [committeeFile, setCommitteeFile] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(props.comit.employees);
  const [selectedOption, setSelectedOption] = useState("");
  const [employeesArr, setEmployeesArr] = useState(props.employeesArr);
  const [isReportSent, setIsReportSent] = useState(props.comit.isReportSent);


  useEffect(() => {
    setEmployeesArr(
      props.employeesArr.filter(
        (e) => !selectedEmployees.some((se) => se.id === e.id)
      )
    );
  }, [selectedEmployees, props.employeesArr]);

  const handleRemove = (emp) => {
    setSelectedEmployees(selectedEmployees.filter((v) => v.id !== emp.id));
    setEmployeesArr([...employeesArr, emp]); // Add back to the dropdown options
  };

  const handleSelect = (empId) => {
    const emp = props.employeesArr.find((x) => x.id === empId); // Use the original prop to find the employee
    if (emp && !selectedEmployees.some((e) => e.id === emp.id)) {
      setSelectedEmployees([...selectedEmployees, emp]);
    }
  };
  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  return (
    <>
      {comitEdidView ? (
        <>
          <div className="row rowshadowbox">
            <div className="row">
              <div className="col-lg-6">
                <label className="form-label">שם הועדה</label>
                <input
                  type="text"
                  className="form-control"
                  value={committeeName}
                  onChange={(e) => {
                    setCommitteeName(e.target.value);
                  }}
                />
              </div>


              <div className="col-lg-3">
                <label className="form-label">תאריך הועדה</label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(committeeDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setCommitteeDate(e.target.value);
                  }}
                />
              </div>

              <div className="col-lg-3">
                <label className="form-label">הועדה הבאה</label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(committeeNexDateVal).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setCommitteeNextDateVal(e.target.value);
                  }}
                />
              </div>


            </div>



            <div className="row">
              <div className="col-lg-12">
                <label className="form-label">תיאור הועדה</label>
                <textarea
                  className="form-control"
                  value={committeeDesc}
                  onChange={(e) => {
                    setCommitteeDesc(e.target.value);
                  }}></textarea>
              </div>

              <div className="col-lg-12" style={{marginTop:20,marginBottom:20}}>
              <input
                    onChange={(e) => setIsReportSent(e.target.checked)}
                    checked={isReportSent}
                    className="form-check-input"
                    type="checkbox"
                    role="switch" />{" "}<label className="form-check-label">מעקב לשליחת וועדת בטיחות למשרד העבודה</label>
              </div>


            </div>

            <div className="row" style={{marginBottom:12}}>
              <div className="col-lg-12">
                <label className="form-label">העלאת קובץ חדש</label>
                <button type="button" style={{marginRight:12}} onClick={triggerFileInput} className="btn btn-success">
                  בחר קובץ
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="form-control"
                  style={{ display: 'none' }} // Hide the default file input
                  onChange={(e) => {
                    setCommitteeFile(e.target.files[0]); // Use your existing onChange handler
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <select
                  className="form-control"
                  value={selectedOption}
                  onChange={(e) => {
                    handleSelect(e.target.value);
                    setSelectedOption(e.target.value);
                  }}
                >
                  <option value="">בחר עובדים מהרשימה...</option>
                  {employeesArr.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="col-lg-12"
                style={{ paddingTop: 12, paddingBottom: 12 }}
              >
                <span>נכחו בועדה: </span>
                {props.comit.employees.length > 0 && (
                  <>
                    {selectedEmployees.map((emp) => (
                      <span
                        key={emp.id}
                        onClick={() => {
                          handleRemove(emp);
                        }}
                        style={{ marginRight: 6 }}
                        className="badge text-bg-info"
                      >
                        {emp.firstName} {emp.lastName}
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>

            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <button
                    style={{ marginRight: 12 }}
                    onClick={() => setComitEditView(!comitEdidView)}
                    className="btn btn-dark"
                  >
                    ביטול
                  </button>

                  <button
                    style={{ marginRight: 10 }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="מחיקה תסיר את הועדה מהרשומות לצמיתות"
                    onClick={() => {
                      props.removeCommittee(props.comit);
                      setComitEditView(false);
                    }}
                    className="btn btn-danger"
                  >
                    <MdDelete size={24} />
                  </button>
                </div>

                <button
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    props.updateComit(
                      selectedEmployees,
                      committeeName,
                      committeeDate,
                      committeeNexDateVal,
                      committeeDesc,
                      committeeFile,
                      props.comit.id,
                      isReportSent
                    )
                  setComitEditView(!comitEdidView)
                  }
                  }
                  className="btn btn-success">שמירת נתונים</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row rowshadowbox">


            <div className="col-lg-5">
              <h6>{props.comit.committeeName}</h6>
              <p>{props.comit.committeeDesc}</p>
              <p>
                <b>השתתפו בועדה: </b>
                {props.comit.employees.map((emp) => (
                  <>
                    {emp.firstName} {emp.lastName},{" "}
                  </>
                ))}
              </p>
            </div>

            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p>משרד העבודה</p>
              {
                props.comit.isReportSent ? (<FaRegCheckCircle size={34} color="#6fcb9f" />) : (<AiOutlineCloseCircle size={34} color="#EB6079" />)
              }
            </div>


            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>תאריך הועדה</p>
              <h4>{moment(props.comit.committeeDate).format("DD/MM/YYYY")}</h4>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>הועדה הבאה</p>
              <h4>{moment(props.comit.committeeNextDate).format("DD/MM/YYYY")}</h4>
            </div>


            <div className="col-lg-1" style={{ textAlign: "center" }}>

            <p>קבצים ({props.comit.committeeFiles.length})</p>
              {
                props.comit.committeeFiles.length > 0 && (<>
                {
                  props.comit.committeeFiles.map((file) => (
                  <a href={file} target="_blank" rel="noreferrer"><BsFillFilePdfFill color="#EB6079" size={30} /></a>))
                }
                </>)
              }

            </div>

            <div
              className="col-lg-1"
              style={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <button
                style={{ marginRight: 12 }}
                onClick={() => setComitEditView(!comitEdidView)}
                className="btn btn-success"
              >
                <RiEdit2Fill size={24} />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CommitteeCard;
