import React, { useState, useEffect } from "react";
import FindingReportItem from "./FindingReportItem";

const SafetySlider = (props) => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [notReleventButton, setNotReleventButton] = useState(false);

  const currentSliderValue = props.item.safetyReportSubItemTitleScore * (selectedValue / 100);

  return (
    <>
      <div className="col-lg-12 safetyCategory-item-row">
        <div className="row">

          <div className="col-lg-1"><p>ציון <b>{props.item.safetyReportSubItemTitleScore}</b></p></div>
          <div className="col-lg-5"><p>{ props.item.safetyReportSubItemTitleName }</p></div>
          
          <div className="col-lg-2 slider-container">
            <input
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                setSelectedValue(newValue);
                const score = notReleventButton ? 0 : props.item.safetyReportSubItemTitleScore * (newValue / 100);
                props.setValue(score, props.index);
                props.setIndex(props.index);
              }}
              value={selectedValue}
              step={25}
              min={props.minValue}
              max={props.maxValue}
              style={{ marginTop: 7 }}
              type="range"
              className="form-range"
              disabled={notReleventButton}
            />
          </div>

          <div className="col-lg-1">{currentSliderValue.toFixed(0)}</div>
          
          <div className="col-lg-1">
            {notReleventButton ? (
              <button
                onClick={() => {
                  setNotReleventButton(false);
                  props.setValue(0);
                  setSelectedValue(0);
                  props.setIndex(props.index);
                }}
                className={
                  notReleventButton
                    ? "btn btn-warning btn-sm"
                    : "btn btn-info btn-sm"
                }>רלבנטי</button>
            ) : (
              <button
                onClick={() => {
                  setNotReleventButton(true);
                  props.setValue(0);
                  props.setIndex(props.index);
                }}
                className={
                  notReleventButton
                    ? "btn btn-warning btn-sm"
                    : "btn btn-info btn-sm"
                }>לא רלבנטי</button>
            )}
          </div>

          <div className="col-lg-1">
            {parseInt(selectedValue) !== 100 ? (
              <button
                className="btn btn-info btn-sm"
                onClick={() => {
                  props.setFindingModalVisible(true);
                  props.setSafetyAssosicateCategoryId(props.item.itemId);
                  props.propSetIndex(props.propIndex);
                  props.setSectionIndex(props.index);
                }}>הוספת ממצא חדש</button>
            ) : null}
          </div>

          <div className="col-lg-1">
            {parseInt(selectedValue) !== 100 ? (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  props.setexistFindingModalVisible(true);
                  props.setSafetyAssosicateCategoryId(props.item.itemId);
                  props.propSetIndex(props.propIndex);
                  props.setSectionIndex(props.index);
                }}>בחירה ממצאים קיימים</button>
            ) : null}
          </div>

        </div>
      </div>

      {props.item.findingItems && (
        <div className="row" style={{ padding: 20 }}>
          {props.item.findingItems.length > 0 &&
            props.item.findingItems.map((item) => (
              <FindingReportItem
                companiesArr={props.companiesArr}
                findingsType={props.findingsType}
                employeesArr={props.employeesArr}
                projects={props.projects}
                item={item}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default SafetySlider;
