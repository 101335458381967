import React, { useState, useEffect } from "react";
import SafetySlider from "./SafetySlider";

const SafetyCategory = (props) => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState(0);

  const [categoryValue, setCategoryValue] = useState([]);
  const categoryReducer = categoryValue.reduce((a, b) => a + b, 0);

  const setCategoryValueCallback = (updatedArray) => {
    props.setSelectedReportItemDummy((prev) => {
      const updatedState = { ...prev };
      updatedState.subCategories[props.index].score = updatedArray.reduce(
        (a, b) => a + b,
        0
      );
      updatedState.subCategories[props.index].sections[
        index
      ].sectionFinalScore = value;
      return updatedState;
    });
  };



  useEffect(() => {
    setCategoryValue((prevCategoryValue) => {
      const newArray = [...prevCategoryValue];
      newArray[index] = value;
      const updatedArray = newArray.map((v) => (v === undefined ? 0 : v));
      setCategoryValueCallback(updatedArray);
      return updatedArray;
    });
    props.setIndex(props.index);
  }, [value]);


  const updateScore = (score, index) => {
    setCategoryValue((prevScores) => {
      const updatedScores = [...prevScores];
      updatedScores[index] = score;
      props.setValue(updatedScores.reduce((a, b) => a + b, 0));
      setCategoryValueCallback(updatedScores);
      setValue(score);
      props.setIndex(props.index);
      return updatedScores;
    });
  };

  const totalCategoryScore = categoryValue.reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    // Assuming there's a prop function to call when total score updates
    // For example: props.onTotalScoreChange(totalCategoryScore);
  }, [totalCategoryScore]);
  
  return (
    <>
      <div className="row">
        <div className="col-lg-12 safetyCategory-header">
          <h3 className="safetyCategory-title">
            {props.safetyCategory.safetyReportSubItem} -{" "}
            <b>{categoryReducer.toFixed(0)} </b>
          </h3>
        </div>
      </div>
      <div className="row">

        {props.safetyCategory.sections?.length > 0 && (
          <>
            {props.safetyCategory.sections.map((item, index) => (
              <SafetySlider
                key={index}
                setFindingModalVisible={props.setFindingModalVisible}
                setexistFindingModalVisible={props.setexistFindingModalVisible}
                index={index}
                setIndex={setIndex}
                setSectionIndex={props.setSectionIndex}
                propSetIndex={props.setIndex}
                propIndex={props.index}
                item={item}
                companiesArr={props.companiesArr}
                setValue={(score) => updateScore(score, index)}
                maxValue={maxValue}
                minValue={minValue}
                setSafetyAssosicateCategoryId={props.setSafetyAssosicateCategoryId}
                findingsType={props.findingsType}
                employeesArr={props.employeesArr}
                projects={props.projects}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default SafetyCategory;
