import React, {useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'
import { Link, useNavigate } from "react-router-dom";
import { auth, database } from "../../services/firebase-config";
import { signInWithEmailAndPassword,createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { collection, addDoc, doc, updateDoc, query, where, getDocs } from "firebase/firestore";

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [code, setCode] = useState("")
  const [mobile, setMobile] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const [selectedView, setSelectedView] = useState('login'); //register //invite //recover

  const loginAction = async() => {
    setIsLoading(true);
    if (email !== "" && password !== "") {
      try {
        await signInWithEmailAndPassword(auth, email, password).then(
          async (user) => {
            setIsLoading(false);
            navigate("/dashboard");
          }
        );
      } catch (error) {
        setIsLoading(false);
        console.log(error.message);
        let errMsg = "";
        switch (error.message) {
          case "Firebase: Error (auth/user-not-found).":
            errMsg = "משתמש אינו קיים במערכת";
            break;
          case "Firebase: Error (auth/wrong-password).":
            errMsg = "הסיסמה לא נכונה";
            break;
          case "Firebase: Error (auth/invalid-credential).":
            errMsg = "פרטי ההתחברות שגויים";
            break;
          default:
            errMsg = error.message;
            break;
        }
        toast.error(errMsg, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      setIsLoading(false);
      toast.error("נא למלא דואר אלקטרוני וסיסמה", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }


  const signupAction = async() => {
    setIsLoading(true);
    if (
      email !== "" &&
      password !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      companyName !== "" &&
      mobile !== ""
    ) {
      try {

        const today = new Date();
        today.setDate(today.getDate() + 30);
        const timestamp = today.getTime();


        await createUserWithEmailAndPassword(auth, email, password).then(
          async (user) => {
            await addDoc(collection(database, "accounts"), {
              uuid: user.user.uid,
              associateId: user.user.uid,
              expoPushToken: "",
              role: "admin",
              email:email,
              avatar: "https://res.cloudinary.com/united-app/image/upload/v1683366002/avatars/avatar_k4ntm3.png",
              contact: {phone: mobile,address: "",zip: "",city: "",country: "",state: "",signature: "",},
              firstName: firstName,
              lastName: lastName,
              isDisplay: true,
              projectsRole: [],
              companyName: companyName,
              createdAt: Date.now(),
              license: 'Free',
              planId: 'SuN9HPv29SC3lrbXqUqR',
              expireDate: timestamp,
              code: getRandomArbitrary(1000,9999)
            }).then(async(account_created) => {

              const companiesOwnerRef = collection(database, "companiesOwner");
              const q = query(companiesOwnerRef, where("associateId", "==", user.user.uid));
              const querySnapshot = await getDocs(q);

              let company = {
                companyName: companyName,
                associateId: user.user.uid,
                companyAddress: '',
                companyCity: '',
                companyEmail: '',
                companyPhone: '',
                companyTaxId: '',
                companyLogo: ''
              };
              if (querySnapshot.empty) {
                await addDoc(companiesOwnerRef, company);
              }
              setIsLoading(false);
              navigate("/dashboard");
            });
          }
        );
      } catch (error) {
        setIsLoading(false);
        let errMsg = "";
        switch (error.message) {
          case "Firebase: Error (auth/email-already-in-use).":
            errMsg = "כתובת הדואר אינה פנויה לרישום";
            break;
          case "Firebase: Password should be at least 6 characters (auth/weak-password).":
            errMsg = "נא להזין סיסמה בת 6 תווים לפחות";
            break;
          default:
            break;
        }
        toast.error(errMsg, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      setIsLoading(false);
      toast.error("נא למלא את כל השדות בטופס ההרשמה", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  const inviteLogin = async() => {

    if(email !== '' && code !== "" && password !== ""){
      const q = query(collection(database, "accounts"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        
        const firstDocSnapshot = querySnapshot.docs[0];
        const firstDocData = firstDocSnapshot.data();
        const userObj = { id: firstDocSnapshot.id, ...firstDocData };


        if(parseInt(code) === parseInt(userObj.code)){
          await createUserWithEmailAndPassword(auth, email, password)
          .then(async user_created => {
            const accountsRef = doc(database, "accounts", userObj.id);
                await updateDoc(accountsRef, {
                  uuid: user_created.user.uid
                })
          })
          .catch(error => {
            toast.error('הפעלת החשבון כבר בוצעה, נא לבצע כניסה מעמוד הכניסה')
          })
        } else {
          toast.error("הקוד החד פעמי אינו תואם את הקוד השמור במערכת")
        }
      } else {
        toast.error("כתובת הדואר האלקטרוני לא קיימת במערכת")
      }
    } else {
      toast.error("נא למלא את כל השדות")
    }

  }

  const passwordRecover = async() => {
    if(email !== ''){
      try {
          await sendPasswordResetEmail(auth,email);
          toast.success('נא לבדוק את תיבת הדואר האלקטרוני שלך')
      } catch (error) {
        console.error(error.message);
        toast.error(error.message)
      }
    } else {
      toast.error('נא למלא כתובת דואר אלקטרוני')
    }
  }
  
  function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  return (
    <>
      <ToastContainer />
      <div className='container-fluid authSetting'>
        <div className='row' style={{paddingTop:50}}>
          <div className='col-lg-4 col-12'></div>
          <div className='col-lg-4 col-12'>
            <div className='login-container'>
              <img className='login-logo' src="../../../images/logo_black.png" alt="Logo" />
             {
              selectedView === 'login' ? 
              (
              <>
               <h4>ברוכים הבאים למערכת לממונה</h4>
                <p>להתחברות למערכת נא להקליד שם משתמש וסיסמה</p>
                <div style={{marginTop:20}}>
                  <input value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder='דואר אלקטרוני' type='email' className='form-control' />
                  <input value={password} onChange={(e) => {setPassword(e.target.value)}} placeholder='סיסמה' type='password' className='form-control' />
                  {
                    isLoading ? (<>
                    <div class="spinner-border text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    </>) : (<><button onClick={loginAction} className='btn btn-warning btn-lg btn100w'>כניסה למערכת</button></>)
                  }
                  
                </div>
                <div style={{marginTop:12}}>
                <button onClick={() => {setSelectedView('recover')}} className='btn btn-outline-dark btn100w'>שכחת סיסמה?</button>
                  <button style={{marginTop:12}} onClick={() => {setSelectedView('register')}} className='btn btn-outline-dark btn100w'>עדיין אין לך חשבון? הרשמה למערכת</button>      
                  <button style={{marginTop:12}} onClick={() => {setSelectedView('invite')}} className='btn btn-outline-info btn100w'>כניסה למשתמשים מוזמנים</button>
                </div>
              </>
              
              ) 
              : selectedView === 'recover' ? 
              (<>
                <h4>שכחת סיסמה?</h4>
                <p>לא נורא :-) הקלד את כתובת הדואר האלקטרוני שלך על מנת לקבל במייל פרטים נוספים לעדכון הסיסמה</p>


                <div style={{marginTop:20}}>
                  <input value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder='דואר אלקטרוני' type='email' className='form-control' />
                  {
                    isLoading ? (<>
                    <div class="spinner-border text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    </>) : (<><button onClick={passwordRecover} className='btn btn-warning btn-lg btn100w'>עדכון סיסמה</button></>)
                  }
                  
                </div>


                <div style={{marginTop:12}}>
                  <button onClick={() => {setSelectedView('login')}} className='btn btn-outline-dark btn100w'>חזרה לעמוד כניסה</button>
                </div>

              </>)
              : selectedView === 'register' ? 
              (<>
                <h4>ברוכים הבאים למערכת לממונה</h4>
                <p>הרשמה למערכת הממונה</p>
                <div style={{marginTop:20}}>
                  <input value={firstName} onChange={(e) => {setFirstName(e.target.value)}} placeholder='שם פרטי' type='text' className='form-control' />
                  <input value={lastName} onChange={(e) => {setLastName(e.target.value)}} placeholder='שם משפחה' type='text' className='form-control' />
                  <input value={mobile} onChange={(e) => {setMobile(e.target.value)}} placeholder='טלפון נייד' type='text' className='form-control' />
                  <input value={companyName} onChange={(e) => {setCompanyName(e.target.value)}} placeholder='שם החברה' type='text' className='form-control' />
                  <input value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder='דואר אלקטרוני' type='email' className='form-control' />
                  <input value={password} onChange={(e) => {setPassword(e.target.value)}} placeholder='סיסמה' type='password' className='form-control' />
                  <button onClick={signupAction} className='btn btn-warning btn-lg btn100w'>הרשמה למערכת</button>
                </div>
                <div style={{marginTop:12}}>
                  <button onClick={() => {setSelectedView('login')}} className='btn btn-outline-dark btn100w'>חזרה לעמוד כניסה</button>
                </div>
              </>) 
              : selectedView === 'invite' ? 
              (<>
                <h4>ברוכים הבאים למערכת לממונה</h4>
                <p>על מנת להתחבר למערכת עליך להזין את כתובת הדואר האלקטרוני והקוד שקיבלת ממנהל המערכת ובנוסף, עליך לבחור סיסמת כניסה למערכת</p>
                <div style={{marginTop:20}}>
                  <input value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder='דואר אלקטרוני' type='email' className='form-control' />
                  <input value={code} onChange={(e) => {setCode(e.target.value)}} placeholder='קוד כניסה חד פעמי' type='text' className='form-control' />
                  <input value={password} onChange={(e) => {setPassword(e.target.value)}} placeholder='סיסמה' type='password' className='form-control' />
                  {
                    isLoading ? (<>
                    <div class="spinner-border text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    </>) : (<><button onClick={inviteLogin} className='btn btn-warning btn-lg btn100w'>הרשמה וכניסה למערכת</button></>)
                  }
                  
                </div>
                <div style={{marginTop:12}}>
                <button onClick={() => {setSelectedView('login')}} className='btn btn-outline-dark btn100w'>חזרה לעמוד כניסה</button>
                </div>
              </>) 
              
              
              : (<></>)
             }
            </div>
            <p className='credit'>Designed and Developed by <a href="https://www.qwamo.com" target="_blank">Qwamo</a></p>
          </div>
          <div className='col-lg-4 col-12'></div>
        </div>
      </div>
    </>
  )
}

export default Login