import React, { useState, useEffect, useRef } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import { database,storage } from "../../services/firebase-config";
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore";
import { Modal } from "bootstrap";
import useDashboardStore from "../../store/useDashboardStore";
import PopupItem from "./PopupItem";

const Popups = () => {
  const { popups, addManagerNewPopup,removePopupById } = useDashboardStore();

  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isWebDisplay, setIsWebDisplay] = useState(true);
  const [isMobDisplay, setIsMobDisplay] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [popupName, setPopupName] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const [link, setLink] = useState("");
  const [popupTrigger, setPopupTrigger] = useState("");
  const [popupAudience, setPopupAudience] = useState("");
  const fileInputRef = useRef(null);
  const [uploadImage, setUploadImage] = useState("");


  const addNewPopup = async () => {
    if (
      popupAudience !== "" &&
      popupContent !== "" &&
      popupName !== "" &&
      popupTitle !== ""
    ) {
      try {

        let imageUrl = "";
        if (uploadImage) {
          const storageRef = ref(storage, `images/${uploadImage.name}`);
          const uploadTask = await uploadBytesResumable(storageRef, uploadImage);
          imageUrl = await getDownloadURL(uploadTask.ref);
        }

        const _popup = {
          createdAt: Date.now(),
          popupAudience,
          popupContent,
          popupName,
          popupTitle,
          isWebDisplay,
          isMobDisplay,
          isActive,
          popupTrigger,
          imageUrl,
          link
        };

        const docRef = await addDoc(
          collection(database, "adminPopups"),
          _popup
        );
        _popup.id = docRef.id;

        addManagerNewPopup(_popup);

        if (modalRef.current) {
          const modalInstance = Modal.getInstance(modalRef.current);
          if (modalInstance) {
            modalInstance.hide();
          } else {
            new Modal(modalRef.current).hide();
          }

          setTimeout(() => {
            document
              .querySelectorAll(".modal-backdrop.fade.show")
              .forEach((backdrop) => backdrop.remove());
            document.body.classList.remove("modal-open");
          }, 150);
        }

        setUploadImage("");
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }

        setIsLoading(false);
        setPopupAudience("");
        setPopupContent("");
        setPopupName("");
        setPopupTrigger("");
        setPopupTitle("");
        setLink("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שדות החובה בטופס");
    }
  };

  const removePopup = async(id) => {
    const isConfirmed = window.confirm("האם למחוק את הפופאפ לצמיתות?");
    if(isConfirmed){
        try {
            await deleteDoc(doc(database, "adminPopups", id))
            .then((res) => {
                removePopupById(id);
                toast.success("הפופאפ הוסר בהצלחה");
              }).catch((err) => {
                toast.error(err.message);
              });
        } catch (error) {
            toast.error(error.message)
        }
    }
  }

  const updatePopup = async(id,isWebDisplay,isMobDisplay,isActive,popupAudience,popupContent,popupName,popupTitle,popupTrigger,uploadImage,link) => {
    try {

      let imageUrl = "";
      if (uploadImage) {
        const storageRef = ref(storage, `images/${uploadImage.name}`);
        const uploadTask = await uploadBytesResumable(storageRef, uploadImage);
        imageUrl = await getDownloadURL(uploadTask.ref);
      }



        const updateData = {
            isWebDisplay,
            isMobDisplay,
            isActive,
            popupAudience,
            popupContent,
            popupName,
            popupTitle,
            popupTrigger,
            link,
            ...(imageUrl && { imageUrl }),
          };

            const popupRef = doc(database, "adminPopups", id);
            await updateDoc(popupRef, {
                ...updateData
              }).then(() => {
                useDashboardStore.getState().updatePopupStore(id, { ...updateData});
              });
    } catch (error) {
        toast.error(error.message);
    }
  }

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade"
        id="exampleModal"
        ref={modalRef}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                הוספת פופאפ חדש
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">
                    בחירת טריגר <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    value={popupTrigger}
                    onChange={(e) => setPopupTrigger(e.target.value)}
                    className="form-control"
                  >
                    <option value="">בחר מהרשימה...</option>
                    <option value="סגירה אוטומטית">סגירה אוטומטית</option>
                    <option value="סגירה בקליק">סגירה בקליק</option>
                  </select>
                </div>

                <div className="col-lg-6">
                  <label className="form-label">קהל תצוגה <span style={{ color: "red" }}>*</span></label>
                  <select
                    value={popupAudience}
                    onChange={(e) => setPopupAudience(e.target.value)}
                    className="form-control"
                  >
                    <option value="">בחר מהרשימה...</option>
                    <option value="כל המשתמשים">כל המשתמשים</option>
                    <option value="מנהל ראשי">מנהל ראשי</option>
                    <option value="מנהלים">מנהלים</option>
                    <option value="משתמשים">משתמשים</option>
                  </select>
                </div>

                <div className="col-lg-4">
                  <label className="form-label">
                    שם הפופאפ (לא מוצג למשתמש)
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={popupName}
                    onChange={(e) => setPopupName(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-4">
                  <label className="form-label">
                    כותרת הפופאפ<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={popupTitle}
                    onChange={(e) => setPopupTitle(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-4">
                <label className="form-label">העלאת תמונה</label>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setUploadImage(e.target.files[0]);
                  }}
                />
                </div>



                <div className="col-lg-12">
                  <label className="form-label">קישור לעמוד</label>
                  <input
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    type="text"
                    className="form-control"
                    style={{textAlign:'left', direction:'ltr'}}
                  />
                </div>



                <div className="col-lg-12">
                  <label className="form-label">
                    תוכן הפופאפ <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setPopupContent(e.target.value);
                    }}>{popupContent}</textarea>
                </div>

                <div className="col-lg-4">
                  <input
                    onChange={(e) => setIsActive(e.target.checked)}
                    checked={isActive}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">פופאפ פעיל</label>
                </div>
                <div className="col-lg-4">
                  <input
                    onChange={(e) => setIsMobDisplay(e.target.checked)}
                    checked={isMobDisplay}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הצגת הפופאפ באפליקציה
                  </label>
                </div>
                <div className="col-lg-4">
                  <input
                    onChange={(e) => setIsWebDisplay(e.target.checked)}
                    checked={isWebDisplay}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">הצגת הפופאפ באתר</label>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  סגירה
                </button>

                {isLoading ? (
                  <>
                    <div class="spinner-border text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      onClick={addNewPopup}
                      type="button"
                      className="btn btn-success">הוספת פופאפ חדש</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>ניהול פופאפים</h3>
        </div>
        <div className="col-lg-2 heading-btn-container">
          <button
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="btn btn-success">הוספת פופאפ</button>
        </div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        {popups.length > 0 ? (
          <>
          <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">נוצר בתאריך</th>
                    <th scope="col">תמונה</th>
                    <th scope="col">שם מערכת</th>
                    <th scope="col">כותרת הפופאפ</th>
                    <th scope="col">פעיל</th>
                    <th scope="col">מוצג בווב</th>
                    <th scope="col">מוצג במובייל</th>
                    <th scope="col">קהל</th>
                    <th scope="col">טריגר</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>

                {popups.map((item) => (
                <PopupItem item={item} removePopup={removePopup} updatePopup={updatePopup} />
                ))}
                </tbody>
            </table>
          </>
        ) : (
          <>
            <p>לא נמצאו פופאפים לתצוגה</p>
          </>
        )}
      </div>
    </>
  );
};

export default Popups;
