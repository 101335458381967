import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { auth, database, storage } from "../../services/firebase-config";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  getCompanyById,
  getEmployeesByCompanyId,
  requestDataByAssociate,
  getAccountDetails,
  getReportItemById,
  createLog,
  loadDataByCompanyId,
} from "../../services/dbHelper";
import SafetyCategory from "./components/SafetyCategory";
import Modal from "react-modal";
import FindingSmallItem from "./components/FindingSmallItem";
import {saveLog} from '../../services/createLog'
import useDashboardStore from "../../store/useDashboardStore";

const AddSafetyReport = () => {

  let { id, siteid } = useParams();
  const { userPersonalData, selectedCompany } = useDashboardStore();

  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const [companiesArr, setCompaniesArr] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [findings, setFindings] = useState([]);
  const [projects, setProjects] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedSite, setSelectedSite] = useState("");
  const [myAccount, setMyAccount] = useState("");
  const [employees, setEmployees] = useState([]);
  const [safetyReportsItems, setSafetyReportsItems] = useState([]);
  const [trackInCharge, setTrackInCharge] = useState("");
  const [manageInCharge, setManageInCharge] = useState("");
  const [selectedReportItem, setSelectedReportItem] = useState("");
  const [selectedReportItemDummy, setSelectedReportItemDummy] = useState("");
  const [itemImpairmentDesc, setItemImpairmentDesc] = useState("");
  const [itemImpairmentComments, setItemImpairmentComments] = useState("");
  const [safetyReportsItem, setSafetyReportsItem] = useState(null);
  const [itemImpairmentLocation, setItemImpairmentLocation] = useState("");
  const [itemImpairmentTreat, setItemImpairmentTreat] = useState("");
  const [itemImpairmentTrack, setItemImpairmentTrack] = useState("");
  const [employeesArr, setEmployeesArr] = useState([]);
  const [findingsType, setFindingsType] = useState([]);
  const [findingSmallItems, setFindingSmallItems] = useState([]);
  const [findingSmallItemsForDatabase, setFindingSmallItemsForDatabase] = useState([]);
  const [smallFindingItemsIndex, setSmallFindingItemsIndex] = useState();
  const [itemImpairmentType, setItemImpairmentType] = useState("");
  const [reportsArrOrig, setReportsArrOrig] = useState([]);
  const [findingModalVisible, setFindingModalVisible] = useState(false);
  const [existFindingModalVisible, setexistFindingModalVisible] =useState(false);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [safetySafetyCategoryValue, setSafetySafetyCategoryValue] = useState([]);
  const [itemImpairmentSeverity, setItemImpairmentSeverity] = useState("");
  const [itemImpairmentImgReq, setItemImpairmentImgReq] = useState(false);
  const [itemImpairmentCommReq, setItemImpairmentCommReq] = useState("");
  const [itemImpairmentArea, setItemImpairmentArea] = useState("");
  const [newFindingItems, setNewFindingItems] = useState([]);
  const safetySafetyCategoryValueReducer = safetySafetyCategoryValue.reduce(
    (a, b) => a + b,
    0
  );

  const totalAverage =
    Array.isArray(selectedReportItem?.subCategories) &&
    selectedReportItem.subCategories.length
      ? (
          safetySafetyCategoryValueReducer /
          selectedReportItem.subCategories.length
        ).toFixed(0)
      : 0;


  const [safetyAssosicateCategoryId, setSafetyAssosicateCategoryId] = useState("");




  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);
      const companies = await requestDataByAssociate(
        account.associateId,
        "companies"
      );
      const findingsTypeArr = await requestDataByAssociate(
        account.associateId,
        "safetyReportsAreas"
      );
      setCompaniesArr(companies);
      setMyAccount(account);
      setFindingsType(findingsTypeArr);

      if (id !== "") {
        const employees = await getEmployeesByCompanyId(id);
        if (employees?.length > 0) {
          setEmployeesArr(employees);
        }
        const findingListArr = await loadDataByCompanyId(id, "findingList");
        const sortedReports = findingListArr.sort((a, b) => a.createdAt - b.createdAt);
        setReportsArrOrig(sortedReports);
        setFindings(sortedReports);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 10);
  }

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles); // Set the files in state
    uploadFiles(selectedFiles); // Call uploadFiles immediately
  };

  const uploadFiles = (filesToUpload) => {
    Array.from(filesToUpload).forEach((file) => {
      const storageRef = ref(storage, `findings/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setDownloadURLs((prevURLs) => [...prevURLs, downloadURL]);

            if (file === filesToUpload[filesToUpload.length - 1]) {
              setInputKey(Date.now()); // Update the key to reset the input
            }
          });
        }
      );
    });
  };

  useEffect(() => {
    if (typeof smallFindingItemsIndex === "number") {
      setFindingSmallItems(() => findings[smallFindingItemsIndex]);
    }
  }, [smallFindingItemsIndex]);
  
  
  useEffect(() => {
    if (selectedReportItem) {
      let updatedSelectedReportItem = { ...selectedReportItem };
      const finding =
        updatedSelectedReportItem.subCategories[index].sections[sectionIndex]
          .findingItems || [];

      updatedSelectedReportItem.subCategories[index].sections[
        sectionIndex
      ].findingItems = [...finding, findingSmallItems];

      setFindingSmallItemsForDatabase((findingSmallItemsForDatabase) => [
        ...findingSmallItemsForDatabase,
        findingSmallItems,
      ]);

      setFindings(() => findings.filter((x) => x.id !== findingSmallItems.id));
      setSmallFindingItemsIndex("0");
      setSelectedReportItem(updatedSelectedReportItem);
    }
  }, [findingSmallItems]);



  const addNewFinding = async () => {
    if (
      itemImpairmentArea !== "" &&
      itemImpairmentLocation !== "" &&
      itemImpairmentSeverity !== "" &&
      itemImpairmentTrack !== "" &&
      itemImpairmentTreat !== "" &&
      itemImpairmentType !== ""
    ) {
      try {
        let gallery = [];
        if (downloadURLs.length > 0) {
          downloadURLs.map((img) => {
            const image = {
              imgId: generateRandomId(),
              imageSource: img,
            };
            gallery.push(image);
          });
        }

        const new_finding = {
          gallery: gallery,
          itemImpairmentArea: itemImpairmentArea,
          itemImpairmentCommReq: itemImpairmentCommReq,
          itemImpairmentComments: itemImpairmentComments,
          itemImpairmentDesc: itemImpairmentDesc,
          itemImpairmentImgReq: itemImpairmentImgReq,
          itemImpairmentLocation: itemImpairmentLocation,
          itemImpairmentSeverity: itemImpairmentSeverity,
          itemImpairmentTrack: itemImpairmentTrack,
          itemImpairmentTreat: itemImpairmentTreat,
          itemImpairmentType: itemImpairmentType,
          status: "פעיל",
          createdBy: myAccount.id,
          associateId: myAccount.associateId,
          site: siteid,
          associateCompanyId: id,
          createdAt: Date.now(),
        };

        let updatedSelectedReportItem = { ...selectedReportItem };
        const finding =
          updatedSelectedReportItem.subCategories[index].sections[sectionIndex]
            .findingItems || [];

        updatedSelectedReportItem.subCategories[index].sections[
          sectionIndex
        ].findingItems = [...finding, new_finding];

        setSelectedReportItem(updatedSelectedReportItem);
        setNewFindingItems((newFinding) => [...newFinding, new_finding]);

        setItemImpairmentArea("");
        setItemImpairmentType("");
        setItemImpairmentSeverity("");
        setItemImpairmentLocation("");
        setItemImpairmentTrack("");
        setItemImpairmentTreat("");
        setItemImpairmentDesc("");
        setItemImpairmentComments("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setDownloadURLs([]);

        setFindingModalVisible(false);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שדות החובה בדוח");
    }
  };


  useEffect(() => {}, [, index, sectionIndex]);

  const updateList = async (companyId) => {
    setCompanyName(companyId);

    setProjects([]);
    setFindings([]);
    setEmployeesArr([]);

    if (!companyId) {
      return;
    }

    const employees = await getEmployeesByCompanyId(companyId);
    if (employees?.length > 0) {
      setEmployeesArr(employees);
    }
    const selectedCompany = companiesArr.find((x) => x.id === companyId);
    if (selectedCompany?.projects) {
      if (selectedCompany?.projects?.length > 0) {
        setProjects(selectedCompany?.projects);
      }
    }
    const findingListArr = await loadDataByCompanyId(companyId, "findingList");
    const sortedReports =
      findingListArr &&
      findingListArr?.sort((a, b) => a.createdAt - b.createdAt);
    setReportsArrOrig(sortedReports);
    setFindings(sortedReports);
  };

  const [projectSelected, setProjectSelected] = useState("");


  
  const loadCompany = useCallback(async () => {
    setIsLoading(true);
    const companyObj = await getCompanyById(id);
    setCompany(companyObj);

    if (companyObj.projects) {
      if (companyObj.projects.length > 0) {
        setProjects(companyObj.projects);
      }
    }

    const account = await getAccountDetails(auth.currentUser.uid);
    const employeesArr = await getEmployeesByCompanyId(id);

    setMyAccount(account);
    setEmployees(employeesArr);

    const safetyReportsItemsArr = await requestDataByAssociate(
      account.associateId,
      "safetyReportsItems"
    );

    const projectObj = companyObj.projects.find((x) => x.siteId === siteid);
    setProjectSelected(projectObj);
    setSafetyReportsItems(safetyReportsItemsArr);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadCompany();
  }, []);



  useEffect(() => {
    if (company.id) {
      updateList(company.id);
    }
  }, [company, findingModalVisible]);



  useEffect(() => {
    setSafetySafetyCategoryValue((safetySafetyCategoryValue) => {
      const newArray = [...safetySafetyCategoryValue];
      newArray[index] = value;
      return newArray.map((v) => (v === undefined ? 0 : v));
    });
  }, [value]);


  const setReport = async (value) => {
    setSelectedReportItem(value);
    const rtype = await getReportItemById(value);
    setSelectedReportItem(rtype);
    setSelectedReportItemDummy({
      ...rtype,
      associateCompanyId: selectedCompany.id,
      siteId: siteid,
      createdAt: Date.now(),
    });
  };








  async function storeValue() {
    setSelectedReportItem((selectedReportItem) => ({...selectedReportItem,associateCompanyId: companyName}));

    try {
      const safetyReportsDocRef = await addDoc(
        collection(database, "safetyReports"),
        { ...selectedReportItemDummy, totalAverage: totalAverage }
      );

      const newFindingItemsWithId = newFindingItems.map((item) => ({
        ...item,
        safetyReportsId: safetyReportsDocRef.id,
      }));

      newFindingItemsWithId.forEach(async (item) => {
        try {
          const docRef = await addDoc(
            collection(database, "findingList"),
            item
          );
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });

      const findingSmallItemsForDatabaseWithId =
        findingSmallItemsForDatabase.map((item) => ({
          ...item,
          safetyReportsId: safetyReportsDocRef.id,
        }));

      findingSmallItemsForDatabaseWithId.forEach(async (item) => {
        try {
          const findingRef = doc(database, "findingList", item.id);
          await updateDoc(findingRef, {safetyReportsId: safetyReportsDocRef.id,});
        } catch (error) {
          console.error("Error updateing Finding document: ", error);
        }

        const reportItem = safetyReportsItems.find(x => x.id === selectedReportItem);
              const logData = {
                associateCompanyId: selectedCompany.id,
                associateId: userPersonalData.associateId,
                createdBy: userPersonalData.uuid,
                logTitle: "הוספת דוח בטיחות",
                logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} יצר דוח בטיחות חדש עבור ${selectedCompany.companyName}, סוג הדוח: ${reportItem.safetyReportItem}`
              };

              saveLog(logData);



      });
      navigate(`/safety-reports`);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }






  const setTrackInChargeFunction = async (value) => {
    setSelectedReportItemDummy((selectedReportItemDummy) => ({
      ...selectedReportItemDummy,
      trackInCharge: value,
    }));
  };
  
  const setManageInChargeFunction = async (value) => {
    setSelectedReportItemDummy((selectedReportItemDummy) => ({
      ...selectedReportItemDummy,
      manageInCharge: value,
    }));
  };






  return (
    <>
      <div className="row heading-container">
        <ToastContainer />

        <Modal isOpen={existFindingModalVisible} style={customStyles}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  הוספת ממצא בטיחות ממצאים קיימים
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setexistFindingModalVisible(false);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {Array.isArray(findings) &&
                  findings.map((item, index) =>
                    Object.prototype.hasOwnProperty.call(
                      item,
                      "safetyReportsId"
                    ) ? null : (
                      <FindingSmallItem
                        companiesArr={companiesArr}
                        projects={projects}
                        findingsType={findingsType}
                        employeesArr={employeesArr}
                        item={item}
                        index={index}
                        setSmallFindingItemsIndex={setSmallFindingItemsIndex}
                      />
                    )
                  )}
              </div>

              <div
                className="modal-footer"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setexistFindingModalVisible(false)}
                >
                  סגירה
                </button>
                {/* <button
                  onClick={() => addNewFinding()}
                  type="button"
                  className="btn btn-success"
                >
                  הוספת ממצא חדש
                </button> */}
              </div>
            </div>
          </div>
        </Modal>



        <Modal isOpen={findingModalVisible} style={customStyles}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  הוספת ממצא בטיחות חדש
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setFindingModalVisible(false);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">
                      שם החברה <span style={{ color: "red" }}>*</span>
                    </label>
                    <select disabled className="form-control">
                      <option value="">{company.companyName}</option>
                    </select>
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label">
                      שם האתר <span style={{ color: "red" }}>*</span>
                    </label>
                    <select disabled className="form-control">
                      <option value="">{projectSelected.projectName}</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <option value="">העלאת תמונה / תמונות</option>
                    <input
                      ref={fileInputRef}
                      key={inputKey}
                      className="form-control"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    />
                    <div className="row">
                      {downloadURLs.map((url, index) => (
                        <div className="col-lg-1" key={index}>
                          <img
                            src={url}
                            alt={`Uploaded File ${index + 1}`}
                            style={{ width: "70px", height: "auto" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-label">
                      בחר את תחום הליקוי <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      value={itemImpairmentArea}
                      onChange={(e) => setItemImpairmentArea(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר את תחום הליקוי...</option>
                      {findingsType.length > 0 && (
                        <>
                          {findingsType.map((finding) => (
                            <option key={finding.id} value={finding.id}>
                              {finding.safetyReportAreaItem}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>

                  <div className="col-lg-4">
                    <label className="form-label">
                      בחר את סוג הליקוי <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      value={itemImpairmentType}
                      onChange={(e) => setItemImpairmentType(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר את סוג הליקוי...</option>
                      <option value="חיובי">חיובי</option>
                      <option value="כללי">כללי</option>
                    </select>
                  </div>

                  <div className="col-lg-4">
                    <label className="form-label">
                      בחר את חומרת הליקוי{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      value={itemImpairmentSeverity}
                      onChange={(e) =>
                        setItemImpairmentSeverity(e.target.value)
                      }
                      className="form-control"
                    >
                      <option value="">בחר את חומרת הליקוי...</option>
                      <option value="קל">קל</option>
                      <option value="בינוני">בינוני</option>
                      <option value="חמור">חמור</option>
                      <option value="מסכן חיים">מסכן חיים</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-label">
                      מיקום הליקוי באתר<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      value={itemImpairmentLocation}
                      onChange={(e) =>
                        setItemImpairmentLocation(e.target.value)
                      }
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="col-lg-4">
                    <label className="form-label">
                      אחראי למעקב <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      value={itemImpairmentTrack}
                      onChange={(e) => setItemImpairmentTrack(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר אחראי למעקב...</option>
                      {employeesArr.length > 0 && (
                        <>
                          {employeesArr.map((emp) => (
                            <option key={emp.id} value={emp.id}>
                              {emp.firstName} {emp.lastName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>

                  <div className="col-lg-4">
                    <label className="form-label">
                      אחראי לטיפול <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      value={itemImpairmentTreat}
                      onChange={(e) => setItemImpairmentTreat(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר אחראי לטיפול...</option>
                      {employeesArr.length > 0 && (
                        <>
                          {employeesArr.map((emp) => (
                            <option key={emp.id} value={emp.id}>
                              {emp.firstName} {emp.lastName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-check form-switch">
                      <input
                        onChange={(e) =>
                          setItemImpairmentImgReq(e.target.value)
                        }
                        checked={itemImpairmentImgReq}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                      <label className="form-check-label">
                        לחייב העלאת תמונה בסגירת הדוח
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div class="form-check form-switch">
                      <input
                        onChange={(e) =>
                          setItemImpairmentCommReq(e.target.value)
                        }
                        checked={itemImpairmentCommReq}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                      <label className="form-check-label">
                        לחייב הוספת תגובה בסגירת הדוח
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label className="form-label">
                      תיאור הליקוי <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      className="form-control"
                      onChange={(e) => {
                        setItemImpairmentDesc(e.target.value);
                      }}
                    >
                      {itemImpairmentDesc}
                    </textarea>
                  </div>
                  <div className="col-lg-12">
                    <label className="form-label">הערות לטיפול</label>
                    <textarea
                      className="form-control"
                      onChange={(e) => {
                        setItemImpairmentComments(e.target.value);
                      }}
                    >
                      {itemImpairmentComments}
                    </textarea>
                  </div>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setFindingModalVisible(false)}
                >
                  סגירה
                </button>
                <button
                  onClick={() => addNewFinding()}
                  type="button"
                  className="btn btn-success"
                >
                  הוספת ממצא חדש
                </button>
              </div>
            </div>
          </div>
        </Modal>




        <div className="col-lg-10">
          <h3>הוספת דו״ח בטיחות חדש - {company.companyName}</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>

      <div className="row heading-container-filter">
        {isLoading ? (
          <>
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          <>
            <div className="col-lg-6">
              <label className="form-label">סוג הדו״ח</label>
              <select
                onChange={(e) => setReport(e.target.value)}
                className="form-control"
              >
                <option value="">נא לבחור את סוג הדוח מהרשימה</option>
                {Array.isArray(safetyReportsItems) &&
                  safetyReportsItems.map((item) => (
                    <option value={item.id}>{item.safetyReportItem}</option>
                  ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label className="form-label">אחראי טיפול</label>
              <select
                onChange={(e) => setManageInChargeFunction(e.target.value)}
                className="form-control"
              >
                <option value="">בחר אחראי טיפול מהרשימה...</option>
                {employees.length > 0 && (
                  <>
                    {employees.map((item) => (
                      <option value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="col-lg-3">
              <label className="form-label">אחראי מעקב</label>
              <select
                onChange={(e) => setTrackInChargeFunction(e.target.value)}
                className="form-control"
              >
                <option value="">בחר אחראי מעקב מהרשימה...</option>
                {employees.length > 0 && (
                  <>
                    {employees.map((item) => (
                      <option value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
          </>
        )}
      </div>




      <div className="row" style={{ padding: 50 }}>
        {selectedReportItem ? (
          <>
            {Array.isArray(selectedReportItem.subCategories) &&
              selectedReportItem.subCategories.map((safetyCategory, index) => (
                <>
                  <SafetyCategory
                    selectedReportItemDummy={selectedReportItemDummy}
                    setSelectedReportItemDummy={setSelectedReportItemDummy}
                    setFindingModalVisible={setFindingModalVisible}
                    setexistFindingModalVisible={setexistFindingModalVisible}
                    company={company}
                    companiesArr={companiesArr}
                    safetyCategory={safetyCategory}
                    setIndex={setIndex}
                    setValue={setValue}
                    index={index}
                    setSectionIndex={setSectionIndex}
                    safetyAssosicateCategoryId={safetyAssosicateCategoryId}
                    setSafetyAssosicateCategoryId={setSafetyAssosicateCategoryId}
                    newFindingItems={newFindingItems}
                    findingsType={findingsType}
                    employeesArr={employeesArr}
                    projects={projects}
                    totalAverage={totalAverage}
                  />
                </>
              ))}
            <div className="row">
              <div className="col-lg-12 safetyCategory-header">
                <h3 className="safetyCategory-title">{totalAverage}</h3>
              </div>
            </div>
          </>
        ) : (
          <p>נא לבחור את סוג הדו״ח מהרשימה</p>
        )}
        <div className="row">
          <button
            onClick={storeValue}
            type="button"
            className="btn btn-success">יצירת דוח חדש</button>
        </div>
      </div>
    </>
  );
};

export default AddSafetyReport;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "70%",
    transform: "translate(-50%, -50%)",
  },
};
