import React, { useState } from 'react'

const PeriodicItem = (props) => {
    const months = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]

    const [isEditView, setIsEditView] = useState(false);
    const [trackingName, setTrackingName] = useState(props.item.trackingName)
    const [periodicTime, setPeriodicTime] = useState(props.item.periodicTime)
    const [trackingDesc, setTrackingDesc] = useState(props.item.trackingDesc)


    function handleInputChange(e) {
        const value = e.target.value;
        const numericValue = parseInt(value, 10);
        if (value === "" || (numericValue <= 60 && value.length <= 2)) {
            setPeriodicTime(value);
        }
    }

  return (
    <>
    {
        isEditView ? (<>
        <tr>
            <td scope="col">
                <input type="text" value={trackingName} onChange={(e) => {setTrackingName(e.target.value)}} className="form-control" />
            </td>
            <td scope="col">
                <input type="text" value={trackingDesc} onChange={(e) => {setTrackingDesc(e.target.value)}} className="form-control" />
            </td>
            <td scope="col">
                <input
                    type="number"
                    max="60"
                    value={periodicTime}
                    onChange={handleInputChange}
                    className="form-control"
                />
            </td>
            <td scope="col">
                <button onClick={() => {props.updateTrack(props.item.id,trackingName,periodicTime,trackingDesc); setIsEditView(false)}} className='btn btn-success btn-sm'>שמירה</button>{" "}
                <button onClick={() => setIsEditView(!isEditView)} className='btn btn-warning btn-sm'>ביטול</button>
            </td>
        </tr>
        </>) : (<>
            <tr>
            <th scope="col">{props.item.trackingName}</th>
            <td scope="col">{props.item.trackingDesc}</td>
            <td scope="col">{props.item.periodicTime}</td>
            <td scope="col">
                <button onClick={() => props.deleteTrack(props.item.id)} className='btn btn-danger btn-sm'>הסרה</button>{" "}
                <button onClick={() => setIsEditView(!isEditView)} className='btn btn-success btn-sm'>עריכה</button>
            </td>
        </tr>
        </>)
    }
        
    </>
  )
}

export default PeriodicItem